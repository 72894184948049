import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "../../../components/grid"
import Poem from "../../../components/poem"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspot from "../../../components/image-hotspot"

const Winter = (props) => {
  const data = useStaticQuery(graphql`
    query JahreszeitenWinterQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-winter.png"
        }
      ) {
        ...largeImage
      }
      winter01: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-01.jpg"
        }
      ) {
        ...largeImage
      }
      winter02: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-02.jpg"
        }
      ) {
        ...largeImage
      }
      winter03: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-03.jpg"
        }
      ) {
        ...largeImage
      }
      winter04: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-04.jpg"
        }
      ) {
        ...largeImage
      }
      winter05: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-05.jpg"
        }
      ) {
        ...largeImage
      }
      winter06: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-06.jpg"
        }
      ) {
        ...largeImage
      }
      winter07: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/winter-07.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack>
      <Grid columns={[1, 2]} alignY="center">
        <Stack space={6}>
          <Heading level={3} color="winter">
            Der Winter.
          </Heading>
          <Poem size="3">
            <span>Wenn sich der Tag des Jahrs hinabgeneiget</span>
            <span>Und rings das Feld mit den Gebirgen schweiget,</span>
            <span>So glänzt das Blau des Himmels an den Tagen,</span>
            <span>Die wie Gestirn in heitrer Höhe ragen.</span>
            <span>
              <br />
            </span>
            <span>Der Wechsel und die Pracht ist minder umgebreitet.</span>
            <span>Dort, wo ein Strom hinab mit Eile gleitet,</span>
            <span>Der Ruhe Geist ist aber in den Stunden</span>
            <span>Der prächtigen Natur mit Tiefigkeit verbunden.</span>
            <span>
              <br />
            </span>
            <span>Mit Unterthänigkeit</span>
            <span>Scardanelli.</span>
            <span>
              <br />
            </span>
            <span>d. 24 Januar 1743.</span>
          </Poem>
        </Stack>
        <ImageHotspots
          caption="Hölderlins Gedichtmanuskript ›Der Winter.‹ aus dem Deutschen Literaturarchiv Marbach"
          image={
            <Image
              image={data.gedicht.childImageSharp.gatsbyImageData}
              alt="Handschriftliches Manuskript von Hölderlins ›Der Winter.‹"
            />
          }
        >
          <ImageHotspot bg="winter" x={55} y={8} name="tinte">
            <ModalTemplate
              title="Tintenflocken"
              image={
                <Image
                  image={data.winter01.childImageSharp.gatsbyImageData}
                  alt="Tintenkleckse auf dem Papier"
                />
              }
            >
              <Paragraph>
                Wie Schneeflocken ziehen sich Hölderlins Flecken und
                Tintenkleckse über das gesamte Manuskript.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={65} y={44} name="hoehen">
            <ModalTemplate
              title="Heitre Höhen"
              image={
                <Image
                  image={data.winter02.childImageSharp.gatsbyImageData}
                  alt="Detailansicht einer Gedichtpassage"
                />
              }
            >
              <Paragraph>
                Mit dem Winter neigt sich das Jahr seinem Ende zu. Das Gedicht
                lenkt den Blick indes mehr und mehr nach oben: Vom Feld in die
                Gebirge, weiter in den Himmel und bis hinauf zum Gestirn, das in
                »heitrer Höhe« über allem ragt.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={55} y={35} name="blau">
            <ModalTemplate
              title="Blau eingefärbt"
              image={
                <Image
                  image={data.winter03.childImageSharp.gatsbyImageData}
                  alt="Detailansicht des Papiers"
                />
              }
            >
              <Paragraph>
                Sein ›Winter‹-Gedicht notierte Hölderlin auf ein leicht
                bläuliches, glattes Papier. Ein Blaustich, der sich wohl auch
                auf das Gedicht selbst übertragen hat, das beschreibt, wie das
                Blau des Himmels die Tage einfärbt und ihnen damit in der kalten
                Jahreszeit einen Glanz verleiht.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={55} y={80} name="tiefigkeit">
            <ModalTemplate
              title="Tiefigkeit"
              image={
                <Image
                  image={data.winter04.childImageSharp.gatsbyImageData}
                  alt="Ein Ausschnitt aus dem Gedicht zeigt das Wort »Tiefigkeit« in der Mitte"
                />
              }
            >
              <Paragraph>
                In Wortneuschöpfungen wie »Tiefigkeit« sahen Mediziner und
                Psychiater ein Zeugnis für Hölderlins Sprachverwirrung.
                Christoph Theodor Schwab dagegen erkannte »im Bilden
                ungewöhnlicher Zusammensetzungen deutscher Worte« eine für
                Hölderlin charakteristische »Haupteigenheit seines Wesens«.
                Tatsächlich finden sich in Hölderlins Texten ungemein viele
                bilderreiche Neologismen, die maßgeblich zu der besonderen
                Poesie seiner Werke beitragen.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={83} y={93} name="geschichte">
            <ModalTemplate
              title="Die Provenienzgeschichte des Manuskripts"
              image={
                <Image
                  image={data.winter05.childImageSharp.gatsbyImageData}
                  alt="Bleistiftvermerk zur Geschichte des Manuskripts"
                />
              }
            >
              <Paragraph>
                Mit Bleistift wurde rechts unten von unbekannter Hand die
                Herkunft des Manuskripts festgehalten. Es stammte aus dem
                Nachlass Ludwig Uhlands, der gemeinsam mit Gustav Schwab noch zu
                Hölderlins Lebzeiten, jedoch ohne sein Wissen und Zutun, die
                Herausgabe seiner Gedichte besorgte.
              </Paragraph>
              <Paragraph>
                Gemeinsam trugen die beiden Schwaben Hölderlins vereinzelte
                Veröffentlichungen in Zeitschriften und Almanachen zusammen und
                ergänzten sie um unveröffentliche Manuskripte, die ihnen von den
                Verwandten und Freunden des Dichters zur Verfügung gestellt
                wurden.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={48} y={48} name="falz">
            <ModalTemplate
              title="Zum Brief gefaltet"
              image={
                <Image
                  image={data.winter06.childImageSharp.gatsbyImageData}
                  alt="Vergrößerte Darstellung der Falz"
                />
              }
            >
              <Paragraph>
                Die Falz des Manuskripts lässt darauf schließen, dass es durch
                mehrere Hände gegangen ist: Wohl für den Versand mit der Post
                wurde es auf ein kleineres Format gefaltet.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot bg="winter" x={10} y={90} name="datierung">
            <ModalTemplate
              title="Hölderlins Datierung"
              image={
                <Image
                  image={data.winter07.childImageSharp.gatsbyImageData}
                  alt="Die linke untere Ecke des Manuskripts zeigt Hölderlins Datierung auf den 24. Januar 1742"
                />
              }
            >
              <Paragraph>
                Hölderlin datiert das Gedicht auf den 24. Januar 1742.
                Wenngleich das Jahr 1742 noch vor seiner Geburt liegt, mag der
                Monat zur beschriebenen Jahreszeit doch gut passen. Wann das
                Gedicht tatsächlich entstanden ist, ist in diesem Fall nicht
                überliefert.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
      </Grid>
    </Stack>
  )
}

export default Winter
