import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Goldschmidt = () => {
  const data = useStaticQuery(graphql`
    query Goldschmidt {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Am Freitag, den 29.11 um 19 Uhr, liest die Lyrikerin und Psychologin Lisa Goldschmidt aus ihrem 2023 im Elif Verlag erschienen Band „Ursprünge“. 
        Der Titel ist programmatisch, insofern er alle Tiefenschichten der Gedichte intentional erfasst: Eine Archäologie des Beginnens zu entwerfen, 
        der Anfänge und Gründungsmythen. Dabei gehen die existenziellen „Ursprünge“ in Form einer „zweiten Geburt“ fließend über in die der Sprache 
        und des Sprechens als einer unabdingbaren Symbolisierung des menschlichen Seins. Moderation: Florian Mittelhammer. 
      </Paragraph>
      <Paragraph>
          Begrenzte Platzzahl. Eine Voranmeldung wird empfohlen:{" "}
            <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen#step=1">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
      </Paragraph>
    </Stack>
  )
}

export default Goldschmidt