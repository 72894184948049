import React from "react"
import { getSrc } from "gatsby-plugin-image"

import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import NoBreak from "../../../components/no-break"
import ImageCompare from "../../../components/image-compare"

const DamalsHeute = () => {
  const data = useStaticQuery(graphql`
    query WirkungDamalsHeuteQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/cover.jpg"
        }
      ) {
        ...largeImage
      }
      damals: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/turm-damals.jpg"
        }
      ) {
        ...largeImage
      }
      heute: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/turm-heute.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack as="section">
      <Heading as="h2" level={2}>
        Der Turm damals und heute
      </Heading>
      <ImageCompare
        first={getSrc(data.damals)}
        second={getSrc(data.heute)}
        caption="Der Hölderlinturm 1869 und 2019"
        leftImageAlt="Historische Ansicht des Hölderlinturms kurz vor dem Brand von 1875. Das heute charakteristische Spitzdach ist hier noch nicht vorhanden"
        rightImageAlt="Der Hölderlinturm heute umgeben von zwei Trauerweiden und Stocherkähnen auf dem Neckar"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            Die ältesten Teile des Turms gehörten zur mittelalterlichen
            Stadtbefestigung. Zwischen innerer und äußerer Stadtmauer errichtete
            man im 16. Jahrhundert eine Färberei, die 1778 umgebaut und mit dem
            danebenliegenden Wehrturm verbunden wurde. Auf dessen Sockel
            entstand auch jenes »achteckige Kämmerlein«, das später Hölderlins
            Zimmer werden sollte. Nach dessen Einzug wurde das Haus erweitert
            und 1820 sogar aufgestockt, um an Studenten vermieten zu können.
          </Paragraph>
          <Paragraph>
            30 Jahre nach dem Tod des Dichters wurde das Gebäude zu einer
            Badeanstalt. Mit dem Wiederaufbau nach dem Brand erhielt der Turm
            seine heutige Gestalt. Hölderlins Zimmer verlor dabei zwei Fenster,
            gewann aber 4 m² hinzu und konnte bereits besichtigt werden. Doch
            erst 1915 wurde – <NoBreak>allerdings</NoBreak> hier im Erdgeschoss
            – ein Gedenkraum für den Dichter eingerichtet, der 60 Jahre später
            in Hölderlins Zimmer umzog. Nach Ende des Zweiten Weltkriegs durch
            eine Gartenanlage aufgewertet, konnte der Turm 1984 zum
            Literaturmuseum ausgebaut werden. Zu Hölderlins 250. Geburtstag
            wurde das gesamte Ensemble – einschließlich des wieder erschlossenen
            zweiten Obergeschosses und des Gartens – völlig neu gestaltet.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default DamalsHeute
