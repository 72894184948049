import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Box from "../../../components/box"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import QuoteBox from "../../../components/quote-box"
import Stack from "../../../components/stack"
import Text from "../../../components/text"

import logoWuestenrot from "../../../images/partner-logos/wuestenrot.svg"

const GartenDichter = (props) => {
  const data = useStaticQuery(graphql`
    query GartenDichterQuery {
      garten: file(
        relativePath: {
          eq: "dauerausstellung/08-der-garten-zum-turm/garten.jpg"
        }
      ) {
        ...largeImage
      }
      skulptur: file(
        relativePath: {
          eq: "dauerausstellung/08-der-garten-zum-turm/skulptur.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Ein Garten für den Dichter{" "}
          </Heading>
          <Paragraph>
            Zu Hölderlins Zeit gab die Fläche um den Turm herum ein ganz anderes
            Bild ab. Sie war aufgeteilt in mehrere Grundstücke, die unter
            anderem zum Anbau von Gemüse oder auch zum Wäschetrocknen benutzt
            wurden. Nur ein kleiner Teil gehörte zum Haus. Um den Turm als
            Erinnerungsort aufzuwerten, wurde darin in den 1950er Jahren nicht
            nur ein Museum eingerichtet; es wurden auch sämtliche Grundstücke
            zusammengelegt und einheitlich gestaltet. Aus den vielen Nutzgärten
            wurde ein Museumgarten. Das heutige Ensemble war geschaffen.
          </Paragraph>
        </Stack>
      </Constrain>
      <Box position="relative">
        <Stack>
          <Box
            position={["static", "static", "absolute"]}
            right={[null, null, 0, 0, -12]}
            width={[null, null, "lg"]}
            zIndex={1}
          >
            <QuoteBox author="Ernst Zimmer an Oberamtspfleger Burk, 29. Januar 1834">
              Seit 8 Tagen Blühen hir, Seidelbast, SchneGloken, Hiazinten,
              Veilchen, und zim theil Pfirschen. An unserem Hause steht ein
              Birnbaum desen Knospen in einige Tage zur Blüthe kommen werden.
            </QuoteBox>
          </Box>
          <Image
            image={data.garten.childImageSharp.gatsbyImageData}
            alt="Ein Bild aus dem unteren Teil des Gartens mit Flussufer und Trauerweide"
            attribution="Gudrun de Maddalena"
          />
        </Stack>
      </Box>
      <Constrain>
        <Stack>
          <Paragraph>
            1956 wurde die ›HÖLDERLIN‹–Skulptur von Ivo Beucker aufgestellt, die
            weniger den Dichter porträtieren als seine »dichterische Aussage«
            abbilden sollte. Sie ergänzte die Bronzebüste von Maximilian
            Wittmann, die 1933 zum 90. Todestag des Dichters in einer Nische im
            Turmsockel aufgestellt worden war und den jungen Hölderlin nach dem
            Porträt von Franz Carl Hiemer zeigt.
          </Paragraph>
          <Image
            image={data.skulptur.childImageSharp.gatsbyImageData}
            alt="Skulptur eines knienden Mannes der nach oben blickt"
            attribution="Gudrun de Maddalena"
          />
          <Paragraph>
            Das gesamte Gartengelände wurde zum 250. Geburtstag Hölderlins von
            der Wüstenrot Stiftung instandgesetzt und neu gestaltet.
          </Paragraph>
          <Box align="center">
            <Stack space={6}>
              <Text sans>
                Wir danken der Wüstenrot Stiftung für ihre Unterstützung
              </Text>
              <img
                src={logoWuestenrot}
                alt="Logo der Wüstenrot Stiftung"
                role="presentation"
                width="298"
                height="92"
                sx={{ width: "auto", height: "12" }}
              />
            </Stack>
          </Box>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default GartenDichter
