import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"

const EinfuehrungGang = (props) => {
  const data = useStaticQuery(graphql`
    query RaumEinfuehrungGangQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/03-ein-gang-von-42-schuh-laenge/cover.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Im langen Gang des Hauses soll Hölderlin zuweilen stundenlang auf- und
        abgegangen sein. Im Gehen suchte er auch nach passenden Reimen, Rhythmen
        und Metren für seine Gedichte.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Der Flur im Erdgeschoss des Turms: An der Wand schwingt ein Metronom. Die Lichtschienen und der Text im Treppenhaus beziehen sich auf Hölderlins Gedicht ›Hälfte des Lebens‹"
        attribution="Gudrun de Maddalena"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            An Hölderlins Versmaßen, heißt es, könne man die Schritte des
            rüstigen Fußgängers ablesen. Seine längste Wanderung führte ihn von
            Nürtingen bis nach Bordeaux. Im Tübinger Stift soll er nachts die
            anderen Studenten dadurch gestört haben, dass er hin- und herlaufend
            seine Gedichte ausprobierte. Und im Erdgeschoss des Turmes, hat er
            »einen Gang von 42 Schuh Länge bekommen, den er auch alle Tage mit
            gewaltigen Schritten« durchmaß. So berichtet es Ernst Zimmer.
          </Paragraph>
          <Trivia>
            Etwa zur gleichen Zeit wurde das Metronom erfunden. Als einer der
            ersten bestand Beethoven – im selben Jahr geboren wie Hölderlin –
            auf einer festen Geschwindigkeit, in der seine Sinfonien gespielt
            werden sollten. Hölderlins Credo hätte er geteilt: »Fest bleibt
            Eins; ... immer bestehet ein Maas«.
          </Trivia>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungGang
