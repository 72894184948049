import PropTypes from "prop-types"
import React from "react"
import theme from "../gatsby-plugin-theme-ui"

import Text from "./text"
import Stack from "./stack"

const Poem = (props) => {
  const { children, size, space, italic, color, align } = props

  return (
    <Stack space={space}>
      {React.Children.map(children, (child, i) => {
        return (
          <Text
            as="div"
            align={align}
            size={size}
            italic={italic}
            color={color}
            serif
            key={i}
            pl={[6, 12]}
            textIndent={[`-${theme.space["6"]}`, `-${theme.space["12"]}`]}
          >
            {child}
          </Text>
        )
      })}
    </Stack>
  )
}

Poem.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  align: PropTypes.string,
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Poem.defaultProps = {
  size: [3, 3, 4, 5],
  space: 0,
}

export default Poem
