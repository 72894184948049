import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Box from "./box"

const Text = (props) => {
  const {
    as,
    align,
    bold,
    children,
    color,
    italic,
    lineHeight,
    hyphens,
    mono,
    sans,
    serif,
    size,
    sx,
    uppercase,
    ...restProps
  } = props

  return (
    <Box
      as={as}
      textAlign={align || null}
      {...restProps}
      sx={{
        fontFamily: sans ? "sans" : serif ? "serif" : mono ? "mono" : null,
        textTransform: uppercase
          ? "uppercase"
          : uppercase === false
          ? "none"
          : null,
        hyphens,
        fontWeight: bold ? "bold" : bold === false ? "normal" : null,
        fontStyle: italic ? "italic" : italic === false ? "normal" : null,
        lineHeight,
        color,
        fontSize: size,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

Text.displayName = "Text"

Text.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  serif: PropTypes.bool,
  sans: PropTypes.bool,
  mono: PropTypes.bool,
  uppercase: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  lineHeight: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}

Text.defaultProps = {
  as: "p",
  color: "text",
}

export default Text
