import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Falb = () => {
  const data = useStaticQuery(graphql`
    query Falb {
      falb: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Gedichte reimen sich meistens.<br />
        Und manche sind schwierig zu verstehen.<br />
        Was passiert, wenn wir Gedichte in Leichte Sprache übersetzen?<br />
        Was passiert mit der Sprache in diesen Gedichten?<br />
        Und was mit dem Inhalt?<br /> 
        Und wie können wir selbst Gedichte in Leichter Sprache schreiben?<br /> 
        Die Antworten auf diese Fragen bekommen Sie in diesem Kurs.<br />
        Sie lernen die Regeln für Leichte Sprache kennen.<br /> 
        Und Sie können selbst ein Gedicht schreiben.<br /> 
        Oder Sie können ein Gedicht in Leichte Sprache übersetzen.<br /> 
        Dazu müssen Sie nichts über das Schreiben von Gedichten wissen.<br /> 
        Wir zeigen Ihnen all das.<br /> 
        Daniel Falb und Krishna-Sara Helmle leiten den Kurs.<br /> 
        Daniel Falb schreibt Gedichte.<br /> 
        Er ist Dichter von Beruf.<br /> 
        Krishna-Sara Helmle übersetzt Texte in Leichte Sprache.<br /> 
        Sie ist von Beruf Trainerin und Übersetzerin für Leichte Sprache.
      </Paragraph>
      <Paragraph>
        Sie können sich mit Telefon anmelden: <strong>07071/2041860</strong><br />
        Oder Sie melden sich mit einer Email an:{" "}
            <ExternalLink href="mailto:hoelderlinturm@tuebingen.de">
              <span style={{color: '#F7A61B'}}> hoelderlinturm@tuebingen.de</span> 
            </ExternalLink>
      </Paragraph>
      <Paragraph>
        Eine Veranstaltung im Rahmen <strong>Literatur für Alle? Literatur mit Allen! Das Inklusive Literaturfestival.</strong>
      </Paragraph>
    </Stack>
  )
}

export default Falb