import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"
import QuotePrimary from "../../../components/quote-primary"

const KarteChandler = ({ headingElement }) => {
  const data = useStaticQuery(graphql`
    query RaumFuegungenKarteQuery {
      karte: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/karte-chandler.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as={headingElement} level={4}>
            Landkartenreisen nach Griechenland
          </Heading>
          <Paragraph>
            »Griechenland war meine erste Liebe und ich weiß nicht, ob ich sich
            sagen soll, es werde meine lezte seyn«, bekennt Hölderlin in einer
            Vorrede zum ›Hyperion‹. Allerdings konnte er diesen Sehnsuchtsort
            nur »mit der Seele« (Goethe) suchen. Eine tatsächliche Vorstellung
            vom Griechenland seiner Zeit erlangte er lediglich über die Bücher,
            auf denen seine damals ungewöhnlich exakten Schilderungen von
            Landschaft und Menschen beruhen.
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain>
        <ImageHotspots
          image={
            <Image
              image={data.karte.childImageSharp.gatsbyImageData}
              alt="Eine alte Landkarte zeigt den Isthmus von Korinth"
              caption="Karte aus Richard Chandlers ›Reisen in Griechenland‹ "
            />
          }
        >
          <ImageHotspot x={50} y={50}>
            <Constrain>
              <QuotePrimary author="Friedrich Hölderlin: Hyperion oder der Eremit in Griechenland">
                Ich bin jezt alle Morgen auf den Höhn des Korinthischen Isthmus,
                und, wie die Biene unter Blumen, fliegt meine Seele oft hin und
                her zwischen den Meeren, die zur Rechten und zur Linken meinen
                glühenden Bergen die Füße kühlen. Besonders der Eine der beeden
                Meerbusen hätte mich freuen sollen, wär' ich ein Jahrtausend
                früher hier gestanden. Wie ein siegender Halbgott, wallte da
                zwischen der herrlichen Wildniß des Helikon und Parnaß, wo das
                Morgenroth um hundert überschneite Gipfel spielt, und zwischen
                der paradiesischen Ebene von Sycion der glänzende Meerbusen
                herein, gegen die Stadt der Freude, das jugendliche Korinth, und
                schüttete den erbeuteten Reichtum aller Zonen vor seiner
                Lieblingin aus.
              </QuotePrimary>
            </Constrain>
          </ImageHotspot>
        </ImageHotspots>
      </Constrain>
      <Constrain>
        <Stack>
          <Paragraph>
            Aus Richard Chandlers Bericht über seine Reise durch Griechenland in
            den 1760er-Jahren hat Hölderlin ganze Passagen fast wörtlich
            übernommen. Das Buch des Engländers ermöglichte ihm auch die
            geografische Orientierung: So sieht Hyperion den Isthmus von Korinth
            zu Beginn des Romans, als ob er auf eine der beigefügten Landkarten
            blicken würde.
          </Paragraph>
          <Trivia>
            Während seiner Reise entdeckte Chandler das historische Olympia
            wieder, dessen Siegern Hölderlins literarisches Vorbild Pindar
            zahlreiche Hymnen gewidmet hat.
          </Trivia>
        </Stack>
      </Constrain>
    </Stack>
  )
}

KarteChandler.propTypes = {
  headingElement: PropTypes.string,
}

KarteChandler.defaultProps = {
  headingElement: "h3",
}

export default KarteChandler
