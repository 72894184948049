import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Schauuebung = () => {
  const data = useStaticQuery(graphql`
    query Schauuebung {
      schauuebung: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Sei dabei, wenn die Feuerwehr in Tübingen in historischer Uniform und mit historischem Löschwerkzeug zur Tat schreitet! 
        Die Feuerwehrmänner zeigen verschiedene Rettungsmaßnahmen, darunter zum Beispiel auch, wie das Wasser damals aus dem Necker abgepumpt wurde, 
        um Brände in der engbebauten Ufernähe zu löschen.
      </Paragraph>
      <Paragraph>
        Die Veranstaltung findet rund um den Hölderlinturm statt, der Eintritt ist frei.
      </Paragraph>
    </Stack>
  )
}

export default Schauuebung