import EinfuehrungGarten from "../../../../src/content/dauerausstellung/08-der-garten-zum-turm/einfuehrung-garten";
import Neckar from "../../../../src/content/dauerausstellung/08-der-garten-zum-turm/neckar";
import GartenDichter from "../../../../src/content/dauerausstellung/08-der-garten-zum-turm/garten-dichter";
import Tempo from "../../../../src/content/dauerausstellung/08-der-garten-zum-turm/tempo";
import * as React from 'react';
export default {
  EinfuehrungGarten,
  Neckar,
  GartenDichter,
  Tempo,
  React
};