import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import Image from "../../../components/image"

const StammbuchStipendiaten = (props) => {
  const data = useStaticQuery(graphql`
    query StammbuchStipendiatenQuery {
      stammbuch3: file(
        relativePath: { eq: "aus-dem-archiv-geholt/02/stammbuch-3.png" }
      ) {
        ...largeImage
      }
      stammbuch4: file(
        relativePath: { eq: "aus-dem-archiv-geholt/02/stammbuch-4.png" }
      ) {
        ...largeImage
      }
      stammbuch5: file(
        relativePath: { eq: "aus-dem-archiv-geholt/02/stammbuch-5.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Grid columns={[1, 2]} alignX="center">
      <Image
        image={data.stammbuch3.childImageSharp.gatsbyImageData}
        alt="Handschriftlicher Eintrag aus Hegels Stammbuch mit einem gezeichneten Wirtshausschild"
        caption="Abb.3: Eintrag mit gezeichnetem Wirtshausschild "
      />
      <Image
        image={data.stammbuch4.childImageSharp.gatsbyImageData}
        alt="Handschriftlicher Eintrag aus Hegels Stammbuch von Fink"
        caption="Abb.4: Fink endet mit einem Vivat auf eine gewisse »Kandidaten-Gesellschaft!!«"
      />
      <Image
        image={data.stammbuch5.childImageSharp.gatsbyImageData}
        alt="Handschriftlicher Eintrag aus Hegels Stammbuch in Französisch von Fallot"
        caption="Abb.5: Fallot erinnert an »sottises en amour«, Wein und Butterbrezeln."
      />
    </Grid>
  )
}

export default StammbuchStipendiaten
