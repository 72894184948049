import React from "react"
import Text from "./text"
import PropTypes from "prop-types"

const Blockquote = (props) => {
  const { children, as, ...restProps } = props
  return (
    <Text
      as={as}
      display="block"
      borderLeft={4}
      borderColor="primary"
      pl={6}
      {...restProps}
    >
      {children}
    </Text>
  )
}

Blockquote.propTypes = {
  as: PropTypes.string,
}

Blockquote.defaultProps = {
  as: "span",
}

export default Blockquote
