import React from "react"
import Stack from "./stack"
import Heading from "./heading"
import Constrain from "./constrain"

import PropTypes from "prop-types"

const ImageHotspotsModalTemplate = (props) => {
  const { title, image, children } = props
  return (
    <Constrain>
      <Stack>
        <Heading level={3}>{title}</Heading>
        {image}
        {children}
      </Stack>
    </Constrain>
  )
}

ImageHotspotsModalTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.node,
  children: PropTypes.node,
}

export default ImageHotspotsModalTemplate
