import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Sonderteufel = () => {
  const data = useStaticQuery(graphql`
    query Sonderteufel {
      sonderteufel: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
      Im Jahr 1875 passierte es… der Hölderlinturm, zu dieser Zeit vornehmlich als die Badeanstalt Eberhardtei bekannt, wurde in Brand gesetzt! 
      Eine feuerpolizeiliche Akte gibt eindeutige Hinweise darauf, dass es sich um Brandstiftung gehandelt haben muss. Doch es gibt 6 Verdächtige - 
      wer könnte es gewesen sein? Im Rahmen der Ausstellung „Turm in Flammen! Die Brandakte ‚Feuerteufel‘“ wird es Sonderführungen durch den Hölderlinturm geben.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.sonderteufel.childImageSharp.gatsbyImageData}
          alt="Postkarte" 
        />
      </Grid>
    </Stack>
    
  )
}

export default Sonderteufel