import React from "react"
import Text from "./text"
import NoBreak from "./no-break"
import Box from "./box"
import Icon from "./icon"

const Accent = ({ children, ...restProps }) => {
  return (
    <Text as="span" bold {...restProps} position="relative">
      <Box
        as="span"
        position="absolute"
        left="50%"
        bottom="0.9em"
        sx={{
          transform: "translateX(-50%)",
        }}
      >
        <Icon size={3} icon="accent" />
      </Box>
      <NoBreak>{children}</NoBreak>
    </Text>
  )
}

export default Accent
