import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"
import Heading from "../../../components/heading"

const EinfuehrungHaelfte = (props) => {
  const data = useStaticQuery(graphql`
    query RaumIntroHaelfteDesLebensQuery {
      cover: file(
        relativePath: { eq: "dauerausstellung/05-haelfte-des-lebens/cover.jpg" }
      ) {
        ...largeImage
      }
      widmung: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/widmung-an-schwab.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Bis zu seinem Tod hat Hölderlin hier Verse geschrieben. 48 Gedichte sind
        überliefert. In ihrer sprachlichen und rhythmischen Schlichtheit
        unterscheiden sie sich deutlich von seinen früheren Versexperimenten.
        Wieviele Gedichte in den 36 Jahren im Turm tatsächlich entstanden sind,
        ist nicht zu ermitteln.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Raumansicht mit dunkler Tischvitrine in der Mitte und einer Vitrine mit Büchern an der rechten Wand. Durch die Türschwelle erkennt man einige der Pseudonyme, mit denen Hölderlin seine Turmgedichte unterzeichnete"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            Zwischen Hölderlins Auszug aus dem Stift und seiner dramatischen
            Rückkehr nach Tübingen liegen 13 Jahre – eine rastlose Zeit, geprägt
            durch unbefriedigende Hauslehrerstellen in Thüringen, Hessen, der
            Schweiz und Frankreich, die ausbleibende Anerkennung als Dichter,
            den Tod seiner großen Liebe Susette Gontard und das Versanden jener
            politischen Hoffnungen, die – ausgehend vom revolutionären
            Frankreich – auch Deutschland ergriffen hatten.
          </Paragraph>

          <Paragraph>
            Ab Mai 1807 wurde der Turm zu Hölderlins neuem Lebensmittelpunkt.
            Bis zu seinem Tod im Juni 1843 hat er ihn und seine unmittelbare
            Umgebung nicht mehr verlassen. Obgleich von seinem Leben hier nur
            Momentaufnahmen überliefert sind – etwa, dass er oft schon um drei
            Uhr aufstand, stundenlang im angrenzenden ›Zwingel‹ spazieren ging
            und hier in diesem Raum mit der Familie Zimmer gegessen hat – muss
            sein Alltag im Turm vom Gleichmaß der Tage und der Jahreszeiten
            bestimmt gewesen sein.
          </Paragraph>

          <Heading as="h2" level={4}>
            Turmgedichte
          </Heading>
          <Paragraph>
            An der Verlässlichkeit des Kalenderzyklus orientieren sich auch
            viele von Hölderlins Turmgedichten. Anders als in den
            vorausgegangenen Gedichten beansprucht er darin nicht mehr, »das
            Bleibende« zu stiften und die Welt zu verändern. Vielmehr scheint
            sich in den Versen aus jener Zeit eine Sehnsucht nach Stabilität und
            Sicherheit niederzuschlagen.
          </Paragraph>

          <Paragraph>
            Darüber hinaus lassen sich die Stabilität des Reims, das regelmäßige
            Heben und Senken der meisten Verse wie auch die Sicherheit der
            wiederkehrenden Jahreszeiten als Therapeutika gegen innere Unruhe,
            Verwundung und Zerrissenheit verstehen.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungHaelfte
