import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Gedankenprozesse = () => {
  const data = useStaticQuery(graphql`
    query Gedankenprozesse {
      gedankenprozesse: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        <italic>Heute suche ich wie morgen schon nach meinen Sprachen.</italic>
      </Paragraph>
      <Paragraph>
        Jose F. A. Oliver, Fremdenzimmer
      </Paragraph>
      <Paragraph>
        Bisweilen kann ein einzelner Vers, eine einzige Zeile die Welt erklären. Schreiben heißt also, 
        mit der Welt im Dialog stehen. Wer im Dialog steht, der*die spricht, sagt und benennt. 
        Greift sich immer auch selbst, tritt in ein intuitives Gespräch mit inneren Anteilen und unbewussten Themen. 
        Das Papier eröffnet eine innere Bühne für vergessene Inhalte, neue Rollen und kühne Impulse. 
        Schreiben kann so schöpferische wie persönliche Entwicklungsprozesse auf spielerische Weise anregen und Wahrnehmungs-, 
        Erlebnis- wie Sprachfähigkeit fördern.
      </Paragraph>
      <Paragraph>
        Dieses Potenzial der schreibenden Zwischenwelt wollen wir nutzen, um uns bekannte Fragen und Gedankenkreise neu zu fassen 
        und im Schreiben Unsägliches, Unsagbares, Ungesagtes sagbar zu machen - vom Wort in den Satz; vom Satz in die Verdichtung.
        Im Schreibworkshop werden wir verschiedene kreative und literarische Schreibstrategien kennenlernen und ausprobieren, 
        um die eigenen Entwicklungs- und Gedankenprozesse im Alltag achtsam und lösungsorientiert begleiten zu können.
      </Paragraph>
      <Paragraph>
        Bitte mitbringen: Schreibmaterial.
      </Paragraph>
      <Paragraph>
        Die Zahl der Teilnehmer*innen ist begrenzt. Anmeldung erforderlich unter:{" "}
            <ExternalLink href="mailto:sabrina.roesch@tuebingen.de">
              <span style={{color: '#F7A61B'}}> sabrina.roesch@tuebingen.de</span> 
            </ExternalLink>
      </Paragraph>
    </Stack>
  )
}

export default Gedankenprozesse