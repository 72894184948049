import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../../../components/box"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import QuoteBox from "../../../components/quote-box"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const Schreibtisch = () => {
  const data = useStaticQuery(graphql`
    query TurmzimmerSchreibtischQuery {
      tisch: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/tisch.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Schreibtisch
          </Heading>
          <Paragraph>
            Nur ein einziger Gegenstand ist aus Hölderlins Turmzimmer geblieben:
            ein kleiner Tisch, auf den der Dichter »mit der Hand geschlagen,
            wenn er Streit gehabt – mit seinen Gedanken«. So berichtete es Lotte
            Zimmer, die Hölderlin gepflegt hat.
          </Paragraph>
          <Paragraph>
            An den Tisch ist aber noch eine andere Überlieferung gebunden:
            Hölderlin soll, wenn er ein Gedicht niederschrieb, mit der linken
            Hand darauf das Versmaß geschlagen haben.
          </Paragraph>
        </Stack>
      </Constrain>

      <Box position="relative">
        <Stack>
          <Image
            image={data.tisch.childImageSharp.gatsbyImageData}
            caption="Replik von Hölderlins Schreibtisch. Das Original befindet sich in Privatbesitz"
            alt="Schreibtisch mit Nussbaumfurnierter Zarge, gedrechselten Beinen und gekreuzten Stegen. Auf der Tischplatte aus Eiche befinden sich einige Tintenflecke und Abdrücke."
          />
          <Constrain align="right">
            <QuoteBox author="Johann Georg Fischer: Hölderlins letzte Verse">
              Nun trat er, und mit einem Auge voll jugendlichen Feuers, an
              seinen Stehpult, nahm einen Foliobogen und eine [...] Feder heraus
              und schrieb, mit den Fingern der linken Hand die Verse auf dem
              Pult skandirend, und nach Vollendung jeder Zeile mit Kopfnicken
              ein zufriedenes deutliches ›Hm‹ ausdrückend...
            </QuoteBox>
          </Constrain>
        </Stack>
      </Box>
      <Constrain>
        <Stack>
          <Heading as="h3" level={5}>
            Mehr zu Hölderlins Schreibtisch
          </Heading>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Gregor Wittkop</Heading>:{" "}
              <TextLink href="https://www.dla-marbach.de/shop/shop-einzelansicht/?tt_products%5BbackPID%5D=365&tt_products%5Bproduct%5D=166&cHash=e6ed14ac12f009ce3b868f2e5b4aa7f4">
                Hölderlins Tisch aus Tübingen
              </TextLink>
            </Text>
            <Text color="whisper">Marbach a.N. 2003 (Spuren 64)</Text>
          </Stack>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Schreibtisch
