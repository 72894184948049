import PatmosHotspots from "../../../../src/content/aus-dem-archiv-geholt/band-05-patmos/patmos-hotspots";
import OlgaMartynova from "../../../../src/content/aus-dem-archiv-geholt/band-05-patmos/olga-martynova";
import Poem from "../../../../src/components/poem";
import Small from "../../../../src/components/small";
import QuoteBox from "../../../../src/components/quote-box";
import * as React from 'react';
export default {
  PatmosHotspots,
  OlgaMartynova,
  Poem,
  Small,
  QuoteBox,
  React
};