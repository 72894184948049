import React from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Text from "./text"
import { Link } from "gatsby"

const Button = (props) => {
  const {
    as,
    borderColor,
    children,
    color,
    href,
    onClick,
    secondary,
    size,
    to,
    ...restProps
  } = props

  return (
    <Box
      appearance="none"
      as={as || (to ? Link : href ? "a" : onClick ? "button" : "span")}
      to={to || null}
      href={href || null}
      onClick={onClick || null}
      bg="transparent"
      cursor="pointer"
      textAlign="center"
      display="inline-flex"
      border={secondary ? undefined : 4}
      borderBottom={secondary ? 4 : undefined}
      px={secondary ? undefined : 3}
      py={2}
      borderColor={borderColor}
      sx={{ ":hover": { borderColor: color || "text" } }}
      {...restProps}
    >
      <Text bold as="span" size={size} color={color || "text"} sans>
        {children}
      </Text>
    </Box>
  )
}

Button.propTypes = {
  secondary: PropTypes.bool,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  size: PropTypes.number,
}

Button.defaultProps = {
  borderColor: "primary",
  size: 2,
}

export default Button
