import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Stack from "./stack"
import Text from "./text"

const QuoteBox = (props) => {
  const { children, author, size, variation, italic, ...restProps } = props
  return (
    <Box {...restProps} bg="black" p={6}>
      <Stack space={6}>
        <Text size={size}>
          <Text serif as="span" color="primary">
            »{" "}
          </Text>
          <Text color="white" italic={italic} serif as="span">
            {children}
          </Text>
          <Text serif as="span" color="primary">
            {" "}
            «
          </Text>
        </Text>
        {author && (
          <Text color="white" bold sans>
            {author}
          </Text>
        )}
      </Stack>
    </Box>
  )
}

QuoteBox.defaultProps = {
  italic: true,
  size: [3, 4, null, 5],
}

QuoteBox.propTypes = {
  author: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  italic: PropTypes.bool,
}

export default QuoteBox
