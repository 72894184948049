/** @jsx jsx */ import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import imageQuote from "../images/quote.svg"
import Box from "./box"
import Poem from "./poem"
import Paragraph from "./paragraph"
import Stack from "./stack"
import Text from "./text"
import Columns from "./columns"
import Column from "./column"

const QuotePrimary = (props) => {
  const { children, size, author, italic, ...restProps } = props
  return (
    <Box
      {...restProps}
      borderTop={4}
      borderBottom={4}
      borderColor="primary"
      py={8}
    >
      <Columns space={[3, 3, 6]} alignY="top">
        <Column width="content">
          <img
            src={imageQuote}
            alt=""
            role="presentation"
            width="51"
            height="39"
            sx={{ width: "auto", height: [4, 4, 5, 6], mt: 3 }}
          />
        </Column>
        <Column>
          <Stack space={6}>
            {children && React.Children.count(children) > 1 ? (
              <Poem size={size} italic={italic}>
                {children}
              </Poem>
            ) : (
              <Paragraph as="div" size={size} italic={italic}>
                {children}
              </Paragraph>
            )}
            {author && (
              <Text bold sans size={[2, 2, 3, 4]} color="primary">
                {author}
              </Text>
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  )
}

QuotePrimary.defaultProps = {
  italic: false,
}

QuotePrimary.propTypes = {
  author: PropTypes.string.isRequired,
  italic: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

export default QuotePrimary
