import React from "react"
import PropTypes from "prop-types"
import TextLink from "./textlink"
import Box from "./box"
import Icon from "./icon"

const ReadMoreButton = (props) => {
  const { children, as: element, onClick, ...restProps } = props

  return (
    <Box
      as={element}
      bg="transparent"
      href="#"
      onClick={onClick}
      alignY="center"
      {...restProps}
    >
      <TextLink>{children}</TextLink>
      <Box
        as="span"
        display="inline-flex"
        ml={1}
        bg="primary"
        color="background"
        borderRadius="full"
      >
        <Icon size={4} icon="plus" />
      </Box>
    </Box>
  )
}

ReadMoreButton.propTypes = {
  as: PropTypes.node,
  onClick: PropTypes.func,
}

ReadMoreButton.defaultProps = {
  as: "a",
}

export default ReadMoreButton
