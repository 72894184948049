import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import ReadMore from "../../../components/read-more"
import ReadMoreBox from "../../../components/read-more-box"
import Paragraph from "../../../components/paragraph"
import QuotePrimary from "../../../components/quote-primary"
import Constrain from "../../../components/constrain"
import Text from "../../../components/text"
import Stack from "../../../components/stack"
import KarteChandler from "./karte-chandler"

const HyperionFuegungen = (props) => {
  const data = useStaticQuery(graphql`
    query RaumFuegungenHyperionQuery {
      widmung: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/hyperion-widmung.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack space={24}>
      <Stack>
        <Constrain>
          <Heading as="h2" level={2}>
            Hölderlins Briefroman ›Hyperion oder Der Eremit in Griechenland‹
          </Heading>
        </Constrain>
        <Image
          image={data.widmung.childImageSharp.gatsbyImageData}
          alt="Aufgeschlagenes Buch. Die rechte Seite zeigt eine handschriftliche Widmung von Hölderlin: »Wem sonst als dir«"
          caption="Hölderlins ›Hyperion‹ von 1799 mit einer versteckten Widmung für Susette Gontard aus dem Deutschen Literaturarchiv Marbach"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              Als Student im Tübinger Stift hatte Hölderlin mit der Arbeit an
              seinem ›Hyperion‹ begonnen und noch im Turm entstanden Entwürfe zu
              einer Fortsetzung desselben.
            </Paragraph>
            <Paragraph>
              Die Form des Romans, eines Erzähltextes in ungebundener Rede, war
              damals gerade erst auf dem Weg, eine akzeptierte literarische
              Gattung zu werden. Auch Hölderlin hatte sich zunächst noch in
              einer Versfassung nach antikem Vorbild geübt, bevor er mit seinem
              Experimentalroman ein »neues schönes Land« betrat.
            </Paragraph>
            <Paragraph>
              Es gibt kaum ein Thema seiner Dichtung, das hier nicht zur Sprache
              kommt – etwa die Einheit mit der Natur, die Liebe und die
              Freundschaft, die Freiheit, das Göttliche, das Vorbild der Antike.
            </Paragraph>
            <Paragraph>
              Hölderlins erste Buchveröffentlichung erschien 1797 und 1799 in
              zwei Bänden im{" "}
              <ReadMore
                content={
                  <Constrain>
                    <Stack>
                      <Paragraph>
                        Der 1659 in Tübingen gegründete Cotta-Verlag galt Ende
                        des 18. Jahrhunderts als bedeutendster Literaturverlag
                        Deutschlands. Die großen Klassiker Schiller und Goethe
                        sowie Jean Paul und Heinrich von Kleist, der Philosoph
                        Johann Gottlieb Fichte und Alexander von Humboldt
                        veröffentlichten ihre Werke bei Johann Friedrich Cotta.
                        Große Zeitschriftenprojekte wie Schillers ›Horen‹ wurden
                        hier verlegt und die 1798 erstmals herausgegebene
                        ›Allgemeine Zeitung‹ avancierte im 19. Jahrhundert zu{" "}
                        <Text as="em">der</Text> deutschen Tageszeitung
                        schlechthin.
                      </Paragraph>
                      <Paragraph>
                        Aus der ehemaligen Cotta’schen Buchhandlung in Tübingen
                        ging auch die noch heute bestehende Buchhandlung
                        Osiander hervor.
                      </Paragraph>
                      <Paragraph>
                        1977 wurde der Cotta-Verlag mit dem Ernst Klett Verlag
                        zusammengelegt. Der Klett-Cotta Verlag sitzt in
                        Stuttgart.
                      </Paragraph>
                    </Stack>
                  </Constrain>
                }
              >
                renommierten Cotta-Verlag
              </ReadMore>
              . Der Roman fasziniert mitunter auch durch seine Nähe zur
              Biografie des Autors. Schließlich wurde auch Hölderlin zum
              Eremiten: nicht auf der Insel Salamis, sondern in einem Turm in
              Tübingen am Neckar.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain align="right">
          <QuotePrimary author="Friedrich Hölderlin: Hyperion oder der Eremit in Griechenland">
            … ich kann kein Volk mir denken, das zerrißner wäre, wie die
            Deutschen. Handwerker siehst du, aber keine Menschen, Denker, aber
            keine Menschen, Priester, aber keine Menschen, Herrn und Knechte,
            Jungen und gesezte Leute, aber keine Menschen ...
          </QuotePrimary>
        </Constrain>
        <Constrain>
          <ReadMoreBox cta="Mehr zum Hyperion">
            <Heading as="h3" level={5}>
              Ein Entwicklungsroman in Briefform
            </Heading>
            <Text size={[2, 3]}>
              In Hölderlins einzigem Roman schildert der Grieche Hyperion seinem
              deutschen Freund Bellarmin rückblickend seinen Bildungsweg: Im
              Widerstreit zwischen Denken und Handeln war er zum schreibenden
              Eremiten geworden. Vom Wunsch getrieben, jene Vollkommenheit und
              Einheit in der Welt wiederherzustellen, wie sie in der Antike
              geherrscht haben soll, folgte er zunächst seinem philosophischen
              Lehrer Adamas und lernte dann den tatkräftigen Alabanda kennen,
              der ihn für den griechischen Befreiungskampf gewann.
            </Text>
            <Text size={[2, 3]}>
              Enttäuscht von den plündernden Griechen, trat er eine
              Bildungsreise an, zu der ihn die Geliebte Diotima im Angesicht der
              Trümmer des besetzten Athens zuvor bewegen wollte – er sollte zum
              »Erzieher unseres Volkes« werden. Nach dieser Reise, die ihn auch
              nach Deutschland führte, zog er sich ernüchtert auf eine Insel
              zurück – und wird Dichter. Denn weder die Philosophie noch die Tat
              überwinden die Zerrissenheit der Gegenwart, sondern allein die
              Schönheit, wie sie sich besonders in der Sprache der Dichtkunst
              zeigt. So ist der Roman selbst die Erfüllung jener Hoffnung, die
              am Anfang von Hyperions Bildungsweg stand.
            </Text>
          </ReadMoreBox>
        </Constrain>
      </Stack>
      <KarteChandler />
    </Stack>
  )
}

export default HyperionFuegungen
