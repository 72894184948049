import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';

const Weihnacht2024 = () => {
  const data = useStaticQuery(graphql`
    query Weihnacht2024 {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        In der Adventszeit lädt der Hölderlinturm wieder ein zu einer Kalenderlesung bei Glühwein und Punsch: Jule Hölzgen, 
        Ensemblemitglied der Akademie für Gesprochenes Wort, liest aus Hubert Klöpfers Gedichtekalender für das Jahr 2025. 
        Im Verlauf des Abends entspinnt sich ein Gespräch über die editorische und performative Seite der Poesie, 
        und freilich übers Gedichte-Sammeln.
      </Paragraph>
      <Paragraph>
        Der handschriftliche Gedichtekalender von Hubert Klöpfer für das Jahr 2024, erschienen im Kröner-Verlag, 
        versammelt 24 Blätter voller klassischer, moderner, ganz gegenwärtiger Lieblingsgedichten in faksimilierter Abschrift - 
        für jeden Monat zwei eigene Gedichte und zwei poetische Erfahrungen. Jule Hölzgen rezitiert aus dem Kalender 
        und Hubert Klöpfer erzählt vom Sammeln und Abschreiben von Gedichten übers Jahr.
      </Paragraph>
      <Paragraph>
        Hubert Klöpfer, geboren 1951, studierte Germanistik, Philosophie und Theologie in Tübingen. 
        Er war Lektor eines Wissenschaftsverlags, freier Kritiker sowie langjähriger Geschäftsführer des Klöpfer & Meyer Verlages. 
        Seit 2020 ist er u.a. Herausgeber der literarischen »Edition Hubert Klöpfer bei Kröner«. Jule Hölzgen, Jahrgang 1994, 
        studierte Sprechkunst und -erziehung an der Hochschule für Musik und Darstellende Kunst in Stuttgart. 
        Seit 2019 ist sie festes Mitglied im Sprecherensemble der Akademie für gesprochenes Wort.
      </Paragraph>
      <Paragraph>
        Aufgrund des begrenzten Platzangebots empfehlen wir die um Anmeldung unter:{" "}
          <ExternalLink href="tel:004970712041860">
            <span style={{color: '#F7A61B'}}> 07071/204-1860</span> 
          </ExternalLink>
      </Paragraph>
      <Paragraph>
        Eine Veranstaltung des Museums Hölderlinturm in Kooperation mit der Akademie für Gesprochenes Wort.
      </Paragraph>
    </Stack>
  )
}

export default Weihnacht2024