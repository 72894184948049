import React from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Caption from "./caption"
import Stack from "./stack"
import Text from "./text"
import ReadMoreButton from "./read-more-button"

const ImageHotspots = (props) => {
  const { image, caption, showAll, children, ...restProps } = props

  const [isExpanded, setIsExpanded] = React.useState(false)

  function expandContent() {
    setIsExpanded(true)
  }

  return image ? (
    <Box {...restProps}>
      <Stack>
        <Stack space={3}>
          <Box position="relative">
            {image}
            {!isExpanded ? children : null}
          </Box>
          {caption && <Caption>{caption}</Caption>}
          {showAll ? (
            isExpanded ? null : (
              <Text as="div" sans align="right">
                <ReadMoreButton fontWeight="bold" onClick={expandContent}>
                  Alle anzeigen
                </ReadMoreButton>
              </Text>
            )
          ) : null}
        </Stack>
        {showAll && isExpanded ? (
          <Stack>
            {React.Children.toArray(children).map((child, i) => {
              return React.cloneElement(child, {
                expanded: true,
              })
            })}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  ) : null
}

ImageHotspots.propTypes = {
  caption: PropTypes.string,
  image: PropTypes.node,
  showAll: PropTypes.bool,
}

ImageHotspots.defaultProps = {
  showAll: false,
}

export default ImageHotspots
