import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"

const EinfuehrungGarten = (props) => {
  const data = useStaticQuery(graphql`
    query RaumIntroGartenQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/08-der-garten-zum-turm/cover.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Zu Hölderlins Zeit gehörte zum Turm lediglich ein kleiner Küchengarten,
        der 1876 überbaut wurde. Die daran angrenzende Grünfläche war Teil des
        Klinikums. Auch Hölderlin wurde dort im Zuge seiner Behandlung ein
        Spaziergang verordnet.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Blick auf den Hölderlinturm aus dem Garten. Im Vordergrund eine steinerne Treppe mit einer Schautafel"
        attribution="Gudrun de Maddalena"
      />
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Eine Treppe für Hölderlin?{" "}
          </Heading>
          <Paragraph>
            Im September 1806, zwölf Tage nach Hölderlins Einlieferung in das
            Universitätsklinikum, wurde die steinerne Treppe fertiggestellt, die
            der junge Direktor Autenrieth in Auftrag gegeben hatte.
          </Paragraph>
          <Paragraph>
            Schreinermeister Zimmer, der damals bereits Arbeiten für das
            Klinikum erledigt und auch den erkrankten Dichter dort besucht
            hatte, lieferte für den Abgang die passende »eichene Thüre 2 1/2
            Zoll dik, 9 Schu hoch, 6 Schu breit«. Autenrieth wollte einen Zugang
            von seiner Klinik in den Garten am Neckar schaffen, den er kurz
            zuvor »für den Zwek des Instituts« erworben hatte; im Brandfall kam
            man so z.B. leichter ans Wasser. Der Klinikumsgarten erstreckte sich
            von dem schmalen ›Küchengärtle‹ der Familie Zimmer, das beim
            Wiederaufbau des Hauses 1876 dem erweiterten Erdgeschoss weichen
            musste, bis hin zu der Tuchmacherrahme, in der die Färber ihre
            Tücher zum Trocknen aufhängten.
          </Paragraph>
          <Paragraph>
            Autenrieth nutzte den Garten auch therapeutisch: Zur Behandlung von
            »Melancholie und Hypochondrie« verordnete er Sturzbäder;
            Spaziergänge wurden ebenfalls verschrieben – »unter Aufsicht eines
            hinlänglich starken und verständigen Wärters«, auch »zur Belohnung
            von Wohlverhalten«. Hölderlin durfte fünf Wochen nach Beginn seiner
            Behandlung »Spazierengehen«. Im Mai 1807 wechselte er vom Klinikum
            in das Turmzimmer und sah Autenrieths Treppe nun von Zimmers
            ›Küchengärtle‹ aus.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungGarten
