import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Blockquote from "../../../components/blockquote"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspotsModalTemplate from "../../../components/image-hotspots-modal-template"
import ImageHotspots from "../../../components/image-hotspots"

const AussichtManuskript = (props) => {
  const data = useStaticQuery(graphql`
    query TurmzimmerAussichtManuskriptQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht.png"
        }
      ) {
        ...largeImage
      }
      aussicht01: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-01.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht02: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-02.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht03: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-03.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht04: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-04.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht05: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-05.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht06: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-06.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <ImageHotspots
      caption="Hölderlins Gedichtmanuskript ›Aussicht.‹ aus dem Deutschen Literaturarchiv Marbach"
      image={
        <Image
          image={data.gedicht.childImageSharp.gatsbyImageData}
          alt="Gedichtmanuskript ›Aussicht‹"
        />
      }
    >
      <ImageHotspot x={35} y={8} name="aussicht-01">
        <ImageHotspotsModalTemplate
          title="Aussicht."
          image={
            <Image
              image={data.aussicht01.childImageSharp.gatsbyImageData}
              alt="Vergrößerte Darstellung des Gedichttitels"
            />
          }
        >
          <Paragraph>
            Der Punkt, den Hölderlin hinter die meisten seiner Gedichttitel
            setzt, wurde häufig übersehen oder außer Acht gelassen. Er ist
            jedoch ein durchaus nicht unwesentliches Detail. Durch den Punkt
            entsteht eine Pause, ein Innehalten, das dem Titel erhöhte Wirkung
            verleiht, Spannung erzeugt, einen Resonanzraum erschafft.
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
      <ImageHotspot x={67} y={15} name="aussicht-02">
        <ImageHotspotsModalTemplate
          title="»Der off’ne Tag ist Menschen hell mit Bildern«"
          image={
            <Image
              image={data.aussicht02.childImageSharp.gatsbyImageData}
              alt="Der erste Vers des Gedichts"
            />
          }
        >
          <Paragraph>
            Schon der erste Vers des Gedichts räumt mit dem Klischee eines im
            dunklen Turm eingesperrten, »umnachteten« Dichters auf: Statt von
            verschlossenen Türen und engen Wänden ist hier vom »off’ne[n] Tag«
            und »hell[en] […] Bildern« die Rede. Der Blick öffnet sich in die
            Welt hinaus, die Hölderlin durch die 5 Fenster seines Turmzimmers
            ganz unmittelbar vor Augen hatte.
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
      <ImageHotspot x={27} y={20} name="aussicht-03">
        <ImageHotspotsModalTemplate
          title="Tintenklecks-Glamour"
          image={
            <Image
              image={data.aussicht03.childImageSharp.gatsbyImageData}
              alt="Tintenkleckse auf dem Manuskript"
            />
          }
        >
          <Paragraph>
            Wer ganz genau hinsieht, erkennt die vielen kleinen Tintenkleckse,
            die das Manuskript zieren. Die Tinte muss beim Ansetzen der Feder
            ziemlich gespritzt haben – und somit auch etwas von dem im Gedicht
            beschriebenen Glanz und Schimmer auf das Papier übertragen haben.
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
      <ImageHotspot x={17} y={85} name="aussicht-04">
        <ImageHotspotsModalTemplate
          title="1871?"
          image={
            <Image
              image={data.aussicht04.childImageSharp.gatsbyImageData}
              alt="Die vergrößerte Darstellung der linken Ecke zeigt Hölderlins Datierung auf den 24. März 1871"
            />
          }
        >
          <Paragraph>
            Auch dieses Turmgedicht datiert Hölderlin außerhalb seiner eigenen
            Lebenszeit – auf den 24. März 1871, fast 30 Jahre nach seinem Tod.
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
      <ImageHotspot x={20} y={90} name="aussicht-05">
        <ImageHotspotsModalTemplate
          title="Korrektur der Datierung"
          image={
            <Image
              image={data.aussicht05.childImageSharp.gatsbyImageData}
              alt="Handschriftliche Korrektur mit Bleistift"
            />
          }
        >
          <Paragraph>
            Von fremder Hand wurde Hölderlins Datierung mit Bleistift
            berichtigt: »Am 12. April 1842 von Hölderlin, dem Unglüklichen,
            geschrieben.«
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
      <ImageHotspot x={57} y={35} name="aussicht-06">
        <ImageHotspotsModalTemplate
          title="Klang oder Glanz? "
          image={
            <Image
              image={data.aussicht06.childImageSharp.gatsbyImageData}
              alt="Detailansicht der Handschrift"
            />
          }
        >
          <Paragraph>
            Die Herausgeber der ›Großen Stuttgarter Hölderlin-Ausgabe‹
            interpretierten Hölderlins Handschrift an dieser Stelle wie folgt:
          </Paragraph>
          <Paragraph>
            <Blockquote>
              »Noch eh’ des Abends Licht zur Dämmerung sich neiget, Und Schimmer
              sanft den Klang des Tages mildern.«
            </Blockquote>
          </Paragraph>
          <Paragraph>
            Gut 20 Jahre später korrigierte D. E. Sattler mit seiner
            ›Frankfurter Ausgabe‹ ein kleines, aber entscheidendes Wort: Nicht
            der »Klang des Tages« verändert sich durch die Schimmer, sondern
            dessen »Glanz«.
          </Paragraph>
        </ImageHotspotsModalTemplate>
      </ImageHotspot>
    </ImageHotspots>
  )
}

export default AussichtManuskript
