import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Accent from "../../../components/accent"
import AudioPlayer from "../../../components/audio-player"
import Blockquote from "../../../components/blockquote"
import Container from "../../../components/container"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import PoemMetric from "../../../components/poem-metric"
import Text from "../../../components/text"
import Stack from "../../../components/stack"
import Grid from "../../../components/grid"

const GartenTempo = (props) => {
  const data = useStaticQuery(graphql`
    query GartenTempoQuery {
      laufstrecke: file(
        relativePath: {
          eq: "dauerausstellung/08-der-garten-zum-turm/laufstrecke.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack space={[12, 24]}>
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Jedes Gedicht hat ein Tempo
            </Heading>
            <Paragraph>
              Der französische Germanist Pierre Bertaux, der das heutige
              Hölderlin-Bild maßgeblich mitgeprägt hat, behauptete, man könne
              Hölderlins Versen den schnellen Schritt des »rüstigen Wanderers«
              abspüren. Im Gegensatz dazu wirkten Goethes Hexamter »behäbig, wie
              Goethe selbst«. Bei diesem reiche »Tempo 60, ein Schritt pro
              Sekunde«. In diesem Tempo sei er, »die Hände im Rücken, in seinem
              Arbeitszimmer in Weimar in langsamem Schritt um den Tisch«
              gegangen; »Hölderlins Hexameter dagegen verlangt, im Tempo 80
              vorgetragen zu werden, weil es in diesem Tempo konzipiert wurde.«
            </Paragraph>
          </Stack>
        </Constrain>
        <Image
          image={data.laufstrecke.childImageSharp.gatsbyImageData}
          alt="Bild der Gedicht-Laufstrecke im Garten"
        />
        <Constrain>
          <Stack>
            <Paragraph>
              In der Tat spricht vieles dafür, dass Hölderlin seine Verse im
              Gehen erprobt hat. Er bekannte bereits als Klosterschüler in
              Maulbronn:
            </Paragraph>
            <Blockquote as="blockquote">
              <Paragraph as="span">
                »Ich mache wirklich über Hals und Kopf Verse […] Auf meinen
                Spaziergängen reim‘ ich allemal in meine Schreibtafel.« Und aus
                der Zeit im Tübinger Stift berichtet Magenau, wie der
                Studienfreund »Holz« mit seinen Versen im Schlafsaal auf– und
                abgelaufen sei: »Nur hie u(nd) da erschallt der Ochsenstall von
                Holzens Centaurähnchem Poeten Schritt, wenn allen fals aufs
                Wörtchen: Fluchtal: der schwere Reim ihm noch gebricht.«
              </Paragraph>
            </Blockquote>
            <Paragraph>
              Ob man Hölderlins Gedichten dabei anmerkt, dass ihr Verfasser ein
              sportlicher Wanderer war, der Strecken von 50 Kilometern am Tag
              zurücklegte? Der den Rhythmus seiner Verse im Gehen ausprobierte?
              Das kann jeder selbst ausprobieren. Auf unserer Gedichtlaufstrecke
              im Garten – oder aber auch zuhause im eigenen Wohnzimmer.
              Versuchen Sie, Ihre Laufgeschwindigkeit an den gleichmäßigen Takt
              des Gedichts anzugleichen. Welches Vortragstempo, beziehungsweise
              welche Schrittgeschwindigkeit passt Ihrer Meinung nach am besten
              zum Gedicht?
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>
      <Container maxWidth="4xl">
        <Stack space={3}>
          <PoemMetric
            title="Der Gang aufs Land"
            subtitle="Ein Gedicht – drei Geschwindigkeiten"
            size={[3, 3, 3, 4]}
            space={3}
          >
            <span>
              <Accent>Komm</Accent>! ins <Text as="span">|</Text>{" "}
              <Accent>O</Accent>ffene, <Text as="span">|</Text>{" "}
              <Accent>Freund</Accent>! zwar <Text as="span">|</Text>{" "}
              <Accent>glänzt</Accent> ein <Text as="span">|</Text>{" "}
              <Accent>We</Accent>niges <Text as="span">|</Text>{" "}
              <Accent>heu</Accent>te
            </span>
            <span>
              <Accent>Nur</Accent> he
              <Text as="span">|</Text>
              <Accent>runter</Accent> und <Text as="span">|</Text>{" "}
              <Accent>eng</Accent> <Text as="span">|</Text>{" "}
              <Accent>schlie</Accent>ßet der <Text as="span">|</Text>{" "}
              <Accent>Him</Accent>mel uns <Text as="span">|</Text>{" "}
              <Accent>ein</Accent>.
            </span>
            <span>
              <Accent>We</Accent>der die <Text as="span">|</Text>{" "}
              <Accent>Ber</Accent>ge <Text as="span">|</Text>{" "}
              <Accent>sind</Accent> noch <Text as="span">|</Text>{" "}
              <Accent>auf</Accent>ge
              <Text as="span">|</Text>
              <Accent>gan</Accent>gen des <Text as="span">|</Text>{" "}
              <Accent>Wal</Accent>des
            </span>
            <span>
              <Accent>Gip</Accent>fel nach <Text as="span">|</Text>{" "}
              <Accent>Wunsch</Accent> und <Text as="span">|</Text>{" "}
              <Accent>leer</Accent> <Text as="span">|</Text>{" "}
              <Accent>ruht</Accent> von Ge
              <Text as="span">|</Text>
              <Accent>san</Accent>ge die <Text as="span">|</Text>{" "}
              <Accent>Luft</Accent>.
            </span>
            <span>
              <Accent>Trüb</Accent> ists <Text as="span">|</Text>{" "}
              <Accent>heut</Accent>, es <Text as="span">|</Text>{" "}
              <Accent>schlum</Accent>mern die <Text as="span">|</Text>{" "}
              <Accent>Gäng</Accent>‘ und die <Text as="span">|</Text>{" "}
              <Accent>Gas</Accent>sen und <Text as="span">|</Text>{" "}
              <Accent>fast</Accent> will
            </span>
            <span>
              <Accent>Mir</Accent> es <Text as="span">|</Text>{" "}
              <Accent>schei</Accent>nen, es <Text as="span">|</Text>{" "}
              <Accent>sei</Accent>, <Text as="span">|</Text>{" "}
              <Accent>als</Accent> in der <Text as="span">|</Text>{" "}
              <Accent>blei</Accent>ernen <Text as="span">|</Text>{" "}
              <Accent>Zeit</Accent>.
            </span>
            <span>
              <Accent>Den</Accent>noch ge
              <Text as="span">|</Text>
              <Accent>lin</Accent>get der <Text as="span">|</Text>{" "}
              <Accent>Wunsch</Accent>, Recht<Text as="span">|</Text>{" "}
              <Accent>glau</Accent>bige <Text as="span">|</Text>{" "}
              <Accent>zwei</Accent>feln an <Text as="span">|</Text>{" "}
              <Accent>Ei</Accent>ner
            </span>
            <span>
              <Accent>Stun</Accent>de <Text as="span">|</Text>{" "}
              <Accent>nicht</Accent> und der <Text as="span">|</Text>{" "}
              <Accent>Lust</Accent> <Text as="span">|</Text>{" "}
              <Accent>blei</Accent>be ge
              <Text as="span">|</Text>
              <Accent>wei</Accent>het der <Text as="span">|</Text>{" "}
              <Accent>Tag</Accent>.
            </span>
          </PoemMetric>
          <Grid columns={[1, 1, 3]} space={3}>
            <AudioPlayer
              src={`dauerausstellung/08-der-garten-zum-turm/gang-aufs-land`}
              title="Langsam"
            />
            <AudioPlayer
              src={`dauerausstellung/08-der-garten-zum-turm/gang-aufs-land-72`}
              title="Gemäßigt"
            />
            <AudioPlayer
              src={`dauerausstellung/08-der-garten-zum-turm/gang-aufs-land-92`}
              title="Zügig"
            />
          </Grid>
        </Stack>
      </Container>
    </Stack>
  )
}

export default GartenTempo
