import Albinus from "../../../../src/content/aus-dem-archiv-geholt/band-07-keppler/albinus";
import Denkmal from "../../../../src/content/aus-dem-archiv-geholt/band-07-keppler/denkmal";
import Portrait from "../../../../src/content/aus-dem-archiv-geholt/band-07-keppler/portrait";
import Manuskript from "../../../../src/content/aus-dem-archiv-geholt/band-07-keppler/manuskript";
import Poem from "../../../../src/components/poem";
import QuoteBox from "../../../../src/components/quote-box";
import Small from "../../../../src/components/small";
import "katex/dist/katex.css";
import * as React from 'react';
export default {
  Albinus,
  Denkmal,
  Portrait,
  Manuskript,
  Poem,
  QuoteBox,
  Small,
  React
};