import Heading from "../../../../src/components/heading";
import Inline from "../../../../src/components/inline";
import Poem from "../../../../src/components/poem";
import Small from "../../../../src/components/small";
import Stack from "../../../../src/components/stack";
import { ExternalLink } from 'react-external-link';
import StammbuchRegister from "../../../../src/content/aus-dem-archiv-geholt/band-02-stammbuch/stammbuch-register";
import StammbuchHoelderlin from "../../../../src/content/aus-dem-archiv-geholt/band-02-stammbuch/stammbuch-hoelderlin";
import StammbuchPolit from "../../../../src/content/aus-dem-archiv-geholt/band-02-stammbuch/stammbuch-polit";
import StammbuchStipendiaten from "../../../../src/content/aus-dem-archiv-geholt/band-02-stammbuch/stammbuch-stipendiaten";
import StammbuchAlterMann from "../../../../src/content/aus-dem-archiv-geholt/band-02-stammbuch/stammbuch-alter-mann";
import * as React from 'react';
export default {
  Heading,
  Inline,
  Poem,
  Small,
  Stack,
  ExternalLink,
  StammbuchRegister,
  StammbuchHoelderlin,
  StammbuchPolit,
  StammbuchStipendiaten,
  StammbuchAlterMann,
  React
};