import React from "react"
import { useThemeUI } from "theme-ui"
import BarChart from "../../../components/bar-chart"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"

const JahreszeitenDatierung = (props) => {
  const context = useThemeUI()
  const { theme } = context

  const dataDatierung = [
    {
      date: "Fiktiv",
      Frühling: 4,
      FrühlingColor: theme.colors.spring,
      Sommer: 4,
      SommerColor: theme.colors.summer,
      Herbst: 1,
      HerbstColor: theme.colors.autumn,
      Winter: 3,
      WinterColor: theme.colors.winter,
    },
    {
      date: "Korrekt",
      Frühling: 2,
      FrühlingColor: theme.colors.spring,
      Sommer: 0,
      SommerColor: theme.colors.summer,
      Herbst: 0,
      HerbstColor: theme.colors.autumn,
      Winter: 1,
      WinterColor: theme.colors.winter,
    },
    {
      date: "Keine",
      Frühling: 3,
      FrühlingColor: theme.colors.spring,
      Sommer: 1,
      SommerColor: theme.colors.summer,
      Herbst: 1,
      HerbstColor: theme.colors.autumn,
      Winter: 1,
      WinterColor: theme.colors.winter,
    },
  ]

  const dataCenturies = [
    {
      id: "17. Jhd",
      label: "17. Jahrhundert",
      Gedichte: 2,
      color: theme.colors.yellow[300],
    },
    {
      id: "18. Jhd",
      label: "18. Jahrhundert",
      Gedichte: 8,
      color: theme.colors.yellow[500],
    },
    {
      id: "19. Jhd",
      label: "19. Jahrhundert",
      Gedichte: 4,
      color: theme.colors.yellow[400],
    },
    {
      id: "20. Jhd",
      label: "20. Jahrhundert",
      Gedichte: 1,
      color: theme.colors.yellow[700],
    },
  ]

  return (
    <Stack space={[12, 24]}>
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Hölderlins Datierung der Jahreszeitengedichte
            </Heading>
            <Paragraph>
              Selten entsprechen Hölderlins Datierungen dem realen
              Entstehungstag der Gedichte …
            </Paragraph>
          </Stack>
        </Constrain>
        <BarChart
          height={[72, 92, 100]}
          enableGridX={false}
          enableGridY={true}
          data={dataDatierung}
          colors={({ id, data }) => data[`${id}Color`]}
          keys={["Frühling", "Sommer", "Herbst", "Winter"]}
          axisTop={null}
          axisRight={null}
          margin={{ top: 20, right: 30, bottom: 70, left: 30 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Datierung",
            legendPosition: "middle",
            legendOffset: 50,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          indexBy="date"
        />
      </Stack>

      <Stack>
        <Constrain>
          <Stack>
            <Paragraph>… zuweilen nicht einmal seiner Lebenszeit.</Paragraph>
          </Stack>
        </Constrain>
        <BarChart
          height={[72, 92, 100]}
          enableGridX={false}
          enableGridY={true}
          data={dataCenturies}
          colors={dataCenturies.map((data) => data["color"])}
          axisTop={null}
          axisRight={null}
          margin={{ top: 20, right: 30, bottom: 70, left: 30 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Datierungen nach Jahrhunderten",
            legendPosition: "middle",
            legendOffset: 50,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          keys={["Gedichte"]}
          indexBy="id"
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 2.5]],
          }}
          colorBy="value"
        />
        <Constrain>
          <Trivia title="Zur Erinnerung">
            Hölderlin lebte von 1770 bis 1843. Die Datierungen aber reichen vom
            17. bis ins 20. Jahrhundert. Dabei liegen 73% der Datierungen
            außerhalb seiner Lebenszeit.
          </Trivia>
        </Constrain>
      </Stack>
    </Stack>
  )
}

export default JahreszeitenDatierung
