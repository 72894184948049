import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Text from "./text"
import Stack from "./stack"

const Trivia = (props) => {
  const { children, size, title, ...restProps } = props

  return (
    <Box {...restProps}>
      <Box display="inline-flex" mb="-4px">
        <Box
          pb={2}
          borderBottom="4px"
          borderColor="primary"
          position="relative"
          zIndex="10"
        >
          <Text as="span" bold uppercase sans size="10px" letterSpacing="2px">
            {title}
          </Text>
        </Box>
      </Box>
      <Box bg="muted" p={6}>
        <Text as="div" size={size}>
          <Stack space={6}>{children}</Stack>
        </Text>
      </Box>
    </Box>
  )
}

Trivia.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Trivia.defaultProps = {
  title: "Im Übrigen",
  size: [3, 3, 4, 5],
}

export default Trivia
