import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import Image from "../../../components/image"
import Stack from "../../../components/stack"

const Manuskript = () => {
  const data = useStaticQuery(graphql`
    query {
      vorderseite: file(
        relativePath: { eq: "aus-dem-archiv-geholt/07/keppler-1.png" }
      ) {
        ...mediumImage
      }
      rueckseite: file(
        relativePath: { eq: "aus-dem-archiv-geholt/07/keppler-2.png" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Stack space={3}>
      <Grid columns={[1, 2]} space={3}>
        <Image
          image={data.vorderseite.childImageSharp.gatsbyImageData}
          alt="Ein handbeschriebenes altes Papier mit den ersten 6 Strophen von Hölderlins Gedicht ›Keppler.‹."
          caption={
            <>
              <span>
                Erstes Blatt aus dem Deutschen Literaturarchiv Marbach
              </span>
              <br />
              <br />
              <span>
                Links oben von fremder Hand »26)«, am unteren Rand Vermerk
                »Hölderlin« von Carl Künzel, jedoch sehr schwach und kaum mehr
                erkennbar.
              </span>
            </>
          }
        />
        <Image
          image={data.rueckseite.childImageSharp.gatsbyImageData}
          alt="Ein handbeschriebenes altes Papier mit den letzten 3 Strophen von Hölderlins Gedicht ›Keppler.‹ und dem Anfang seines Gedichts ›An Thills Grab.‹."
          caption={
            <>
              <span>
                Zweites Blatt aus dem Freien Deutschen Hochstift Frankfurt
              </span>
              <br />
              <br />
              <span>
                Es enthält auch die ersten beiden Strophen von ›An Thills
                Grab.‹. Links oben und in der Mitte links von fremder Hand
                jeweils »printed« hinzugefügt, rechts oben Vermerk Mörikes:
                »Fried Hölderlins Handschrift, deren Ächtheit T. Dr. Ed.
                Mörike.«; ›T.‹ für »testatur«, möglicherweise auch »Tübingen«.
              </span>
            </>
          }
        />
      </Grid>
    </Stack>
  )
}

export default Manuskript
