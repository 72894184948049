import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import { ExternalLink } from 'react-external-link';

const Anruf = () => {
  const data = useStaticQuery(graphql`
    query Anruf {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Anfang des neuen Jahres findet bereits zum zweiten Mal eine telefonische Führung zur aktuell laufenden Sonderausstellung 
        „Turm in Flammen! Die Fahndungsakte Feuerteufel“ statt. Unsere Volontärin geht gemeinsam mit den Teilnehmenden am Ohr 
        durch die Ausstellung und beschreibt die visuellen Inhalte und Erfahrungen beim Rundgang am Telefon. 
        Das Museum Hölderlinturm nimmt damit am Programm „Bei Anruf Kultur“ teil, 
        das mittlerweile als ein überregionales Angebot verschiedenste kulturelle Inhalte vermittelt.
      </Paragraph>
      <Paragraph>
        „Bei Anruf Kultur“ entstand 2021. Im Corona-Lockdown starteten der Blinden- und Sehbehindertenverein Hamburg e.V. (BSVH) 
        und grauwert, Büro für Inklusion & demografiefeste Lösungen, gemeinsam mit einigen Museen in Hamburg das Projekt. 
        Dank der Unterstützung durch die Aktion Mensch wird das Angebot bis 2026 im deutschsprachigen Raum ausgebaut 
        und als inklusives und barrierefreies Vermittlungsformat weiterentwickelt und etabliert.
      </Paragraph>
      <Paragraph>
        Mit der Teilnahme an„Bei Anruf Kultur“ möchte das Museum Hölderlinturm Menschen unabhängig von einer Behinderung, 
        ihrem Wohnort oder ihrer Mobilität den Zugang zu Kultur zu ermöglichen. Mit den Telefonführung öffnet sich das Dichterwohnhaus 
        damit für neue Zielgruppen.
      </Paragraph>
      <Paragraph>
        Eine Anmeldung ist auf der Plattform{" "}
          <ExternalLink href="www.beianrufkultur.de%20">
            <span style={{color: '#F7A61B'}}> www.beianrufkultur.de </span> 
          </ExternalLink>
        oder telefonisch unter 040 209 404 36
          <ExternalLink href="tel:04020940436">
            <span style={{color: '#F7A61B'}}> 040 209 404 36 </span> 
          </ExternalLink>
        erforderlich. Im Anschluss erhalten die Interessierten die telefonischen Zugangsdaten und wählen sich am Veranstaltungstag 
        bequem von zu Hause ein.
      </Paragraph>
      <Paragraph>
        Die Teilnahme ist kostenfrei.
      </Paragraph>
    </Stack>
  )
}

export default Anruf