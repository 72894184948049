import React from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Icon from "./icon"
import { Link } from "gatsby"

const SquareButton = (props) => {
  const { disabled, to, href, icon, onClick, children, ...restProps } = props

  const _isInteractive = !disabled || href || to || onClick

  const StyledButton = ({ as, disabled }) => (
    <Box
      appearance="none"
      as={as}
      to={to}
      href={href}
      onClick={onClick}
      bg={disabled ? "muted" : "primary"}
      color={disabled ? "disabled" : "black"}
      p={3}
      cursor={disabled ? "default" : "pointer"}
      textAlign="center"
      display="inline-flex"
      alignX="center"
      alignY="center"
      sx={
        !_isInteractive && {
          ":hover, :focus": {
            color: "primary",
            bg: "black",
          },
        }
      }
      {...restProps}
    >
      <Icon icon={icon} />
      {children && (
        <Box
          display={["none", "inline-flex"]}
          fontFamily="sans"
          fontWeight="bold"
          ml={3}
        >
          {children}
        </Box>
      )}
    </Box>
  )

  return disabled ? (
    <StyledButton as="div" disabled />
  ) : to ? (
    <Link to={to}>
      <StyledButton to={to} as="span" />
    </Link>
  ) : href ? (
    <StyledButton href={href} as="a" />
  ) : onClick ? (
    <StyledButton onClick={onClick} as="button" />
  ) : (
    <StyledButton as="div" />
  )
}

SquareButton.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
}

SquareButton.defaultProps = {
  icon: "plus",
}

export default SquareButton
