import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Lieblingswoerter = () => {
  const data = useStaticQuery(graphql`
    query Lieblingswoerter {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
        <Paragraph>
          Der Hölderlinturm lädt alle kreativen Köpfe im Alter von 7 bis 13 Jahren herzlich ein, 
          ihre Lieblingswörter zum Leben zu erwecken! Am 31.10.2024 erwartet die Teilnehmer im Hölderlinturm ein Vormittag voller Kreativität 
          und Freude. Unter dem Motto „Lauter Lieblingswörter“ haben die Kinder die Möglichkeit, lyrische Armbänder zu gestalten 
          und Marmeladengläser mit ihren ganz persönlichen Lieblingswörtern zu füllen und zu verzieren. 
          Alle benötigten Materialien werden kostenfrei zur Verfügung gestellt. Der Workshop beginnt um 10 Uhr. 
          Wir bitten alle Teilnehmenden, rechtzeitig zu erscheinen, damit wir genügend Zeit zum Basteln haben.
          Da die Teilnehmerzahl begrenzt ist, empfehlen wir eine frühzeitige Anmeldung über unsere Webseite.
        </Paragraph>
        <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href=" https://app.cituro.com/booking/stadtmuseumtuebingen">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Lieblingswoerter