import React from "react"
import PropTypes from "prop-types"

import Text from "./text"

const NoBreak = (props) => {
  const { children, as, ...restProps } = props

  return (
    <Text
      as={as}
      {...restProps}
      sx={{
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Text>
  )
}

NoBreak.propTypes = {
  as: PropTypes.string,
}

NoBreak.defaultProps = {
  as: "span",
}

export default NoBreak
