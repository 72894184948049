import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { forwardRef } from "react"

const alignFromProp = (prop) =>
  ({
    top: "flex-start",
    left: "flex-start",
    center: "center",
    right: "flex-end",
    bottom: "flex-end",
  }[prop] || prop)

const flexFromProp = (prop) =>
  ({
    auto: "1 1 auto",
    initial: "0 1 auto",
    1: "1 1 0%",
  }[prop] || prop)

const orderFromProp = (prop) =>
  ({
    first: -9999,
    last: 9999,
    none: 0,
  }[prop] || prop)

/**
 * One Box to rule them all
 */
const Box = forwardRef((props, ref) => {
  const {
    as: Element,
    appearance,
    alignX,
    alignY,
    alignItems,
    bg,
    border,
    borderBottom,
    borderColor,
    borderLeft,
    borderRadius,
    borderRight,
    borderStyle,
    borderTop,
    borderX,
    borderY,
    bottom,
    boxShadow,
    children,
    cursor,
    color,
    display,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    flex,
    float,
    fontFamily,
    fontWeight,
    order,
    height,
    justifyContent,
    left,
    letterSpacing,
    listStyleType,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    opacity,
    overflow,
    overflowX,
    overflowY,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    pointerEvents,
    position,
    right,
    sx,
    textDecoration,
    textAlign,
    top,
    visibility,
    textIndent,
    userSelect,
    verticalAlign,
    wordBreak,
    width,
    zIndex,
    ...restProps
  } = props

  const alignToFlexAlign = (align) =>
    Array.isArray(align) ? align.map(alignFromProp) : alignFromProp(align)

  const resolveFlex = (flex) =>
    Array.isArray(flex) ? flex.map(flexFromProp) : flexFromProp(flex)

  const resolveFlexOrder = (order) =>
    Array.isArray(order) ? order.map(orderFromProp) : orderFromProp(order)

  const resolvedAlignY =
    alignItems ||
    alignToFlexAlign(
      flexDirection &&
        (flexDirection === "column" || flexDirection === "column-reverse")
        ? alignX
        : alignY
    )
  const resolvedAlignX =
    justifyContent ||
    alignToFlexAlign(
      (flexDirection && flexDirection === "column-reverse") ||
        flexDirection === "column-reverse"
        ? alignY
        : alignX
    )

  const resolvedBorderTop = borderTop || borderY || border
  const resolvedBorderBottom = borderBottom || borderY || border
  const resolvedBorderLeft = borderLeft || borderX || border
  const resolvedBorderRight = borderRight || borderX || border

  const resolvedPaddingTop = pt || py || p
  const resolvedPaddingBottom = pb || py || p
  const resolvedPaddingLeft = pl || px || p
  const resolvedPaddingRight = pr || px || p

  const resolvedMarginTop = mt || my || m
  const resolvedMarginBottom = mb || my || m
  const resolvedMarginLeft = ml || mx || m
  const resolvedMarginRight = mr || mx || m

  return (
    <Element
      {...restProps}
      ref={ref}
      sx={{
        appearance,
        alignItems: resolvedAlignY || undefined,
        bg,
        borderColor,
        borderRadius,
        borderStyle:
          resolvedBorderTop ||
          resolvedBorderBottom ||
          resolvedBorderLeft ||
          resolvedBorderRight
            ? borderStyle
            : undefined,
        bottom,
        boxShadow,
        color,
        cursor,
        display,
        flexDirection,
        flexGrow,
        flexShrink,
        flexWrap,
        flex: resolveFlex(flex),
        float,
        fontFamily,
        fontWeight,
        order: resolveFlexOrder(order),
        height,
        justifyContent: resolvedAlignX || undefined,
        left,
        letterSpacing,
        listStyleType,
        borderRightWidth: resolvedBorderRight || undefined,
        borderLeftWidth: resolvedBorderLeft || undefined,
        borderBottomWidth: resolvedBorderBottom || undefined,
        borderTopWidth: resolvedBorderTop || undefined,
        paddingRight: resolvedPaddingRight || undefined,
        paddingLeft: resolvedPaddingLeft || undefined,
        paddingBottom: resolvedPaddingBottom || undefined,
        paddingTop: resolvedPaddingTop || undefined,
        marginRight: resolvedMarginRight || undefined,
        marginLeft: resolvedMarginLeft || undefined,
        marginBottom: resolvedMarginBottom || undefined,
        marginTop: resolvedMarginTop || undefined,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        opacity,
        overflow,
        overflowX,
        overflowY,
        pointerEvents,
        position,
        right,
        textDecoration,
        textAlign,
        textIndent,
        verticalAlign,
        top,
        userSelect,
        visibility,
        wordBreak,
        width,
        zIndex,
        ...sx,
      }}
    >
      {children}
    </Element>
  )
})

Box.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.element,
  ]),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

Box.defaultProps = {
  as: "div",
  borderStyle: "solid",
}

export default Box
