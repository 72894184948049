import React from "react"
import PropTypes from "prop-types"
import Columns from "./columns"
import Column from "./column"

const Constrain = (props) => {
  const { children, align, ...restProps } = props
  return (
    <Columns alignX={align} {...restProps}>
      <Column width={["full", "5/6", "2/3"]}>{children}</Column>
    </Columns>
  )
}

Constrain.propTypes = {
  align: PropTypes.string,
}

Constrain.defaultProps = {
  align: "center",
}

export default Constrain
