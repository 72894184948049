import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../../../components/image"

const Faltung = () => {
  const data = useStaticQuery(graphql`
    query {
      faltung: file(
        relativePath: { eq: "aus-dem-archiv-geholt/06/faltung.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Image
      image={data.faltung.childImageSharp.gatsbyImageData}
      caption="Hölderlins Brief an die Schwester war ursprünglich mehrmals gefaltet und ineinander gesteckt. Papierrestauratorin Wiebke Müller hat für diese Rekonstruktion einen Papierbogen in Originalgröße verwendet."
      attribution="Wolfgang Pfauder"
      alt="Ein gefaltetes Blatt Papier vor einem grauen Hintergrund."
    />
  )
}

export default Faltung
