import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"
import QuotePrimary from "../../../components/quote-primary"

const Neckar = (props) => {
  const data = useStaticQuery(graphql`
    query GartenNeckarQuery {
      neckar: file(
        relativePath: {
          eq: "dauerausstellung/08-der-garten-zum-turm/neckar.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Auf dem Neckar nach Griechenland
          </Heading>
          <Paragraph>
            Der Neckar war Hölderlins Fluss. »In deinen Tälern wachte mein Herz
            mir auf«, heißt es in der dem Fluss gewidmeten Ode. Am Neckar wurde
            Hölderlin geboren – in Lauffen. Am Neckar wuchs er auf – in
            Nürtingen. Und am Neckar liegt er begraben – hier in Tübingen. In
            der zweiten Hälfte seines Lebens hatte er den Fluss von seinem
            Zimmer aus täglich vor Augen.
          </Paragraph>
        </Stack>
      </Constrain>
      <Image
        image={data.neckar.childImageSharp.gatsbyImageData}
        alt="Blick auf den Neckar und den von Trauerweiden umgebenen Hölderlinturm von der Neckarbrücke"
        attribution="Gudrun de Maddalena"
      />
      <Constrain>
        <Paragraph>
          Gewiss hat Hölderlin auch die Donau bedichtet, und der tiefe Eindruck,
          den der »stillerhabene« Rhein bei ihn hinterlassen hat, ist etlichen
          seiner Verse abzuspüren. Aus keinem dieser Gedichte spricht jedoch ein
          so inniges Vertrauensverhältnis wie aus der Ode, die er als
          30-Jähriger an den Neckar adressiert hat. Inmitten des Bekenntnisses
          zum heimatlichen Fluss wechselt die Perspektive zu Hölderlins
          Sehnsuchtsort Griechenland. Aber auch dort weicht ihm der Neckar nicht
          »aus treuem Sinn«.
        </Paragraph>
      </Constrain>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Der Nekar">
          <span>Zu euch, ihr Inseln! bringt mich vielleicht, zu euch</span>
          <span>Mein Schuzgott einst; doch weicht mir aus treuem Sinn</span>
          <span>Auch da mein Nekar nicht mit seinen</span>
          <span>Lieblichen Wiesen und Uferweiden.</span>
        </QuotePrimary>
      </Constrain>
    </Stack>
  )
}

export default Neckar
