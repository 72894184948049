import EinfuehrungFuegungen from "../../../../src/content/dauerausstellung/07-harte-fuegungen/einfuehrung-fuegungen";
import EmpedoklesFuegungen from "../../../../src/content/dauerausstellung/07-harte-fuegungen/empedokles-fuegungen";
import HyperionFuegungen from "../../../../src/content/dauerausstellung/07-harte-fuegungen/hyperion-fuegungen";
import Gedichtwerk from "../../../../src/content/dauerausstellung/07-harte-fuegungen/gedichtwerk";
import * as React from 'react';
export default {
  EinfuehrungFuegungen,
  EmpedoklesFuegungen,
  HyperionFuegungen,
  Gedichtwerk,
  React
};