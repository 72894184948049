import Stack from "../../../../src/components/stack";
import Heading from "../../../../src/components/heading";
import Paragraph from "../../../../src/components/paragraph";
import Grid from "../../../../src/components/grid";
import Container from "../../../../src/components/container";
import QuoteBox from "../../../../src/components/quote-box";
import Box from "../../../../src/components/box";
import Text from "../../../../src/components/text";
import Small from "../../../../src/components/small";
import Poem from "../../../../src/components/poem";
import Image from "../../../../src/components/image";
import EleusisManuskript from "../../../../src/content/aus-dem-archiv-geholt/band-01-eleusis/eleusis-manuskript";
import EleusisHegel from "../../../../src/content/aus-dem-archiv-geholt/band-01-eleusis/eleusis-hegel";
import RichardSchumm from "../../../../src/content/aus-dem-archiv-geholt/band-01-eleusis/richard-schumm";
import * as React from 'react';
export default {
  Stack,
  Heading,
  Paragraph,
  Grid,
  Container,
  QuoteBox,
  Box,
  Text,
  Small,
  Poem,
  Image,
  EleusisManuskript,
  EleusisHegel,
  RichardSchumm,
  React
};