import PropTypes from "prop-types"
import React from "react"

import Box from "./box"

const Inline = (props) => {
  const { children, as: element, space, ...restProps } = props

  const isList = element === "ol" || element === "ul"
  const spaceFromProps = space
  const negativeSpace = Array.isArray(spaceFromProps)
    ? spaceFromProps.map((value) => (value !== 0 ? -value : value))
    : space * -1

  return (
    <Box
      as={element}
      display="flex"
      flexWrap="wrap"
      listStyleType={isList ? "none" : null}
      ml={negativeSpace}
      mt={negativeSpace}
      mb={isList ? "0px" : null}
      p={isList ? "0px" : null}
      {...restProps}
    >
      {/* We need to turn children into an array to allow for if/else statements within stacks */}
      {React.Children.toArray(children).map((child, i) => {
        return (
          <Box
            as={isList ? "li" : "div"}
            display="inline-flex"
            key={i}
            pt={space}
            pl={space}
          >
            {child}
          </Box>
        )
      })}
    </Box>
  )
}

Inline.propTypes = {
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Inline.defaultProps = {
  space: 3,
}

export default Inline
