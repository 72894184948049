import React from "react"
import PropTypes from "prop-types"
import ReactCompareImage from "react-compare-image"
import ImageCompareHandle from "./image-compare-handle"
import Caption from "./caption"
import Stack from "./stack"

const ImageCompare = (props) => {
  const { first, second, caption, position, ...restProps } = props

  return first && second ? (
    <Stack space={3}>
      <ReactCompareImage
        leftImage={first}
        rightImage={second}
        handle={<ImageCompareHandle />}
        sliderPositionPercentage={position}
        sliderLineWidth={4}
        {...restProps}
      />
      {caption && <Caption align="center">{caption}</Caption>}
    </Stack>
  ) : null
}

ImageCompare.propTypes = {
  first: PropTypes.string,
  second: PropTypes.string,
  caption: PropTypes.string,
  position: PropTypes.number,
}

ImageCompare.defaultProps = {
  position: 0.5,
}

export default ImageCompare
