import React, { useState } from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Button from "./button"
import Stack from "./stack"
import Text from "./text"
import { alpha } from "@theme-ui/color"

const ReadMoreBox = (props) => {
  const { children, previewSize, cta, ...restProps } = props

  const [state, setState] = useState({
    expanded: false,
  })

  const toggleReadMore = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    })
  }

  return (
    <Box bg="muted" p={[6, 12]} {...restProps}>
      <Stack space="6">
        <Box
          position="relative"
          overflow="hidden"
          height={state.expanded ? "auto" : previewSize}
        >
          <Text as="div" size={[2, 3]}>
            <Stack space={6}>{children}</Stack>
          </Text>
          {!state.expanded ? (
            <Box
              height="12"
              position="absolute"
              left="0"
              right="0"
              bottom="0"
              width="full"
              sx={{
                backgroundImage: (theme) => `
                  linear-gradient(
                    180deg,
                    ${alpha("muted", 0)(theme)} 0,
                    ${alpha("muted", 1)(theme)} 100%
                  )
                `,
              }}
            />
          ) : null}
        </Box>
        {!state.expanded ? (
          <Button secondary onClick={toggleReadMore}>
            {cta}
          </Button>
        ) : null}
      </Stack>
    </Box>
  )
}

ReadMoreBox.propTypes = {
  previewSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cta: PropTypes.string,
}

ReadMoreBox.defaultProps = {
  previewSize: 48,
  cta: "Mehr erfahren",
}

export default ReadMoreBox
