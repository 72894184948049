import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../components/image-hotspots-modal-template"
import Paragraph from "../../../components/paragraph"
import Box from "../../../components/box"
import Caption from "../../../components/caption"
import Stack from "../../../components/stack"
import Poem from "../../../components/poem"
import Grid from "../../../components/grid"

const PatmosHotspots = () => {
  const data = useStaticQuery(graphql`
    query {
      manuskript01: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-01.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript02: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-02.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript03: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-03.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript04: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-04.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript05: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-05.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript06: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-06.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript07: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-07.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript08: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-08.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript09: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-09.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      manuskript10: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/05/manuskript-patmos-10.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos01: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-01.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos02: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-02.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos03: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-03.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos04: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-04.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos05: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-05.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos0201: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-0201.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos0301: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-0301.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
      patmos0501: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/patmos-0501.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 680, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Grid columns={[1, 2, 3]} alignX="center" space={2}>
        <ImageHotspots
          image={
            <Image
              image={data.manuskript01.childImageSharp.gatsbyImageData}
              alt="Gedichthandschrift ›patmos‹ Seite 1"
            />
          }
        >
          <ImageHotspot x={65} y={8} name="patmos-01" label="Nummerierungen">
            <ImageHotspotsModalTemplate
              title="Nummerierungen"
              image={
                <Image
                  image={data.patmos01.childImageSharp.gatsbyImageData}
                  alt="Zwei Ziffern in einem mit brauner Tinte gezeichnetem Rechteck. Darunter ein handschriftlicher Vermerk mit derselben Tinte."
                />
              }
            >
              <Paragraph>
                Die Ziffern 28/67 referieren auf die Nummer und Seitenzahl der
                Gedichthandschrift in der Zusammenstellung des Preußischen
                Leutnants Diest. Hölderlins Halbbruder Karl Gock hat sie im Zuge
                der Vorbereitungen auf die von Schwab und Uhland herausgegebene
                Gedichtausgabe auf dem Manuskript notiert und auch den
                handschriftlichen Hinweis auf die Erstveröffentlichung des
                Gedichts in »Sekendorfs MusenAllmanach« für das Jahr 1808 dort
                angebracht. Schwab und Uhland werden ›Patmos.‹ jedoch nicht in
                die Ausgabe mit aufnehmen.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={40} y={7} name="patmos-02" label="An erster Stelle">
            <ImageHotspotsModalTemplate
              title="An erster Stelle"
              image={
                <Image
                  image={data.patmos02.childImageSharp.gatsbyImageData}
                  alt="Detail einer unterstrichenen Bleistiftnotiz: »N(ummer) 1«."
                />
              }
            >
              <Paragraph>
                Bei der Sichtung von Hölderlins Manuskripten für die erste, von
                Gustav Schwab und Ludwig Uhland herausgegebene Sammlung von
                Hölderlins Gedichten hat dessen Halbbruder Karl Gock eine Liste
                angelegt. Das Gedicht ›Patmos.‹ steht darin an erster Stelle.
                Diese Nummerierung überträgt Karl Gock auch in das Manuskript.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
          <ImageHotspot
            x={35}
            y={15}
            name="patmos-03"
            label="Dem Landgrafen von Homburg."
          >
            <ImageHotspotsModalTemplate
              title="Dem Landgrafen von Homburg."
              image={
                <Image
                  image={data.patmos03.childImageSharp.gatsbyImageData}
                  alt="Detail der Widmungszeile: Mit sauberer Handschrift hat Hölderlin das Gedicht »Dem Landgrafen von Homburg« gewidmet."
                />
              }
            >
              <Paragraph>
                Die Widmung für den Landgrafen von Homburg behält Hölderlin auch
                bei der Umarbeitung des Gedichtes bei. Der
                Entstehungshintergrund bleibt damit nach wie vor Bestandteil des
                Gedichts.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
          <ImageHotspot
            x={20}
            y={20}
            name="patmos-04"
            label="Korrektur der Anfangsverse"
          >
            <ImageHotspotsModalTemplate
              title="Korrektur der Anfangsverse"
              image={
                <Image
                  image={data.patmos04.childImageSharp.gatsbyImageData}
                  alt="Detail der Anfangsverse: Die ersten beiden Zeilen sind mit gekringelten Linien in dunkler Tinte durchstrichen. Darüber hat Hölderlin mit derselben Feder und Tinte seine Korrekturen eingefügt."
                />
              }
            >
              <Paragraph>
                In einer gekringelten Linie durchstreicht Hölderlin die ersten
                beiden Verse des Gedichts bei der Überarbeitung mit dunkler
                Tinte und entwirft darüber eine neue Variante:
              </Paragraph>

              <Box
                bg="muted"
                p={6}
                sx={{
                  "del, ins": {
                    display: "block",
                    position: "relative",
                  },
                  "del > p, ins > p": {
                    mx: "1.8em",
                  },
                  ins: {
                    textDecoration: "none",
                  },
                  "del::before, ins::before": {
                    position: "absolute",
                    left: ".5rem",
                    fontFamily: "monospace",
                  },
                  "del::before": {
                    content: `"−"`,
                    color: "negative",
                  },
                  "ins::before": {
                    content: `"+"`,
                    color: "positive",
                  },
                }}
              >
                <Stack space={6}>
                  <Stack space={1}>
                    <Paragraph as="del">
                      <p>Nah ist</p>
                    </Paragraph>
                    <Paragraph as="del">
                      <p>Und schwer zu fassen der Gott.</p>
                    </Paragraph>
                  </Stack>
                  <Stack space={1}>
                    <Paragraph as="ins">
                      <p>Voll Güt’ ist. Keiner aber fasset</p>
                    </Paragraph>
                    <Paragraph as="ins">
                      <p>Allein Gott.</p>
                    </Paragraph>
                  </Stack>
                </Stack>
              </Box>

              <Paragraph>
                Damit kippt nicht nur die metrische Komposition der
                Eingangsverse. Auch die Aussage ändert sich entscheidend: Ist
                Gott in der ersten Variante gerade weil er den Menschen so nah
                ist, nur schwer zu fassen, so wandelt sich die Relation zwischen
                Gott und Mensch mit der späteren Variante: Nicht seine Nähe
                zeichnet Gott aus, sondern seine Güte. Und: Nur allein ist er
                schwer zu fassen.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
          <ImageHotspot
            x={65}
            y={75}
            name="patmos-05"
            label="Personifizierte Landschaften"
          >
            <ImageHotspotsModalTemplate
              title="Personifizierte Landschaften"
              image={
                <Image
                  image={data.patmos05.childImageSharp.gatsbyImageData}
                  alt="Detail der Gedichthandschrift: Über dem mit hellerer Tinte und feinerer Feder notierten Gedichttext der 1. Fassung fügt Hölderlin mit dunklerer Tinte und breiterer Feder eine Korrektur ein."
                />
              }
            >
              <Paragraph>
                Wird die Landschaft, durch die der Dichter zur Insel Pathmos
                aufbricht, in der zugrundliegenden Reinschrift der 1. Fassung
                noch wie folgt beschrieben,
              </Paragraph>
              <Poem>
                <span>»... Es dämmerten </span>
                <span>Im Zwielicht, da ich gieng</span>
                <span>Der schattige Wald</span>
                <span>Und die sehnsüchtigen Bäche</span>
                <span>Der Heimath«,</span>
              </Poem>

              <Paragraph>
                so wird sie in der Weiterentwicklung des Gedichts mehr und mehr
                vermenschlicht:
              </Paragraph>
              <Poem>
                <span>»... Es kleideten sich </span>
                <span>Im Zwielicht Menschen ähnlich, da ich gieng</span>
                <span>Der schattige Wald</span>
                <span>Und die sehnsüchtigen Bäche</span>
                <span>Der Heimath«.</span>
              </Poem>
              <Paragraph>
                Sie werden zu Gefährten, die ihn ein Stück seines Weges
                begleiten.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
        </ImageHotspots>

        <ImageHotspots
          image={
            <Image
              image={data.manuskript02.childImageSharp.gatsbyImageData}
              alt="Gedichthandschrift ›patmos‹ Seite 2"
            />
          }
        >
          <ImageHotspot
            x={40}
            y={57}
            name="patmos-0201"
            label="Naturbeschreibungen"
          >
            <ImageHotspotsModalTemplate
              title="Naturbeschreibungen"
              image={
                <Image
                  image={data.patmos0201.childImageSharp.gatsbyImageData}
                  alt="Detail der Handschrift: Über einer mit feiner Feder geschriebenen Zeile sind mit zwei verschiedenen Tinten Alternativen notiert."
                />
              }
            >
              <Paragraph>
                Schon in Hölderlins Gedicht ›Der Nekar‹ sehnt sich das lyrische
                Ich zum Fluss Paktolos (Sart Çayı) an der türkischen Küste, der
                dort als »golden« beschrieben wird, da er in der Antike nach
                Herodots Überlieferungen Goldstaub mit sich führte. In der
                Landschaft, die das lyrische Ich in ›Patmos.‹ in Richtung Asien
                durchquert, taucht der »goldgeschmükte Pactol« erneut wieder
                auf.
              </Paragraph>
              <Paragraph>
                Die dabei erblickte Natur erscheint vermenschlicht und belebt:
                Über einer Beschreibung eines Gartens, »voll von Blumen«,
                ergänzt Hölderlin zunächst mit heller Tinte das Wort »schläfrig«
                und fügt in einem späteren Überarbeitungsschritt mit dunkler
                Tinte noch das Wort »fast« hinzu.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
        <ImageHotspots
          image={
            <Image
              image={data.manuskript03.childImageSharp.gatsbyImageData}
              alt="Gedichthandschrift ›patmos‹ Seite 3"
            />
          }
        >
          <ImageHotspot
            x={65}
            y={18}
            name="patmos-0301"
            label="»O Insel des Lichts!«"
          >
            <ImageHotspotsModalTemplate
              title="»O Insel des Lichts!«"
              image={
                <Image
                  image={data.patmos0301.childImageSharp.gatsbyImageData}
                  alt="Eine handschriftliche Ergänzung im Manuskript"
                />
              }
            >
              <Paragraph>
                Schon auf Höhe der 4. Strophe notiert Hölderlin bei der
                Überarbeitung den Ausruf »O Insel des Lichts!«, bricht jedoch in
                der darauffolgenden Zeile wieder ab, um daraus eine Strophe
                später eine ganze Passage zu entwickeln, die er allerdings
                wieder verwirft.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
        <Image
          image={data.manuskript04.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 4"
        />
        <ImageHotspots
          image={
            <Image
              image={data.manuskript05.childImageSharp.gatsbyImageData}
              alt="Gedichthandschrift ›patmos‹ Seite 5"
            />
          }
        >
          <ImageHotspot x={0} y={25} name="patmos-0301" label="Herausgetrennt?">
            <ImageHotspotsModalTemplate
              title="Herausgetrennt?"
              image={
                <Image
                  image={data.patmos0501.childImageSharp.gatsbyImageData}
                  alt="Nahaufnahme von der Falz der Manuskriptbögen, in der mehrere Löcher zu erkennen sind."
                />
              }
            >
              <Paragraph>
                Die Heftungslöcher in der Falz des Manuskripts könnten laut
                Gunter Martens ein Hinweis darauf sein, dass die Blätter
                ursprünglich Teil des sogenannten Homburger Foliohefts waren und
                später herausgetrennt wurden.
              </Paragraph>
            </ImageHotspotsModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
        <Image
          image={data.manuskript06.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 6"
        />
        <Image
          image={data.manuskript07.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 7"
        />
        <Image
          image={data.manuskript08.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 8"
        />
        <Image
          image={data.manuskript09.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 9"
        />
        <Image
          image={data.manuskript10.childImageSharp.gatsbyImageData}
          alt="Gedichthandschrift ›patmos‹ Seite 10"
        />
      </Grid>
      <Caption>
        Gedichtmanuskript ›Patmos.‹ aus der Mappe G,2-7 der Bad Homburger
        Hölderlin-Bestände in der Württembergischen Landesbibliothek Stuttgart,
        in dem Hölderlin die 2. Fassung des Gedichts entworfen hat.
      </Caption>
    </Stack>
  )
}

export default PatmosHotspots
