import AudioPlayer from "../../../../src/components/audio-player";
import Poem from "../../../../src/components/poem";
import Grid from "../../../../src/components/grid";
import Small from "../../../../src/components/small";
import QuoteBox from "../../../../src/components/quote-box";
import VideoKommerell from "../../../../src/content/turmgespraeche/05-blanche-kommerell/video-kommerell";
import * as React from 'react';
export default {
  AudioPlayer,
  Poem,
  Grid,
  Small,
  QuoteBox,
  VideoKommerell,
  React
};