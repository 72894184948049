import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Accent from "../../../components/accent"
import AudioPlayer from "../../../components/audio-player"
import Blockquote from "../../../components/blockquote"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import PoemMetric from "../../../components/poem-metric"
import QuotePrimary from "../../../components/quote-primary"
import ReadMore from "../../../components/read-more"
import Stack from "../../../components/stack"
import Text from "../../../components/text"

const EinfuehrungLinien = (props) => {
  const data = useStaticQuery(graphql`
    query RaumIntroLinienDesLebensQuery {
      cover: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/cover.jpg"
        }
      ) {
        ...largeImage
      }
      berichtZimmer: file(
        relativePath: {
          eq: "dauerausstellung/01-die-linien-des-lebens/bericht-zimmer.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Im Erdgeschoss des Hauses hatte der Schreinermeister Ernst Zimmer, der
        Hölderlin bei sich aufnahm, seine Werkstatt. Hier entstand eines von
        Hölderlins bekanntesten Turmgedichten: Die Linien des Lebens...
      </Paragraph>

      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Bild des ersten Raumes der Dauerausstellung: Das Gedicht ›Die Linien des Lebens…‹ auf Holzschindeln und eine graue Vitrine mit Dokumenten"
        attribution="Gudrun de Maddalena"
      />

      <Constrain>
        <Stack>
          <Paragraph as="div">
            In{" "}
            <ReadMore
              content={
                <Columns space={12} collapse={[true, true, false]}>
                  <Column width={["full", "60%", "40%"]}>
                    <Image
                      image={data.berichtZimmer.childImageSharp.gatsbyImageData}
                      alt="Ein handschriftlicher Bericht von Ernst Zimmer an Hölderlins Mutter"
                    />
                  </Column>
                  <Column>
                    <Stack>
                      <Heading as="h1" level={3}>
                        Brief von Ernst Zimmer an Hölderlins Mutter am 19. April
                        1812
                      </Heading>
                      <Paragraph>
                        Ich habe den Herrn Professor Gmelin, als Arzt zu Ihrem
                        Lieben Sohn hohlen laßen, dieser sagte mann könne über
                        Ihres Sohnes würklichen Zustandt noch nichts bestimtes
                        sagen es scheint Ihm aber ein Nachlaß der Natur zu seyn,
                        und Leider gute Frau bin ich in die traurige
                        Nothwindigkeit versetz es Ihnen zu schreiben das ich es
                        selbst glaube. Ihre schönne Hoffnung, den lieben Sohn
                        noch disseits glüklich zu sehen würde den freylich
                        leider ach leider verschwünden, doch komme es wie es
                        komme so wird Er gewißt, doch jenseits beklükt werden.
                        In 8 biß 14 Tage kann ich Ihnen vielleicht bestimtere
                        Nachricht geben.
                      </Paragraph>
                      <Paragraph>
                        Sein dichterischer Geist zeigt Sich noch immer thätig,
                        so sah Er bey mir eine Zeichnung von einem Tempel Er
                        sagte mir ich sollte einen von Holz so machen, ich
                        versetze Ihm drauf daß ich um Brod arbeiten müßte, ich
                        sey nicht so glüklich so in Philosofischer ruhe zu leben
                        wie Er, gleich versetze Er, Ach ich bin doch ein armer
                        Mensch, und in der nehmlichen Minute schrieb Er mir
                        folgenden Vers mit Bleistift auf ein Brett
                      </Paragraph>
                      <QuotePrimary author="Friedrich Hölderlin: Die Linien des Lebens">
                        <span>Die Linien des Lebens sind Verschieden</span>
                        <span>Wie Wege sind, und wie der Berge Gränzen.</span>
                        <span>
                          Was Hir wir sind, kan dort ein Gott ergänzen
                        </span>
                        <span>Mit Harmonien und ewigem Lohn und Frieden.</span>
                      </QuotePrimary>
                    </Stack>
                  </Column>
                </Columns>
              }
            >
              einem der vielen Berichte
            </ReadMore>
            , die Ernst Zimmer regelmäßig an Hölderlins Mutter nach Nürtingen
            schickte, erzählt er von einer besonderen Begebenheit: Ihr Sohn, der
            nun schon fünf Jahre bei ihm lebte, habe hier in seiner Werkstatt
            die Zeichnung eines griechischen Tempels gesehen und gebeten, »ich
            solte einen von Holz so machen«:
          </Paragraph>

          <Paragraph>
            <Blockquote>
              »Ich versetze Ihm drauf daß ich um Brod arbeiten müßte, ich sey
              nicht so glüklich so in Philosofischer ruhe zu leben wie Er«.
            </Blockquote>
          </Paragraph>

          <Paragraph>
            Daraufhin habe Hölderlin, für dessen zerrütteten Zustand auch das
            Dichten verantwortlich gemacht und dem sogar Papierentzug verordnet
            worden war, auf eines seiner Holzbretter mit einem Bleistift vier
            Verse geschrieben. Das Gedicht ›Die Linien des Lebens…‹ wurde später
            zu einem seiner meistzitierten.
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain align="right">
        <PoemMetric
          title="Die Linien des Lebens"
          player={
            <AudioPlayer
              src={`dauerausstellung/01-die-linien-des-lebens/linien-des-lebens`}
              title={
                <>
                  Christian Reiner liest{" "}
                  <Text bold as="span">
                    ›Die Linien des Lebens‹
                  </Text>
                </>
              }
            />
          }
        >
          <span>
            Die <Accent>Li</Accent>ni<Accent>en</Accent> des <Accent>Le</Accent>
            bens <Accent>sind</Accent> Ver<Accent>schie</Accent>den
          </span>
          <span>
            Wie <Accent>We</Accent>ge <Accent>sind</Accent>, und{" "}
            <Accent>wie</Accent> der <Accent>Ber</Accent>ge{" "}
            <Accent>Grän</Accent>zen.
          </span>
          <span>
            Was <Accent>Hir</Accent> wir <Accent>sind</Accent>, kan{" "}
            <Accent>dort</Accent> ein <Accent>Gott</Accent> er
            <Accent>gän</Accent>zen
          </span>
          <span>
            Mit <Accent>Har</Accent>mo<Accent>nien</Accent> und{" "}
            <Accent>e</Accent>wigem <Accent>Lohn</Accent> und{" "}
            <Accent>Frie</Accent>den.
          </span>
        </PoemMetric>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungLinien
