import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"

const Besetzt = () => {
  const data = useStaticQuery(graphql`
    query Besetzt {
      ruedigerGoerner: file(
        relativePath: { eq: "veranstaltungen/goerner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 350, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Der flämische Dichter Paul Van Ostaijen (1896–1928) lebte nach dem Ersten Weltkrieg in Berlin, 
        wo sein Gedichtband „Bezette Stad“ (1921) entstand, der die Krisen seiner Zeit thematisiert. 
        Der Hölderlinturm lädt am 30.11.2024 herzlich zu einem Abend mit aktuellen Perspektiven auf sein Werk ein. 
        Matthijs de Ridder, seines Zeichens Paul van Ostaijen-Biograph, wird die Besucher*innen durch den historischen 
        und künstlerischen Kontext führen. Er und die Übersetzerin Anna Eble erwecken die Poesie van Ostaijens 
        in einer schillernden zweisprachigen Lesung zum Leben und nehmen die uns mit in die Welt des Projekts „Befallene Stadt“, 
        das aktuelle künstlerische Reaktionen auf das Werk van Ostaijens hervorgebracht hat.
      </Paragraph>
      <Paragraph>
        Höhepunkt des Abends sind die Performances von Franziska Füchsl und der niederländischen Dichterin Anneke Brassinga, 
        die künstlerische Antworten auf van Ostaijens Gedicht „Der Rückzug“ vortragen werden. 
        Wie van Ostaijen haben sie sich auf die Suche nach einer neuen Sprache für eine neue Welt begeben 
        und teilen ihre Perspektiven auf die Krisen unserer Zeit mit dem Publikum. Franziska Füchsl stellt 
        ihren dichten Prosatext „Kako se kaže Geschützabwerferin“ vor. Anneke Brassinga liest ihr Gedicht „In der Ferne“, 
        aus dessen deutscher Übersetzung von Ira Wilhelm auch das Titelzitat der Veranstaltung stammt.
        Der Abend wird in Kooperation mit deBuren, dem Studio Literatur und Theater der Universität Tübingen 
        und der Paul van Ostaijen-Gesellschaft veranstaltet.
      </Paragraph>
    </Stack>
  )
}

export default Besetzt