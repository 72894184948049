import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import Image from "../../../components/image"

const StammbuchPolit = (props) => {
  const data = useStaticQuery(graphql`
    query StammbuchPolitQuery {
      stammbuch1: file(
        relativePath: { eq: "aus-dem-archiv-geholt/02/stammbuch-1.png" }
      ) {
        ...largeImage
      }
      stammbuch2: file(
        relativePath: { eq: "aus-dem-archiv-geholt/02/stammbuch-2.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Grid columns={[1, 2]} alignX="center">
      <Image
        image={data.stammbuch1.childImageSharp.gatsbyImageData}
        alt="Handschriftlicher Eintrag aus Hegels Stammbuch von Friedrich Harpprecht"
        caption="Abb.1: Friedrich Harpprechts Eintrag, am »letzten Tag [seines] Klosterlebens«"
      />
      <Image
        image={data.stammbuch2.childImageSharp.gatsbyImageData}
        alt="Handschriftlicher Eintrag aus Hegels Stammbuch von Gotthold Friedrich Stäudlin"
        caption="Abb.2: Gotthold Friedrich Stäudlin zitiert »In tyrannos«"
      />
    </Grid>
  )
}

export default StammbuchPolit
