import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Schreibwerkstatt = () => {
  const data = useStaticQuery(graphql`
    query Schreibwerkstatt {
      schreibwerkstatt: file(
        relativePath: { eq: "veranstaltungen/postkarte_feuerteufel.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 760, height: 525, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Gedichte in Leichter Sprache, kann das funktionieren? Was passiert mit Gedichten, wenn wir sie in Leichte Sprache übertragen? 
        Was passiert, wenn wir mit den Regeln für Leichte Sprache versuchen, selbst Gedichte zu schreiben? 
        Die Antworten auf diese Fragen finden Sie in dieser Lesung. Die Teilnehmenden eines Workshops für Lyrik in Leichter Sprache lesen 
        ihre frisch entstandenen Werke vor. Danach besteht die Möglichkeit des gemeinsamen Austauschs. Kommen Sie vorbei, wir freuen uns auf Sie!
      </Paragraph>
      <Paragraph>
        Bei einer Lesung werden Gedichte vorgelesen. 
        Die Gedichte sind in Leichter Sprache. 
        Vor der Lesung gibt es einen Kurs. 
        Im Kurs lernen die Teilnehmenden, 
        wie sie Gedichte in Leichter Sprache schreiben können. 
        Bei der Lesung lesen die Teilnehmenden ihre Gedichte vor. 
        Kommen Sie vorbei, wir freuen uns auf Sie!
      </Paragraph>
      <Paragraph>
        Eine Veranstaltung im Rahmen <strong>Literatur für Alle? Literatur mit Allen! Das Inklusive Literaturfestival.</strong>
      </Paragraph>
    </Stack>
  )
}

export default Schreibwerkstatt