import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Accent from "../../../components/accent"
import AudioPlayer from "../../../components/audio-player"
import Blockquote from "../../../components/blockquote"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import NoBreak from "../../../components/no-break"
import PoemMetric from "../../../components/poem-metric"
import ReadMore from "../../../components/read-more"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import VideoPlayer from "../../../components/video-player"
import AussichtManuskript from "./aussicht-manuskript"

const Aussicht = (props) => {
  const data = useStaticQuery(graphql`
    query TurmzimmerAussichtQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht.png"
        }
      ) {
        ...largeImage
      }
      aussicht01: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-01.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht02: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-02.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht03: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-03.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht04: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-04.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht05: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-05.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht06: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-06.jpg"
        }
      ) {
        ...largeImage
      }
      poster: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/poster-gebaerdensprache.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h1" level={3}>
              {title}
            </Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins ›Aussicht.‹
          </Heading>
          <Paragraph>
            Insgesamt 3 Gedichte mit dem Titel ›Aussicht‹ bzw. ›Die Aussicht‹
            sind aus Hölderlins Zeit im Turm überliefert. Das früheste entstand
            um 1829/30, das letzte Anfang Juni 1843, wenige Tage vor seinem Tod.
          </Paragraph>
          <Paragraph>
            Ausgehend von dem, was Hölderlin aus den Fenstern seines Turmzimmers
            unmittelbar vor Augen stand, geht es darin doch um Allgemeineres,
            Übertragbares: die Zeit und ihr Vorübergehen, die Wiederkehr und den
            Wandel, den Menschen und die Natur.
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain>
        <AussichtManuskript />
      </Constrain>
      <Constrain>
        <Stack>
          <Paragraph>
            <ReadMore
              content={
                <Constrain>
                  <Stack>
                    <Paragraph>
                      Christoph Theodor Schwab, der Hölderlin Anfang 1841
                      häufiger im Turm besuchen kam, setzte das Werk seines
                      Vaters Gustav Schwab fort, indem er Hölderlins Werke 1846
                      und 1874 veröffentlichte und um eine Biografie ergänzte.
                    </Paragraph>
                    <Paragraph>
                      Schwabs Mutter schildert dazu im Januar 1841:
                    </Paragraph>
                    <Blockquote as="blockquote">
                      <Paragraph as="span">
                        »Unser Christoph hat gegenwärtig eine Liebhaberei in
                        Tübingen, für welche ich mich auch interessire, er hat
                        mit Hölderlin Freundschaft geschloßen und dieser scheint
                        wirklich auch an ihm Interesse zu nehmen, wenigstens hat
                        C.(hristoph) ihn weiter gebracht als es Andern gelungen
                        ist. H.(ölderlin) hat C.(hristoph) auf seine
                        Aufforderung schon einige Gedichte gemacht, mein
                        l.(ieber) Mann hat sie gelesen u.(nd) sagt, Hölderlins
                        ganzes Genie zeige sich noch darinn...«
                      </Paragraph>
                    </Blockquote>
                  </Stack>
                </Constrain>
              }
            >
              Christoph Theodor Schwab
            </ReadMore>{" "}
            schrieb über Hölderlins Begeisterung für die Natur:
          </Paragraph>
          <Paragraph>
            <Blockquote>
              »Die Natur übt noch jetzt einen großen Einfluß auf{" "}
              <NoBreak>Hölderlins</NoBreak> Befinden aus; ein schöner heitrer
              Tag bringt ihn in eine fröhliche, sanfte Stimmung, in welcher die
              Selbstgespräche seltner werden und seine Neigung zum Widersprechen
              u.(nd) Verneinen sich vermindert. Eine schöne Mondnacht lockt ihn
              oft an's Fenster und er sieht dann manchmal die halbe Nacht lang
              in's Freie hinaus.«
            </Blockquote>
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain align="right">
        <Stack>
          <PoemMetric
            title="Aussicht."
            size={[3, 3, 4]}
            player={
              <AudioPlayer
                src={`dauerausstellung/06-hoelderlins-turmzimmer/aussicht`}
                title={
                  <>
                    Christian Reiner liest{" "}
                    <Text bold as="span">
                      ›Aussicht.‹
                    </Text>
                  </>
                }
              />
            }
          >
            <span>
              Der <Accent>off</Accent>’ne <Accent>Tag</Accent> ist{" "}
              <Accent>Men</Accent>schen <Accent>hell</Accent> mit{" "}
              <Accent>Bil</Accent>dern,
            </span>
            <span>
              Wenn <Accent>sich</Accent> das <Accent>Grün</Accent> aus{" "}
              <Accent>eb</Accent>ner <Accent>Fer</Accent>ne <Accent>zei</Accent>
              get,
            </span>
            <span>
              Noch <Accent>eh</Accent>’ des <Accent>A</Accent>bends{" "}
              <Accent>Licht</Accent> zur <Accent>Däm</Accent>mer
              <Accent>ung</Accent> sich <Accent>nei</Accent>get,
            </span>
            <span>
              Und <Accent>Schim</Accent>mer <Accent>sanft</Accent> den{" "}
              <Accent>Glanz</Accent> des <Accent>Ta</Accent>ges{" "}
              <Accent>mil</Accent>dern.
            </span>
            <span>
              Oft <Accent>scheint</Accent> die <Accent>In</Accent>ner
              <Accent>heit</Accent> der <Accent>Welt</Accent> um
              <Accent>wölkt</Accent>, ver<Accent>schlos</Accent>sen,
            </span>
            <span>
              Des <Accent>Men</Accent>schen <Accent>Sinn</Accent> von{" "}
              <Accent>Zwei</Accent>feln <Accent>voll</Accent>, ver
              <Accent>dros</Accent>sen,
            </span>
            <span>
              Die <Accent>präch</Accent>ti<Accent>ge</Accent> Na
              <Accent>tur</Accent> er<Accent>hei</Accent>tert{" "}
              <Accent>sei</Accent>
              ne <Accent>Ta</Accent>ge,
            </span>
            <span>
              Und <Accent>fer</Accent>ne <Accent>steht</Accent> des{" "}
              <Accent>Zwei</Accent>fels <Accent>dunk</Accent>le{" "}
              <Accent>Fra</Accent>ge.
            </span>
            <span>
              <br />
            </span>
            <Columns>
              <Column textAlign="center">Mit Unterthänigkeit</Column>
            </Columns>
            <Columns>
              <Column>
                d. 24ten März <br />
                1871.
              </Column>
              <Column></Column>
              <Column>Scardanelli.</Column>
            </Columns>
          </PoemMetric>
        </Stack>
      </Constrain>
      <Constrain>
        <Stack>
          <Heading as="h3" level={4}>
            ›Aussicht.‹ in Gebärdensprache
          </Heading>
          <Paragraph>
            Friedrich Hölderlins Gedichte wurden vielfach vertont und häufig
            rezitiert. Doch wie sieht es eigentlich aus, wenn man sie in
            Gebärdensprache übersetzt? Welche performative Präsenz erhalten sie
            dabei? Welche neuen Zugänge erschließen sich dadurch? Diese Fragen
            standen am Ausgangspunkt eines{" "}
            <ReadMore
              content={
                <ModalTemplate title="Das Fenster zur Seele öffnen. Eine Rückschau auf den Workshop ›Hölderlin in Gebärdensprache‹">
                  <Heading level={5}>Von Krishna-Sara Helmle</Heading>
                  <Paragraph>
                    Wie sieht ein Gedicht von Friedrich Hölderlin in
                    Gebärdensprache aus? Wie geht man bei der Übersetzung vor?
                    Für dieses Vorhaben kam eine gemischte Gruppe aus vierzehn
                    hörenden und gehörlosen Teilnehmenden unterschiedlichen
                    Alters im Garten des Hölderlinturms zusammen. Für die
                    Verständigung sorgte ein Dolmetsch-Tandem, bestehend aus
                    einer hörenden und einer gehörlosen Dolmetscherin.
                  </Paragraph>
                  <Paragraph>
                    Bereits bei einer kleinen Einführung in die Gebärdensprache
                    zeigten sich die Teilnehmenden sehr interessiert. »Gibt es
                    Vorlagen von bekannten Gedichten oder feste Regeln dafür,
                    wie beispielsweise ein Gedicht von Goethe in Gebärdensprache
                    aussehen soll?«, erkundigte sich eine Teilnehmerin. Daniela
                    Happ, die gehörlose Dolmetscherin, verneinte das. Die
                    Übersetzung von Gedichten sei sehr individuell. Ein
                    ebenfalls gehörloser Teilnehmer ergänzte, dass
                    Gebärdensprache lange verboten gewesen sei, was den Zugang
                    zu literarischer Bildung erschwert habe.
                  </Paragraph>
                  <Paragraph>
                    Nach einem Rundgang durch den Turm und einem Austausch über
                    die Ausstellung, wandte sich die Gruppe dem Gedicht
                    ›Aussicht‹ von Friedrich Hölderlin zu. Daniela Happ führte
                    durch diesen Teil des Workshops. Sie zeigte die eine oder
                    andere Gebärde, ließ den Teilnehmenden aber viel Raum für
                    eigene Interpretationen. Zunächst vorsichtig, doch dann
                    immer mutiger erprobte jede und jeder einen persönlichen
                    Zugang zu diesem Gedicht. Allmählich formte sich ein Konsens
                    darüber, wie sich der Inhalt in Gebärdensprache
                    transportieren ließe.
                  </Paragraph>
                  <Paragraph>
                    Beim Titel entschied sich die Gruppe für ein gebärdetes
                    Aufstoßen eines imaginären Fensterladens. Das war einerseits
                    wörtlich gemeint, weil sich die Teilnehmenden vorstellten,
                    wie Hölderlin den Fensterladen in seinem Turmzimmer öffnet
                    und hinausschaut in die Welt. Andererseits kann man die
                    Gebärde auch im übertragenen Sinn verstehen, nämlich als ein
                    Öffnen der Seele, des tiefsten Inneren.
                  </Paragraph>
                  <Paragraph>
                    Im Ergebnis sind sowohl Elemente des Ursprungstexts als auch
                    Interpretationen der Teilnehmenden enthalten. Die
                    Sprachmelodie drückt sich dabei in der Mimik, der
                    Körpersprache und dem Rhythmus der Gebärden aus. Die
                    Teilnehmenden waren sich einig: Solche Veranstaltungen
                    sollte es im Kulturbetrieb öfter geben.
                  </Paragraph>
                </ModalTemplate>
              }
            >
              inklusiven Workshops
            </ReadMore>{" "}
            zu Hölderlin in Gebärdensprache.
          </Paragraph>
          <VideoPlayer
            src="dauerausstellung/aussicht-in-gebaerdensprache"
            poster={data.poster}
          />
          <Paragraph>
            Vielen Dank an die Gebärdendolmetscherinnen Daniela Happ und Anne
            Nagel sowie alle Teilnehmerinnen und Teilnehmer, die das Gedicht
            gemeinsam in Gebärdensprache übertragen haben.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Aussicht
