import EinfuehrungWirkung from "../../../../src/content/dauerausstellung/02-hoelderlins-wirkung/einfuehrung-wirkung";
import Celan from "../../../../src/content/dauerausstellung/02-hoelderlins-wirkung/celan";
import JoachimLehrer from "../../../../src/content/dauerausstellung/02-hoelderlins-wirkung/joachim-lehrer";
import DamalsHeute from "../../../../src/content/dauerausstellung/02-hoelderlins-wirkung/damals-heute";
import * as React from 'react';
export default {
  EinfuehrungWirkung,
  Celan,
  JoachimLehrer,
  DamalsHeute,
  React
};