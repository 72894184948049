/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useThemeUI } from "theme-ui"
import Text from "./text"

const TextLink = (props) => {
  const { children, to, href, onClick, sx, ...restProps } = props
  const context = useThemeUI()
  const { theme } = context

  const linkStyles = {
    ...sx,
    textAlign: "left",
    textDecoration: "underline",
    color: "text",
    textDecorationColor: theme.colors.primary,
    ":hover, :focus": {
      textDecoration: "none",
    },
  }

  return to ? (
    <Link to={to} sx={linkStyles} {...restProps}>
      {children}
    </Link>
  ) : href ? (
    <Text as="a" href={href} sx={linkStyles} {...restProps}>
      {children}
    </Text>
  ) : onClick ? (
    <Text
      bg="transparent"
      onClick={onClick}
      as="button"
      sx={linkStyles}
      {...restProps}
    >
      {children}
    </Text>
  ) : (
    <Text bg="transparent" as="span" sx={linkStyles} {...restProps}>
      {children}
    </Text>
  )
}

TextLink.propTypes = {
  variation: PropTypes.string,
  size: PropTypes.number,
}

TextLink.defaultProps = {
  variation: "primary",
}

export default TextLink
