import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Roesch = () => {
  const data = useStaticQuery(graphql`
    query Roesch {
        liland: file(
            relativePath: { eq: "veranstaltungen/liland-bawue.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 268, height: 300, layout: CONSTRAINED)
            }
        }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Heute suche ich wie morgen schon nach meinen Sprachen.<br />
        Jose F. A. Oliver, Fremdenzimmer
      </Paragraph>
      <Paragraph>
        Bisweilen kann ein einzelner Vers, eine einzige Zeile die Welt erklären. Schreiben heißt also, 
        mit der Welt im Dialog stehen. Wer im Dialog steht, der*die spricht, sagt und benennt. 
        Greift sich immer auch selbst, tritt in ein intuitives Gespräch mit inneren Anteilen und unbewussten Aspekten. 
        Das Papier eröffnet eine innere Bühne für vergessene Inhalte, neue Rollen und kühne Impulse. 
        Schreiben kann so schöpferische wie persönliche Entwicklungsprozesse auf spielerische Weise anregen und Wahrnehmungs-, 
        Erlebnis- wie Sprachfähigkeit fördern.
      </Paragraph>
      <Paragraph>
        Dieses Potenzial der schreibenden Zwischenwelt wollen wir nutzen, um uns bekannte Fragen und Gedankengänge neu zu fassen 
        und im Schreiben Unsägliches, Unsagbares, Ungesagtes sagbar zu machen - vom Wort in den Satz; vom Satz in die Verdichtung.
      </Paragraph>
      <Paragraph>
        Bitte mitbringen: Schreibmaterial, Sitzunterlage, Snack.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
          Die Zahl der Teilnehmer*innen ist begrenzt. Anmeldung erforderlich, bis 18.10. unter:{" "}
            <ExternalLink href="mailto:anmeldung@literatur-fuer-alle.de">
              <span style={{color: '#F7A61B'}}> Anmeldung </span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
    </Stack>
  )
}

export default Roesch