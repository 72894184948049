import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const RichardSchumm = (props) => {
  const data = useStaticQuery(graphql`
    query EleusisRichardSchummQuery {
      richard: file(
        relativePath: { eq: "aus-dem-archiv-geholt/01/richard-schumm.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Container maxWidth="lg">
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.richard.childImageSharp.gatsbyImageData}
            alt="Richard Schumm vor der Nachbildung eines Walfisches im Naturkundemuseum"
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Richard Schumm
            </Heading>
            <Text size={[2, 3]} color="white">
              Richard Schumm wurde 1985 in Tübingen geboren. Er studierte Neuere
              deutsche Literatur in Tübingen und am Trinity College in Dublin.
              Zuletzt war er wissenschaftlicher Mitarbeiter am Deutschen
              Literaturarchiv Marbach und hat sich unter anderem intensiv mit
              Hegel und der Sprache der Philosophie auseinandergesetzt.
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default RichardSchumm
