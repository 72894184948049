import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Magenau = () => {
  const data = useStaticQuery(graphql`
    query Magenau {
        liland: file(
            relativePath: { eq: "veranstaltungen/liland-bawue.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 268, height: 300, layout: CONSTRAINED)
            }
        }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Der junge Martin Walser kam 1948 nach Tübingen, um beim Hölderlin-Herausgeber Friedrich Beißner zu studieren - und dann, 
        bereits beim Süddeutschen Rundfunk festangestellt - bei ihm über Kafka zu promovieren. Der Walser-Biograph 
        und renommierte Literaturkritiker Jörg Magenau, der selbst in Tübingen lebt, 
        hat diese frühen Jahre Walsers anhand bislang unbekannter Dokumente in der neuen Ausgabe der Marbacher Reihe SPUREN mit dem Titel 
        „Martin Walser mit Kafka in Tübingen“ rekonstruiert. Im Kafka-Jahr 2024 präsentiert Magenau im Hölderlinturm die aktuelle Ausgabe - 
        gemeinsam mit dem SPUREN-Herausgeber Thomas Schmidt von der Arbeitsstelle für literarische Museen in Baden-Württemberg.
      </Paragraph>
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Anmeldung
        </Heading>
        <Paragraph>
            Aus Platzgründen ist eine Anmeldung erforderlich:{" "}
            <ExternalLink href="https://app.cituro.com/booking/stadtmuseumtuebingen">
              <span style={{color: '#F7A61B'}}> Anmeldung</span> 
            </ExternalLink>
        </Paragraph>
      </Stack>
      <Paragraph> 
        In Kooperation mit der{" "}
        <ExternalLink href="https://www.dla-marbach.de/ueber-uns/mitarbeiterinnen-und-mitarbeiter/arbeitsstelle-f-literarische-museen-archive-u-gedenkstaetten-in-bawue/">
          <span style={{color: '#F7A61B'}}> Arbeitsstelle für literarische Museen, Archive und Gedenkstätten in Baden-Württemberg </span> 
        </ExternalLink>
        (Deutsches Literaturarchiv Marbach).
      </Paragraph>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.liland.childImageSharp.gatsbyImageData}
          alt="Logo der Arbeitsstelle für literarische Museen, Archive und Gedenkstätten in Baden-Württemberg"
        />
      </Grid>
    </Stack>
  )
}

export default Magenau