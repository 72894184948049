import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const OlgaMartynova = () => {
  const data = useStaticQuery(graphql`
    query {
      martynova: file(
        relativePath: { eq: "aus-dem-archiv-geholt/05/olga-martynova.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Container maxWidth="lg">
      <Box bg="black">
        <Box pr={[12, 24]}>
          <Image
            image={data.martynova.childImageSharp.gatsbyImageData}
            attribution="Daniel Jurjew"
            alt="Porträt von Olga Martynova"
          />
        </Box>
        <Box p={[6, 12]}>
          <Stack space={6}>
            <Heading level={4} color="white">
              Olga Martynova
            </Heading>
            <Text size={[2, 3]} color="white">
              Olga Martynova, geb. 1962 in Sibirien, ist in Leningrad
              aufgewachsen, wo sie in den 1980ern die Dichter­gruppe ›Kamera
              Chranenia‹ mitbegründete. 1991 zog sie zusammen mit Oleg Jurjew
              (1959-2018) nach Deutschland. Zahlreiche Veröffentlichungen in
              russischsprachigen Periodika, vier Lyrikbände. Seit 1999 schreibt
              sie Buchbesprechungen und Essays für deutschsprachige Medien, seit
              Ende der 2000er Jahre Prosa in deutscher Sprache (erschienen sind
              drei Romane und ein Essayband), seit 2018 auch Gedichte auf
              Deutsch. Olga Martynova ist Mitglied des PEN und der Deutschen
              Akademie für Sprache und Dichtung. Texte von ihr wurden in viele
              Sprachen übersetzt. Sie erhielt u. a. den Ingeborg-Bachmann-Preis
              (2012) und den Berliner Literaturpreis (2015).
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default OlgaMartynova
