import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../../components/constrain"
import Image from "../../../components/image"

const Bueste = () => {
  const data = useStaticQuery(graphql`
    query VerliebtBueste {
      bueste: file(
        relativePath: { eq: "aus-dem-archiv-geholt/04/bueste.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Constrain>
      <Image
        image={data.bueste.childImageSharp.gatsbyImageData}
        attribution="Liebieghaus Skulpturensammlung, Frankfurt am Main ARTOTHEK"
        caption="Büste der Susette Gontard, 1795 (Landolin Ohmacht, 1760-1834)"
        alt="Eine weiß-gelbe Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
      />
    </Constrain>
  )
}

export default Bueste
