import React from "react"
import { useThemeUI } from "theme-ui"
import Box from "../../../components/box"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import Blockquote from "../../../components/blockquote"
import PieChart from "../../../components/pie-chart"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

import VectorScardanelli from "../../../images/dauerausstellung/05-haelfte-des-lebens/scardanelli.svg"

const Scardanelli = (props) => {
  const context = useThemeUI()
  const { theme } = context

  const dataSignature = [
    {
      id: "Mit Unterthänigkeit Scardanelli.",
      label: "Mit Unterthänigkeit Scardanelli.",
      value: 12,
      color: theme.colors.yellow[600],
    },
    {
      id: "Dero unterthänigster Scardanelli.",
      label: "Dero unterthänigster Scardanelli.",
      value: 1,
      color: theme.colors.grey[500],
    },
    {
      id: "Scardanelli.",
      label: "Scardanelli.",
      value: 2,
      color: theme.colors.grey[300],
    },
    {
      id: "Ohne Signatur",
      label: "Ohne Signatur",
      value: 7,
      color: theme.colors.yellow[700],
    },
  ]

  return (
    <Box bg="background">
      <Stack>
        <img
          src={VectorScardanelli}
          alt="Das Pesudonym Scardanelli in Hölderlins Handschrift"
          width="1000"
          height="215"
        />
        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Scardanelli
            </Heading>
            <Paragraph>
              Seine Turmgedichte unterzeichnete der Dichter nur noch selten mit
              dem Namen Hölderlin, sondern stattdessen »Mit Unterthänigkeit
              Scardanelli«. Gegenüber seinen Besuchern bestand er indessen
              energisch auf der Anrede »Herr Hofbibliothekar«.
            </Paragraph>
            <Paragraph>
              Von seinem Besuch in Hölderlins Turmzimmer schildert Johann Georg
              Fischer:
            </Paragraph>
            <Blockquote as="blockquote">
              <Paragraph as="span">
                »Beim Aufgang […] hörten wir vor der Thüre, wie Hölderlin auf
                seinem Tangenten-Klavier ziemlich leidenschaftlich phantasierte.
                Auf unser Anklopfen, das mit Herzklopfen geschah, lautete ein
                ziemlich heiseres, etwas hohles »Herein«. Unsere Verneigung
                erwiderte Hölderlin mit einer tiefen Verbeugung und
                entsprechender Handbewegung, die uns zu sitzen einlud. Er war
                bekleidet mit beblümtem Damastschlafrock und Pantoffeln. Uns
                vorzustellen war nicht nötig, denn er hatte den einen von uns
                mit ›Heiligkeit‹, den anderen mit ›Majestät‹ angeredet. Unsere
                Anrede an ihn mit Herr Doktor hatte er durch ›Bibliothekar‹
                korrigiert.«
              </Paragraph>
            </Blockquote>
          </Stack>
        </Constrain>
        <Stack>
          <Constrain>
            <Heading as="span" level={5}>
              Hölderlins Signatur der{" "}
              <Text as="span" wordBreak="break-all">
                Jahreszeitengedichte
              </Text>
            </Heading>
          </Constrain>
          <PieChart
            height={[72, 92, 100]}
            data={dataSignature}
            colors={dataSignature.map((data) => data["color"])}
            margin={{ top: 20, right: 30, bottom: 120, left: 30 }}
            slicesLabelsTextColor={{
              from: "color",
              modifiers: [["brighter", 7.0]],
            }}
            enableRadialLabels={false}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                translateY: 120,
                itemWidth: 220,
                itemHeight: 24,
                itemTextColor: theme.colors.text,
                symbolSize: 18,
                symbolShape: "circle",
              },
            ]}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Scardanelli
