import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Caption from "../../../components/caption"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import Stack from "../../../components/stack"

const StammbuchRegister = (props) => {
  const data = useStaticQuery(graphql`
    query StammbuchRegisterQuery {
      register1: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/02/stammbuch-register-1.png"
        }
      ) {
        ...largeImage
      }
      register2: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/02/stammbuch-register-2.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Grid columns={[1, 2]} alignX="center">
        <Image
          image={data.register1.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus Hegels Stammbuch"
        />
        <Image
          image={data.register2.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus Hegels Stammbuch"
        />
      </Grid>
      <Caption>
        Namensregister zu Hegels Stammbuch aus der Bibliothek der Eberhard Karls
        Universität Tübingen.
      </Caption>
    </Stack>
  )
}

export default StammbuchRegister
