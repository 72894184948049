const space = {
  px: "1px",
  0: "0",
  1: "0.25rem",
  2: "0.5rem",
  3: "0.75rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  8: "2rem",
  10: "2.5rem",
  12: "3rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  32: "8rem",
  40: "10rem",
  48: "12rem",
  56: "14rem",
  64: "16rem",
  72: "20rem",
  80: "24rem",
  92: "28rem",
  100: "32rem",
  128: "36rem",
  "2/12": "16.666667%", // used for backdrop padding
}

const maxWidth = {
  none: "none",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",
  "8xl": "90rem",
}

const columns = {
  "1/2": "50%",
  "1/3": "33.333333%",
  "2/3": "66.666667%",
  "1/4": "25%",
  "2/4": "50%",
  "3/4": "75%",
  "1/5": "20%",
  "2/5": "40%",
  "3/5": "60%",
  "4/5": "80%",
  "1/6": "16.666667%",
  "2/6": "33.333333%",
  "3/6": "50%",
  "4/6": "66.666667%",
  "5/6": "83.333333%",
  "1/12": "8.333333%",
  "2/12": "16.666667%",
  "3/12": "25%",
  "4/12": "33.333333%",
  "5/12": "41.666667%",
  "6/12": "50%",
  "7/12": "58.333333%",
  "8/12": "66.666667%",
  "9/12": "75%",
  "10/12": "83.333333%",
  "11/12": "91.666667%",
}

const sizes = {
  ...maxWidth,
  ...space,
  ...columns,
  auto: "auto",
  full: "100%",
  screen: "100vh",
}

const transitions = {
  fast: ".05s ease",
  main: ".2s ease",
}

const zIndices = {
  auto: "auto",
  negative: "-1",
  0: "0",
  10: "10",
  20: "20",
  30: "30",
  40: "40",
  50: "50",
  60: "60", // prev-next-nav
  70: "70", // slide
}

const pressedActive = {
  position: "relative",
  top: "2px",
}

const formPlaceholder = {
  "::placeholder": {
    color: "whisper",
    opacity: 0.8,
  },
}

const formFocus = {
  outline: 0,
  bg: "rgba(247,166,27, 0.1)",
  borderColor: "#F7A61B !important",
  boxShadow: "0 0 0 3px rgba(247,166,27, 0.5)",
}

const firstLetter = {
  fontSize: "3.25em",
  lineHeight: 1,
  textTransform: "uppercase",
  mt: "0.15em",
  mr: "0.15em",
  mb: "0px",
  ml: "0px",
  textIndent: 0,
  float: "left",
  display: "block",
  color: "primary",
}

const Theme = {
  config: {
    initialColorModeName: "dark",
    printColorModeName: "light",
    useColorSchemeMediaQuery: false,
    useCustomProperties: false,
  },
  colors: {
    text: "white",
    heading: "white",
    background: "#1C1B1B", // same as grey-900
    modal: "rgba(28, 27, 27, 0.97)", // same as grey-900
    primary: "#F7A61B", // yellow-500
    secondary: "#5C5B5B", // grey-500
    whisper: "#BEBDBD", // grey-300
    border: "#5C5B5B", // grey-500
    muted: "#292929", // grey-800
    disabled: "#373737", // grey-700
    contrast: "#FFF",
    contrastHover: "#EFEFEF",
    chart: "#BEBDBD", // grey-300
    padInactive: "rgba(255,255,255,0.3)",
    letter: "#3C3A39",

    "white-20": "rgba(255,255,255,0.2)",
    "white-70": "rgba(255,255,255,0.7)",

    positive: "#28CD41",
    caution: "#F7A61B", // yellow-500
    neutral: "#BEBDBD", // whisper
    negative: "#FF544B",

    inherit: "inherit",
    transparent: "transparent",

    spring: "#8CBE20",
    summer: "#F7A61B",
    autumn: "#EB5B05",
    winter: "#64AAD8",

    // Hegel & Hölderlin
    letters: {
      hoelderlin: "#F8D4B0",
      hegel: "#8AC8B3",
      schelling: "#E8908F",
    },

    yellow: {
      100: "#FEF6E8",
      200: "#FDE9C6",
      300: "#FCDBA4",
      400: "#F9C15F",
      500: "#F7A61B",
      600: "#DE9518",
      700: "#946410",
      800: "#6F4B0C",
      900: "#4A3208",
    },
    grey: {
      // 100: "#EFEFEF",
      // 200: "#D6D6D6",
      300: "#BEBDBD",
      400: "#8D8C8C",
      500: "#5C5B5B",
      600: "#535252",
      700: "#373737",
      // 800: "#292929",
      // 900: "#1C1B1B",
    },
    white: "#fff",
    black: "#000",
    modes: {
      light: {
        text: "black",
        heading: "black",
        background: "white",
        modal: "rgba(255, 255, 255, 0.97)",
        whisper: "#5C5B5B", // grey-500
        border: "#D6D6D6", // grey-200
        muted: "#EFEFEF", // grey-300
        disabled: "#D6D6D6", // grey-700
        contrast: "#000",
        contrastHover: "#292929",
        chart: "#BEBDBD", // grey-300
        positive: "#09810E",
        neutral: "#5C5B5B", // whisper
        caution: "#DE9518", // yellow-600
        negative: "#CD3D3D",
        padInactive: "rgba(0,0,0,0.3)",
        letter: "#EEEBE3",
      },
    },
  },
  fonts: {
    body: "Georgia, serif",
    heading:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    sans: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    serif: "Georgia, serif",
    mono: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    none: 1,
    body: 1.5,
    heading: 1.25,
  },
  borderWidths: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 64, 72],
  radii: {
    none: "0",
    sm: "2px",
    md: "4px",
    lg: "8px",
    full: "9999em",
  },
  shadows: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0)",
    md: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    lg: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    xl: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    "2xl":
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "3xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  space,
  sizes,
  transitions,
  zIndices,
  breakpoints: ["640px", "768px", "1024px", "1280px"],
  forms: {
    input: {
      ...formPlaceholder,
      ":focus": {
        ...formFocus,
      },
    },
    textarea: {
      ...formPlaceholder,
      ":focus": {
        ...formFocus,
      },
    },
    controls: {
      "input:focus ~ &": {
        ...formFocus,
      },
    },
    select: {
      ":focus": {
        outline: 0,
        borderColor: "#F7A61B !important",
        boxShadow: "0 0 0 3px rgba(247,166,27, 0.5)",
      },
    },
  },
  buttons: {
    pads: {
      ":active": {
        ...pressedActive,
      },
    },
  },
  text: {
    dropcap: {
      ":first-of-type::first-letter": {
        ...firstLetter,
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body",
      color: "text",
      bg: "background",
    },
  },
}

export default Theme
