import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import ReadMoreBox from "../../../components/read-more-box"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"

const EmpedoklesFuegungen = (props) => {
  const data = useStaticQuery(graphql`
    query RaumEmpedoklesFuegungenQuery {
      geometrie: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/empedokles-geometrie.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Trauerspiel ›Der Tod des Empedokles‹
          </Heading>
          <Paragraph>
            Den ersten Plan zu seinem Trauerspiel fasst Hölderlin 1797 als
            Hauslehrer in Frankfurt kurz nach Erscheinen des ersten Bands zum
            ›Hyperion‹.
          </Paragraph>
        </Stack>
      </Constrain>
      <Image
        image={data.geometrie.childImageSharp.gatsbyImageData}
        alt="Zwei Seiten eines Schulhefts"
        caption="Geometrieheft mit Hölderlins ›Frankfurter Plan‹ zum ›Empedokles‹ aus der Württembergischen Landesbibliothek Stuttgart"
      />
      <Constrain>
        <Stack>
          <Trivia title="Empedokles im Geometrieheft">
            »Und mache von a eine Linie nach g«, notierte Henry, der Sohn von
            Susette Gontard, gewissenhaft in sein Geometrieheft. Sein Hauslehrer
            Hölderlin schrieb darüber 1797 kurzerhand die erste Skizze zum
            ›Empedokles‹, den sogenannten ›Frankfurter Plan‹.
          </Trivia>
          <Paragraph>
            Mehr als ein Jahr später machte er sich erneut daran. Im Laufe des
            Jahres 1799 entstehen drei Entwürfe, die sich in Länge,
            Ausgangspunkt, Dramaturgie und Figurenanlage unterscheiden, aber nie
            über den 2. Akt, in dem sich der Held in den Ätna stürzt,
            hinausgehen.
          </Paragraph>
          <Paragraph>
            Dass darin auch die Folgen der Französischen Revolution verhandelt
            werden, wird deutlich, wenn Empedokles im zweiten Entwurf von seinen
            Gegnern vorgeworfen wird, seine aus der Nähe zu den Göttern
            resultierende Rolle als Anführer, selbst wenn sie vom Volk gewollt
            ist, bringe die Ordnung ins Wanken.
          </Paragraph>
          <Paragraph>
            So wird auch Empedokles’ Freitod im dritten Entwurf des Dramas als
            notwendiges Opfer für das Volk definiert: Niemals dürfe sich ein
            Einzelner anmaßen, die Probleme seiner Zeit allein lösen zu wollen,
            wie es Robespierre und später Napoleon taten.
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain>
        <ReadMoreBox cta="Mehr zum Empedokles" previewSize={32}>
          <Heading as="h3" level={5}>
            Empedokles – ein Revolutionsstück
          </Heading>
          <p>
            Einst Liebling der Götter und des Volkes zieht sich der Philosoph
            Empedokles auf den Ätna zurück. Die ihm vom Volk dargebotene Krone
            lehnt er ab, um sich stattdessen allein mit der Natur zu vereinen.
          </p>
          <p>
            Mit den mutigen Worten, die »Zeit der Könige« sei vorbei, lässt
            Hölderlin seinen Helden Empedokles die Krone ablehnen, die ihm vom
            Volk angeboten wird. Einst Liebling der Götter und des Volkes zieht
            er sich nun auf den Ätna zurück, um sich allein mit der Natur zu
            vereinen. Wenig später sucht er im Vulkan Ätna den Freitod.
          </p>
          <p>
            Das Trauerspiel ›Der Tod des Empedokles‹ blieb der einzige
            ausgeführte Versuch des Dichters, ein Drama zu schreiben.
          </p>
          <p>
            Mit der Legende um den griechischen Politiker und Philosophen, von
            dem die Lehre der vier Elemente stammt, versuchte Hölderlin – vor
            dem Hintergrund einer erhofften schwäbischen Republik – die
            problematischen Beziehungen zwischen dem Einzelnen, dem Volk und der
            Macht auszuloten. So erkennt Empedokles in seiner Position als
            Anführer etwa, dass das Volk keinen Alleinherrscher akzeptieren
            darf: »Euch ist nicht / Zu helfen, wenn ihr selber euch nicht
            helft.«
          </p>
          <p>
            Empedokles bleibt nur der Freitod, von dem seine Sandalen, »die der
            Feuerauswurf aus dem Abgrund geschleudert hatte«, zeugen. Hölderlin,
            der darin das »Schicksal seiner Zeit« sichtbar machen wollte, nahm
            mehrfach Anlauf, um des Stoffes habhaft zu werden und durchbrach
            dabei die dramaturgischen Normen seiner Zeit. Seit Beginn des 20.
            Jahrhunderts wurde das Stück so für das Theater zu einer großen
            Inspirationsquelle.
          </p>
        </ReadMoreBox>
      </Constrain>
    </Stack>
  )
}

export default EmpedoklesFuegungen
