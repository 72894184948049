import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import { ExternalLink } from 'react-external-link';

const Pressefreiheit = () => {
  const data = useStaticQuery(graphql`
    query Pressefreiheit {
      literatursommer: file(
        relativePath: { eq: "veranstaltungen/literatursommer24.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, height: 537, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Gemeinsam mit dem Chefredakteur des Schwäbischen Tagblatts Gernot Stegert, mit der Lyrikerin Nancy Hünger, 
        Leiterin des Studios Literatur und Theater und Mitglied des PEN Berlin, sowie Lyriker José F.A. Oliver, 
        Präsident des PEN-Zentrums Deutschland, sprechen wir über Verbindungen und Spannungen zwischen Literatur- und Pressefreiheit.
      </Paragraph>
      <Paragraph> 
        Eine Veranstaltung im Rahmen des{" "}
        <ExternalLink href="https://www.literatursommer.de/de/">
          <span style={{color: '#F7A61B'}}> Literatursommers Baden-Württemberg</span> 
        </ExternalLink>
        .
      </Paragraph>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.literatursommer.childImageSharp.gatsbyImageData}
          alt="Logo des Literatursommer 2024"
        />
      </Grid>
    </Stack>
  )
}

export default Pressefreiheit