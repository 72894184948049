import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Blockquote from "../../../components/blockquote"
import Constrain from "../../../components/constrain"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Trivia from "../../../components/trivia"

const GesammelteGedichte = (props) => {
  const data = useStaticQuery(graphql`
    query GesammelteGedichteHaelfteDesLebensQuery {
      widmung: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/widmung-an-schwab.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Heading as="h2" level={2}>
        Eine Gedichtausgabe aus fremder Hand
      </Heading>
      <Image
        image={data.widmung.childImageSharp.gatsbyImageData}
        alt="Aufgeklapptes Buch mit marmoriertem Einband in einer Vitrine"
        caption="Werkausgabe von Hölderlins Gedichten, 1826 herausgegeben von Gustav Schwab und Ludwig Uhland, aus dem Nachlass von Christoph Theodor Schwab. Deutsches Literaturarchiv Marbach"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            Trotz mancherlei Bemühungen hat Hölderlin selbst nur wenige seiner
            Werke veröffentlichen können. Die einzige Sammlung seiner Verse zu
            Lebzeiten wurde 1826 ohne sein Zutun veröffentlicht.
          </Paragraph>

          <Paragraph>
            Als er jenen Band 1841 von Christoph Theodor Schwab überreicht
            bekam, lehnte er das Geschenk ab, schrieb ihm auf dessen Bitte aber
            einige Zeilen hinein. Über die darin zusammengestellten Gedichte
            soll Hölderlin gesagt haben:
          </Paragraph>
          <Paragraph>
            <Blockquote>
              »Die sind von mir; aber der Name ist gefälscht, ich habe nie
              Hölderlin geheißen, sondern Scardanelli oder Scarivari oder
              Salvator Rosa oder so was.«
            </Blockquote>
          </Paragraph>
          <Paragraph>
            Die Herausgeber Ludwig Uhland und Gustav Schwab hatten jene Texte
            nicht in den Band aufgenommen, in denen ihnen »die Klarheit des
            Geistes schon bedeutend getrübt« erschien – darunter auch ›Hälfte
            des Lebens‹.
          </Paragraph>
          <Trivia title="Erneut gescheitert">
            Auch ein weiterer Versuch, Hölderlin ein Exemplar der inzwischen neu
            aufgelegten Gedichtsammlung zu überreichen, scheiterte. Am 24.
            Februar 1843 notierte der Herausgeber Gustav Schwab resigniert:
            »Hölderlin hat auch die neue Ausgabe seiner Gedichte, die man ihm –
            ohne das biographische Vorwort – überreicht hat, für unächt
            erklärt.«
          </Trivia>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default GesammelteGedichte
