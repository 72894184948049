import AudioPlayer from "../../../../src/components/audio-player";
import Grid from "../../../../src/components/grid";
import Poem from "../../../../src/components/poem";
import Small from "../../../../src/components/small";
import QuoteBox from "../../../../src/components/quote-box";
import * as React from 'react';
export default {
  AudioPlayer,
  Grid,
  Poem,
  Small,
  QuoteBox,
  React
};