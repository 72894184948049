import React from "react"
import Columns from "./columns"
import Column from "./column"
import Inline from "./inline"
import Text from "./text"
import Small from "./small"
import Box from "./box"
import Stack from "./stack"
import Heading from "./heading"

const MetricBox = (props) => {
  const { title, greek, greekNormalized, metric, children } = props
  return (
    <Box bg="muted" height="full" p={6}>
      <Stack space={3}>
        <Columns>
          <Column>
            <Stack space="1">
              <Small as="div" sans color="whisper">
                <Inline space={2}>
                  {greek}
                  {greekNormalized && (
                    <Text as="em" italic color="inherit">
                      {greekNormalized}
                    </Text>
                  )}
                </Inline>
              </Small>
              <Heading level={3}>{title}</Heading>
            </Stack>
          </Column>
          <Column width="content">
            <Text size={[7, 8]} color="primary" letterSpacing="4px">
              {metric}
            </Text>
          </Column>
        </Columns>
        <Text size={[4, 5]}>{children}</Text>
      </Stack>
    </Box>
  )
}

export default MetricBox
