import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Container from "../../../components/container"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Text from "../../../components/text"
import Stack from "../../../components/stack"

const Denkmal = () => {
  const data = useStaticQuery(graphql`
    query {
      denkmal: file(
        relativePath: { eq: "aus-dem-archiv-geholt/07/denkmal.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Container maxWidth="xl">
      <Stack space={0}>
        <Image
          image={data.denkmal.childImageSharp.gatsbyImageData}
          alt="Eine Stein-Büste von Johannes Kepler vor einer Marmortafel mit Keplers Gesetzen und einer runden Marmorplatte."
          attribution="Albinus"
        />
        <Box p={8} bg="muted">
          <Stack space={6}>
            <Heading as="strong" level={5}>
              Kepler-Denkmal im Stadtpark Graz
            </Heading>
            <Text>
              Von 1963 von Alfred Pirker (1910–1986), 1989 ergänzt um die
              ›Keplerschen Gesetze‹. Die Säule ist beschriftet ›JOHANNES |
              KEPLER | LEHRTE IN GRAZ | 1594–1600‹. Auf der waage­rechten
              Granit­platte symbolisiert die größere metallene Halbkugel die
              Sonne, die in einem der beiden Brennpunkte der elliptischen Bahn
              steht, welche der Planet, die kleinere Halb­kugel am hinteren
              Rand, durchläuft. Die hier abwechselnd hell- und dunkelgrau
              gefärbten zwölf Segmente haben gleichen Flächeninhalt, ihr
              gekrümmter Rand wird in je einem Zwölftel der gesamten Umlaufzeit
              des Planeten durchlaufen.
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Denkmal
