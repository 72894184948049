import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import ReadMore from "../../../components/read-more"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Stack from "../../../components/stack"

const EinfuehrungFuegungen = (props) => {
  const data = useStaticQuery(graphql`
    query RaumIntroFuegungenQuery {
      cover: file(
        relativePath: { eq: "dauerausstellung/07-harte-fuegungen/cover.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Paragraph dropcap={true}>
        Obwohl Hölderlin mehr als die Hälfte seines Lebens in Tübingen verbracht
        hat, ist der größte Teil seines Werkes nicht dort entstanden, sondern in
        Frankfurt am Main und Bad Homburg ebenso wie in Nürtingen und Stuttgart.
        Der Kosmos seiner Literatur reichte freilich weit darüber hinaus. Im
        Schreiben versuchte der Dichter die Welt als globales Ganzes zu fassen.
      </Paragraph>
      <Image
        image={data.cover.childImageSharp.gatsbyImageData}
        alt="Raumansicht mit neun dunklen säulenartigen Vitrinen an der Wand und einer Vitrine mit Glashaube in der Mitte"
        attribution="Gudrun de Maddalena"
      />
      <Constrain>
        <Stack>
          <Paragraph>
            Ein Roman, ein Drama und zahlreiche Gedichte, aber auch
            theoretische, philosophische und poetologische Texte und
            Übersetzungen sind in Hölderlins Werk enthalten. Über alle Gattungen
            hinweg bewies er einen beständigen Willen zum Experiment. In der
            Literaturgeschichte kommt ihm ein Sonderplatz zu: Er lässt sich
            keiner Strömung zuordnen – weder der Klassik noch der Romantik. Mit
            deren Vertretern teilte er die Überzeugung, die Dichtung könne eine
            bessere Welt schaffen. Er übertraf sie aber in der Radikalität und
            Unbedingtheit, mit der er die Literatur dafür umgestalten wollte.
          </Paragraph>
          <Paragraph>
            In seinem Sprachlabor trieb er die Dichtung bis an die Grenzen des
            Möglichen; er modernisierte Stoffe und Denkweisen der{" "}
            <ReadMore
              content={
                <Constrain>
                  <Paragraph>
                    Wenn es um Griechenland ging, ging es zu Hölderlins Zeit um
                    alles! Griechenland war das »heilige Grab der jugendlichen
                    Menschheit«. Hier ruhten jene Werte, die man wiederbeleben
                    wollte. Allerdings hat Hölderlin griechischen Boden nie
                    betreten und die den Göttern geweihten Tempel nie mit
                    eigenen Augen gesehen.
                  </Paragraph>
                </Constrain>
              }
            >
              griechischen Antike
            </ReadMore>{" "}
            und markierte einen einzigartigen ›Gesichtspunct aus dem wir das
            Altertum anzusehen haben‹.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default EinfuehrungFuegungen
