import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "../../../components/grid"
import Poem from "../../../components/poem"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspot from "../../../components/image-hotspot"

const Herbst = (props) => {
  const data = useStaticQuery(graphql`
    query JahreszeitenHerbstQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-herbst.png"
        }
      ) {
        ...largeImage
      }
      herbst01: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-01.jpg"
        }
      ) {
        ...largeImage
      }
      herbst02: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-02.jpg"
        }
      ) {
        ...mediumImage
      }
      herbst03: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-03.jpg"
        }
      ) {
        ...largeImage
      }
      herbst04: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-04.jpg"
        }
      ) {
        ...largeImage
      }
      herbst05: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-05.jpg"
        }
      ) {
        ...largeImage
      }
      herbst06: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/herbst-06.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack>
      <Grid columns={[1, 2]} alignY="center">
        <ImageHotspots
          caption="Hölderlins Gedichtmanuskript ›Der Herbst.‹ aus der Fondation Martin Bodmer"
          image={
            <Image
              image={data.gedicht.childImageSharp.gatsbyImageData}
              alt="Handschriftliches Manuskript von Hölderlins ›Der Herbst.‹"
            />
          }
        >
          <ImageHotspot x={16} y={4} bg="autumn" name="schreibfluss">
            <ModalTemplate
              title="Originalität beglaubigt"
              image={
                <Image
                  image={data.herbst01.childImageSharp.gatsbyImageData}
                  alt="Die vergrößerte Darstellung der linken oberen Ecke des Manuskripts zeigt ein Echtheitszertifikat von unbekannter Hand"
                />
              }
            >
              <Paragraph>
                Links oben hat ein unbekannter Sammler die Echtheit des
                Manuskripts bestätigt: »Autographie v. Hölderlin«. Heute
                befindet sich das Manuskript in der Sammlung Bodmer in Genf.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={88} y={5} bg="autumn" name="herbstgedicht">
            <ModalTemplate
              title="Ein Herbstgedicht im Sommer?"
              image={
                <Image
                  image={data.herbst02.childImageSharp.gatsbyImageData}
                  alt="auf dem Manuskript rechts oben vermerkte Datierung"
                />
              }
            >
              <Paragraph>
                Ob sich Hölderlin im Tübinger Hochsommer eine Brise Herbst
                herbeigesehnt hat? Die auf dem Manuskript vermerkte Datierung
                gibt dies zumindest vor. Am 12. Juli 1842 soll das Gedicht
                entstanden sein.
              </Paragraph>
              <Paragraph>
                Christoph Theodor Schwab, der das Gedicht noch einmal
                abgeschrieben hat, datiert es zwar auf den 15. November 1841,
                doch Georg Herwegh bestätigt den 12. Juli 1842 in einer
                Aufzeichung aus demselben Jahr.
              </Paragraph>
              <Paragraph>
                Erstmals veröffentlicht wurde Hölderlins ›Herbst‹-Gedicht
                allerdings erst 1927 durch Stefan Zweig, der selbst ein großer
                Autographensammler und Bewunderer des Dichters war.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={36} y={6} bg="autumn" name="platzhalter">
            <ModalTemplate
              title="Ein Platzhalter"
              image={
                <Image
                  image={data.herbst03.childImageSharp.gatsbyImageData}
                  alt="Lücke im Gedichttitel"
                />
              }
            >
              <Paragraph>
                Zwischen der Jahreszeit und dem Artikel hat Hölderlin eine
                auffällig große Lücke gelassen, die möglicherweise daher rührt,
                dass er den Artikel erst nachträglich hinzugefügt hat.
              </Paragraph>
              <Paragraph>
                Für die Leser*innen hat er damit einen Platzhalter geschaffen,
                der sich nach eigenem Belieben vervollständigen lässt: Der
                goldene Herbst? Der schaurige Herbst? Der verlorene Herbst?
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={63} y={54} bg="autumn" name="guss">
            <ModalTemplate
              title="Aus einem Guss"
              image={
                <Image
                  image={data.herbst04.childImageSharp.gatsbyImageData}
                  alt="Detailansicht der Handschrift"
                />
              }
            >
              <Paragraph>
                Anders als die Entwürfe zu Hölderlins früheren Oden, Gesängen
                und Elegien, die einem Labyrinth aus Streichungen, Ergänzungen
                und Überarbeitungsstufen gleichen, finden sich in den
                Handschriften seiner Turmgedichte kaum Korrekturen. Alles
                scheint aus einem Guss.
              </Paragraph>
              <Paragraph>
                Linear und meist in nur wenigen Minuten soll Hölderlin jene
                Gedichte zu Papier gebracht haben. So berichten es seine
                Besucher. Doch lässt sich annehmen, dass der Dichter die Verse
                längst in Gedanken ausgearbeitet und den Akt des Schreibens vor
                seinen Turmbesuchern lediglich zur Schau gestellt hat.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={48} y={33} bg="autumn" name="gespiegelt">
            <ModalTemplate
              title="Seitenverkehrt"
              image={
                <Image
                  image={data.herbst05.childImageSharp.gatsbyImageData}
                  alt="Von der Rückseite des Blattes scheinen an einigen Stellen gespiegelte Buchstaben hindurch"
                />
              }
            >
              <Paragraph>
                Von der Rückseite des Blattes scheinen an einigen Stellen die
                letzten beiden Verse und Hölderlins Datierung des Gedichts
                hindurch und spiegeln spiegeln sich seitenverkehrt wie Ornamente
                im Geschriebenen der Vorderseite.
              </Paragraph>
            </ModalTemplate>
          </ImageHotspot>
          <ImageHotspot x={70} y={78} bg="autumn" name="leere">
            <ModalTemplate
              title="Gefüllte Leere"
              image={
                <Image
                  image={data.herbst06.childImageSharp.gatsbyImageData}
                  alt="Detailansicht des Seitenendes"
                />
              }
            >
              <Paragraph>
                Selten gehen die Turmgedichte über die Länge einer Seite hinaus.
                In diesem aber führte Hölderlin mit dem Vers »Wenn schon mit
                Leere sich die Felder dann vertauschen.« auf die bis dato noch
                leere Rückseite über, deren oberes Drittel mit den letzten
                Zeilen des Gedichts gefüllt wurde:
              </Paragraph>
              <Poem>
                <span>»Der ganze Sinn des hellen Bildes lebet</span>
                <span>Als wie ein Bild, das goldne Pracht umschwebet.</span>
                <span>
                  <br />
                </span>
                <span>d. 15ten Nov.</span>
                <span>1759.«</span>
              </Poem>
            </ModalTemplate>
          </ImageHotspot>
        </ImageHotspots>
        <Stack space={6}>
          <Heading level={3} color="autumn">
            Der Herbst.
          </Heading>
          <Poem size="3">
            <span>Das Glänzen der Natur ist höheres Erscheinen,</span>
            <span>Wo sich der Tag mit vielen Freuden endet,</span>
            <span>Es ist das Jahr, das sich mit Pracht vollendet.</span>
            <span>Wo Früchte sich mit frohem Glanz vereinen.</span>
            <span>
              <br />
            </span>
            <span>Das Erdenrund ist so geschmükt, und selten lärmet</span>
            <span>Der Schall durchs offne Feld, die Sonne wärmet</span>
            <span>Den Tag des Herbstes mild, die Felder stehen</span>
            <span>Als eine Aussicht weit, die Lüffte wehen</span>
            <span>
              <br />
            </span>
            <span>Die Zweig' und Äste durch mit frohem Rauschen</span>
            <span>Wenn schon mit Leere sich die Felder dann vertauschen.</span>
            <span>Der ganze Sinn des hellen Bildes lebet</span>
            <span>Als wie ein Bild, das goldne Pracht umschwebet.</span>
            <span>
              <br />
            </span>
            <span>d. 15ten Nov.</span>
            <span>1759.</span>
          </Poem>
        </Stack>
      </Grid>
    </Stack>
  )
}

export default Herbst
