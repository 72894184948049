import React from "react"

import Text from "./text"

const Small = (props) => {
  const { children, ...restProps } = props

  return (
    <Text size={1} {...restProps}>
      {children}
    </Text>
  )
}

export default Small
