import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import QuoteBox from "../../../components/quote-box"
import Image from "../../../components/image"

const EleusisHegel = (props) => {
  const data = useStaticQuery(graphql`
    query EleusisHegelQuery {
      hegel: file(
        relativePath: { eq: "aus-dem-archiv-geholt/01/schlesinger-hegel.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Grid columns={2}>
      <QuoteBox author="Georg Wilhelm Friedrich Hegel (nach Heinrich Heine)">
        nur Einer hat mich verstanden […] und der hat mich auch nicht verstanden
      </QuoteBox>
      <Image
        image={data.hegel.childImageSharp.gatsbyImageData}
        alt="Ein Ölgemälde zeigt den alten Georg Wilhelm Friedrich Hegel"
        caption="Georg Wilhelm Friedrich Hegel porträtiert von Jakob Schlesinger, 1831"
      />
    </Grid>
  )
}

export default EleusisHegel
