import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import VideoPlayer from "../../../components/video-player"

const VideoKommerell = (props) => {
  const data = useStaticQuery(graphql`
    query VideoKommerellQuery {
      poster: file(
        relativePath: { eq: "turmgespraeche/poster-blanche-kommerell.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return <VideoPlayer src="blanche-kommerell" poster={data.poster} />
}

export default VideoKommerell
