import EinfuehrungHaelfte from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/einfuehrung-haelfte";
import GesammelteGedichte from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/gesammelte-gedichte";
import Fruehling from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/fruehling";
import Herbst from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/herbst";
import JahreszeitenGedichte from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/jahreszeiten-gedichte";
import Scardanelli from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/scardanelli";
import Sommer from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/sommer";
import Winter from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/winter";
import JahreszeitenDatierung from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/jahreszeiten-datierung";
import AttributionChristianReiner from "../../../../src/content/dauerausstellung/05-haelfte-des-lebens/attribution-christian-reiner";
import * as React from 'react';
export default {
  EinfuehrungHaelfte,
  GesammelteGedichte,
  Fruehling,
  Herbst,
  JahreszeitenGedichte,
  Scardanelli,
  Sommer,
  Winter,
  JahreszeitenDatierung,
  AttributionChristianReiner,
  React
};