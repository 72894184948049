import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Blockquote from "../../../components/blockquote"
import Box from "../../../components/box"
import Breakout from "../../../components/breakout"
import Constrain from "../../../components/constrain"
import Container from "../../../components/container"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import ReadMore from "../../../components/read-more"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const JahreszeitenGedichte = (props) => {
  const data = useStaticQuery(graphql`
    query JahreszeitenGedichteQuery {
      fruehling: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-fruehling.png"
        }
      ) {
        ...largeImage
      }
      sommer: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-sommer.png"
        }
      ) {
        ...largeImage
      }
      herbst: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-herbst.png"
        }
      ) {
        ...largeImage
      }
      winter: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-winter.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Box bg="background">
      <Stack>
        <Heading as="h2" level={2}>
          Die Jahreszeitengedichte
        </Heading>
        <Breakout>
          <Container>
            <Box px={6}>
              <Grid columns={[2, 4]} space={1} alignY="center">
                <Image
                  alt="Handschriftliches Manuskript von Hölderlins ›Der Frühling.‹"
                  image={data.fruehling.childImageSharp.gatsbyImageData}
                />
                <Image
                  alt="Handschriftliches Manuskript von Hölderlins ›Der Sommer.‹"
                  image={data.sommer.childImageSharp.gatsbyImageData}
                />
                <Image
                  alt="Handschriftliches Manuskript von Hölderlins ›Der Herbst.‹"
                  image={data.herbst.childImageSharp.gatsbyImageData}
                />
                <Image
                  alt="Handschriftliches Manuskript von Hölderlins ›Der Winter.‹"
                  image={data.winter.childImageSharp.gatsbyImageData}
                />
              </Grid>
            </Box>
          </Container>
        </Breakout>
        <Constrain>
          <Stack>
            <Container maxWidth="xl">
              <Inline as="ul" alignY="center" alignX="center">
                <Heading as="li" color="spring" level={1}>
                  9x Frühling
                </Heading>
                <Heading as="li" color="summer" level={3}>
                  5x Sommer
                </Heading>
                <Heading as="li" color="autumn" level={4}>
                  2x Herbst
                </Heading>
                <Heading as="li" color="winter" level={2}>
                  6x Winter
                </Heading>
              </Inline>
            </Container>
            <Paragraph>
              Über die Zahl der Gedichte, die Hölderlin in seinen letzten 36
              Jahre verfasst hat, kann man{" "}
              <ReadMore
                content={
                  <Constrain>
                    <Stack>
                      <Paragraph>
                        Dass Hölderlin weit mehr als die 48 kurzen,
                        überlieferten Gedichte geschrieben haben muss, steht für
                        Gregor Wittkop, der die Dokumente aus jener Zeit in
                        einem Band zusammengestellt hat, außer Frage. Mit
                        einigem Entsetzen blickt er auf die generellen
                        »Entwendungen, Verschickungen, das Verbummeln und
                        Vertrödeln Hölderlinscher Originale!«:
                      </Paragraph>
                      <Paragraph>
                        <Blockquote>
                          »Mahlmann verschlampt das Tragödienfragment ›Agis‹;
                          Kerner verschickt die einzige handschriftliche
                          Gedichtsammlung an eine falsche Adresse; Neuffer weiß
                          nicht mehr, wo er die Jugendgedichte verstaut hat;
                          Conz holt einen Stapel von Gedichten aus dem Turm und
                          läßt Schreiner Zimmer einen ›bidren Meister‹ sein
                          (Conzens Nachlaß: bis heute verschollen); Waiblinger
                          klemmt sich ebenfalls eine ›Rolle solcher Papiere‹
                          unter den Arm und da nimmt auch Mörike etwas fort und
                          schenkt es später Hermann Kurz...«
                        </Blockquote>
                      </Paragraph>
                      <Text sans>
                        Gregor Wittkop (Hrsg.):{" "}
                        <TextLink href="https://link.springer.com/book/10.1007/978-3-476-03488-5">
                          Hölderlin. Der Pflegsohn. Texte und Dokumente
                          1806-1843 mit den neu entdeckten Nürtinger
                          Pflegschaftsakten
                        </TextLink>
                        , Stuttgart und Weimar 1993.
                      </Text>
                    </Stack>
                  </Constrain>
                }
              >
                heute nur mutmaßen
              </ReadMore>
              . Fast die Hälfte der überlieferten Gedichte widmet sich den
              Jahreszeiten: neun dem Frühling, fünf dem Sommer, zwei dem Herbst
              und sechs dem Winter.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>
    </Box>
  )
}

export default JahreszeitenGedichte
