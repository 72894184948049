/** @jsx jsx */

import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import Box from "./box"
import TextLink from "./textlink"
import PropTypes from "prop-types"

import { getSrc } from "gatsby-plugin-image"

const VideoPlayer = ({ src, poster }) => {
  return (
    src && (
      <Box>
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <video controls sx={{ width: "full" }} poster={getSrc(poster)}>
          <source
            src={`${process.env.GATSBY_MEDIA_URL}/video/${src}.mp4`}
            type="video/mp4"
          />
          <source
            src={`${process.env.GATSBY_MEDIA_URL}/video/${src}.webm`}
            type="video/webm"
          />
          <Box bg="muted" p={6}>
            Dein Browser unterstützt leider keine Video-Wiedergabe.{" "}
            <TextLink href={`${process.env.GATSBY_MEDIA_URL}/video/${src}.mp4`}>
              Hier kannst du das Video herunterladen
            </TextLink>
          </Box>
        </video>
        {/* eslint-enable jsx-a11y/media-has-caption */}
      </Box>
    )
  )
}

VideoPlayer.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.object,
}

export default VideoPlayer
