import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Heading from "../../components/heading"
import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const Richter = () => {
  const data = useStaticQuery(graphql`
    query Richter {
        liland: file(
            relativePath: { eq: "veranstaltungen/liland-bawue.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 268, height: 300, layout: CONSTRAINED)
            }
        }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Heute suche ich wie morgen schon nach meinen Sprachen.<br />
        Jose F. A. Oliver, Fremdenzimmer
      </Paragraph>
      <Paragraph>
        Davon sind im mittleren Lebensalter viele betroffen: Die Pflege ihrer nunmehr alt gewordenen Eltern. 
        Elisabeth Richters Roman „Letztes Zimmer“ ist etwas Besonderes: Manchmal ist er urkomisch und vergnüglich zu lesen, 
        manchmal schießen plötzlich Tränen in die Augen. Elisabeth Richter erzählt wunderbar und auch schonungslos von der Tragik 
        und Komik des Alterns von Mutter und Vater.
      </Paragraph>
      <Paragraph>
        Der Vater der Erzählerin Tina leidet an schleichender Demenz, die Mutter scheint blind zu werden und zunehmend verrückt. 
        Schließlich bringt die Tochter - die selbst eine eigene Familie hat - ihre Eltern in einem Pflegeheim unter. 
        Vor ihnen liegt ein mehrjähriger gemeinsamer Weg, auf dem die Eltern immer mehr von ihrer einstigen Substanz verlieren. 
        Aber ein Charakter ändert sich nicht. Die Metamorphose der Eltern, die zu eigensinnigen Kindern werden, 
        bedeutet für Tina das Ende ihres eigenen Kindseins. Neben dem Erleben der Ich-Erzählerin wird die Katastrophe geschildert, 
        die Demenz für einen Menschen bedeutet, denn das Gefühlsleben bleibt vom geistigen Verfall unangetastet. 
        Verrücktheit ist oft lustig - allerdings nur für die Zuschauer.
      </Paragraph>
      <Paragraph>
        Elisabeth Richter studierte Pädagogik und Soziologie in Göttingen. Berufsjahre als Buchhändlerin, Internatslehrerin 
        und -erzieherin, Familientherapeutin, Kulturjournalistin. Freie Mitarbeit bei verschiedenen Zeitungen, 
        wiederholt Beiträge für DIE ZEIT, DAS MAGAZIN. Autorin von Kurzgeschichten. Mehrfache Preisträgerin, 
        u.a. Brandenburgischer Literaturpreis. Sie lebt in einem Dorf in der Prignitz, Brandenburg.
      </Paragraph>
      <Paragraph>
        Eine Kooperation mit dem Konkursbuch Verlag Claudia Gehrke.
      </Paragraph>
    </Stack>
  )
}

export default Richter