import React, { createContext, useContext, useReducer, useEffect } from "react"
import produce from "immer"
import courseData from "./courseData"

const gobalWindow = typeof window !== "undefined" && window

export const answerStatus = {
  VALID_CHECKED: "VALID_CHECKED",
  VALID_UNCHECKED: "VALID_UNCHECKED",
  INVALID: "INVALID",
  MISSED: "MISSED",
}

export const getCourse = ({ courseId }) => {
  const course = courseData.courses.find(({ id }) => id === courseId)
  return course
}

export const getChapter = ({ courseId, chapterId }) => {
  const course = getCourse({ courseId })
  const chapter = course.chapters.find(({ id }) => id === chapterId)
  return chapter
}

export const getTask = ({ courseId, chapterId, taskId }) => {
  const chapter = getChapter({ courseId, chapterId })
  const task = chapter.tasks.find(({ id }) => id === taskId)
  return task
}

export const getCourseNavigation = ({ courseId }) => {
  const course = getCourse({ courseId })
  const courseRoute = `/kurse/${course.id}`
  const navigation = { title: course.title, route: courseRoute }
  navigation.chapters = course.chapters.map((chapter) => ({
    title: chapter.title,
    route: `${courseRoute}/${chapter.id}`,
    tasks: chapter.tasks.map((task) => ({
      title: task.title,
      route: `${courseRoute}/${chapter.id}/${task.id}`,
      invisible: task.invisible,
    })),
  }))
  return navigation
}

export const getCourseProgress = ({ courseId, chapterId, taskId }) => {
  let chapter = 0
  let chapterTitle = ""
  let tasksInCurrentChapter = 0
  let task = 0
  let taskInCurrentChapter = 0

  const course = getCourse({ courseId })
  const courseTitle = course.title
  const totalChapters = course.chapters.length
  const totalTasks = course.chapters.reduce((acc, chapter) => {
    return (
      chapter.tasks.filter((task) => !task.invisible && task.isTask !== false)
        .length + acc
    )
  }, 0)

  const currentChapterIndex = course.chapters.findIndex(
    (chapter) => chapter.id === chapterId
  )
  if (currentChapterIndex >= 0) {
    const currentChapter = course.chapters[currentChapterIndex]
    chapter = currentChapterIndex + 1
    chapterTitle = currentChapter.title
    tasksInCurrentChapter = currentChapter.tasks.filter(
      (task) => !task.invisible && task.isTask !== false
    ).length
    const currentTaskIndex = currentChapter.tasks
      .filter((task) => !task.invisible && task.isTask !== false)
      .findIndex((task) => task.id === taskId)
    taskInCurrentChapter = currentTaskIndex + 1
    if (currentChapterIndex > 0) {
      for (let i = 0; i < currentChapterIndex; i++) {
        task =
          task +
          course.chapters[i].tasks.filter(
            (task) => !task.invisible && task.isTask !== false
          ).length
      }
      task = task + taskInCurrentChapter
    } else {
      task = taskInCurrentChapter
    }
  }

  return {
    courseTitle,
    totalChapters,
    chapter,
    chapterTitle,
    totalTasks,
    tasksInCurrentChapter,
    taskInCurrentChapter,
    task,
  }
}

const initialState = {
  answers: {},
}

const reducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "answer/set":
      if (!draft.answers[action.courseId]) {
        draft.answers[action.courseId] = {}
      }
      if (!draft.answers[action.courseId][action.chapterId]) {
        draft.answers[action.courseId][action.chapterId] = {}
      }
      draft.answers[action.courseId][action.chapterId][action.taskId] =
        action.answer
  }
})

export const CourseContext = createContext({})

export const CourseProvider = ({ children }) => {
  const localState = gobalWindow
    ? JSON.parse(gobalWindow.localStorage.getItem("courseState"))
    : null
  const [state, dispatch] = useReducer(reducer, localState || initialState)

  useEffect(() => {
    if (gobalWindow) {
      localStorage.setItem("courseState", JSON.stringify(state))
    }
  }, [state])

  const setAnswer = ({ courseId, chapterId, taskId, answer }) => {
    dispatch({ type: "answer/set", courseId, chapterId, taskId, answer })
  }

  const getAnswer = ({ courseId, chapterId, taskId }) => {
    return state.answers[courseId]?.[chapterId]?.[taskId]
  }

  const booleanAnswerIsValid = ({ courseId, chapterId, taskId }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId })

    // not given answers are treated as incorrect
    if (typeof answer === "undefined" || answer === null) {
      return false
    }

    return task.solution === answer
  }

  const answerStatusForArrayValue = ({
    courseId,
    chapterId,
    taskId,
    value,
  }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId })
    const valueInSolution = task.solution.find((val) => val === value)
    const valueInAnswer =
      answer && Object.values(answer).find((val) => val === value)

    if (valueInSolution && valueInAnswer) {
      return answerStatus.VALID_CHECKED
    }
    if (valueInSolution && !valueInAnswer) {
      return answerStatus.MISSED
    }
    if (!valueInSolution && valueInAnswer) {
      return answerStatus.INVALID
    }
    return answerStatus.VALID_UNCHECKED
  }

  const answerStatusForArray = ({ courseId, chapterId, taskId }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId }) || {}
    const relevantIds = [...task.solution, ...Object.values(answer)]

    const answers = relevantIds.reduce((acc, value) => {
      const status = answerStatusForArrayValue({
        courseId,
        chapterId,
        taskId,
        value,
      })
      return {
        ...acc,
        [value]: {
          status,
          answer: answer[value],
          solution: task.solution[value],
        },
      }
    }, {})
    return answers
  }

  const answerStatusForDictValue = ({
    courseId,
    chapterId,
    taskId,
    dictId,
    comparator,
  }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId }) || {}

    const solutionDict = task.solution[dictId]
    const answerDict = answer[dictId]

    if (comparator) {
      return comparator({
        solution: solutionDict,
        answer: answerDict,
        status: answerStatus,
      })
    }

    const isValid = solutionDict === answerDict
    if (solutionDict && answerDict && isValid) {
      return answerStatus.VALID_CHECKED
    }
    if (solutionDict && !answerDict) {
      return answerStatus.MISSED
    }
    if (!solutionDict && answerDict) {
      return answerStatus.INVALID
    }
    if (solutionDict && answerDict && !isValid) {
      return answerStatus.INVALID
    }
    return answerStatus.VALID_UNCHECKED
  }

  const answerStatusForDict = ({ courseId, chapterId, taskId, comparator }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId }) || {}

    const relevantIds = [...Object.keys(task.solution), ...Object.keys(answer)]

    const answers = relevantIds.reduce((acc, id) => {
      const status = answerStatusForDictValue({
        courseId,
        chapterId,
        taskId,
        dictId: id,
        comparator,
      })
      return {
        ...acc,
        [id]: {
          status,
          answer: answer[id],
          solution: task.solution[id],
        },
      }
    }, {})
    return answers
  }

  const dictAnswerIsValid = ({ courseId, chapterId, taskId, dictId }) => {
    const task = getTask({ courseId, chapterId, taskId })
    const answer = getAnswer({ courseId, chapterId, taskId })

    // not given answers are treated as incorrect
    if (
      !answer ||
      typeof answer[dictId] === undefined ||
      answer[dictId] === null
    ) {
      return false
    }

    return task.solution[dictId] === answer[dictId]
  }

  const getFormData = ({ event }) => {
    const form = new FormData(event.target)
    const entries = Object.fromEntries(form.entries())
    return entries
  }

  const submitForm = ({ event, courseId, chapterId, taskId }) => {
    const answer = getFormData({ event })
    setAnswer({ courseId, chapterId, taskId, answer })
  }

  const getStatsForChapterWithBoolean = ({ courseId, chapterId }) => {
    const chapter = getChapter({ courseId, chapterId })
    const [correct, incorrect] = chapter.tasks.reduce(
      (acc, task) =>
        booleanAnswerIsValid({ courseId, chapterId, taskId: task.id })
          ? [acc[0] + 1, acc[1]]
          : [acc[0], acc[1] + 1],
      [0, 0]
    )
    return {
      correct,
      incorrect,
      missed: 0,
    }
  }

  const getStatsForAnswerStatus = ({ answers }) => {
    const values = Object.values(answers)
    const correct = values.reduce(
      (acc, value) =>
        acc + (value.status === answerStatus.VALID_CHECKED ? 1 : 0),
      0
    )
    const incorrect = values.reduce(
      (acc, value) => acc + (value.status === answerStatus.INVALID ? 1 : 0),
      0
    )
    const missed = values.reduce(
      (acc, value) => acc + (value.status === answerStatus.MISSED ? 1 : 0),
      0
    )

    return {
      correct,
      incorrect,
      missed,
    }
  }

  const getStatsForTaskWithArray = ({ courseId, chapterId, taskId }) => {
    const answers = answerStatusForArray({ courseId, chapterId, taskId })
    return getStatsForAnswerStatus({ answers })
  }

  const getStatsForTaskWithDict = ({
    courseId,
    chapterId,
    taskId,
    comparator,
  }) => {
    const answers = answerStatusForDict({
      courseId,
      chapterId,
      taskId,
      comparator,
    })
    return getStatsForAnswerStatus({ answers })
  }

  return (
    <CourseContext.Provider
      value={{
        state,
        dispatch,
        getCourse,
        getChapter,
        getTask,
        setAnswer,
        getAnswer,
        submitForm,
        getFormData,
        booleanAnswerIsValid,
        dictAnswerIsValid,
        getStatsForChapterWithBoolean,
        getStatsForTaskWithArray,
        getStatsForTaskWithDict,
        answerStatusForArrayValue,
        answerStatusForArray,
        answerStatusForDict,
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}

export const useCourseStore = () => {
  return useContext(CourseContext)
}
