import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../../components/grid"
import Image from "../../../components/image"
import Small from "../../../components/small"
import Stack from "../../../components/stack"

const EleusisManuskript = (props) => {
  const data = useStaticQuery(graphql`
    query EleusisManuskriptQuery {
      eleusis1: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/01/eleusis_manuskript_01.png"
        }
      ) {
        ...largeImage
      }
      eleusis2: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/01/eleusis_manuskript_02.png"
        }
      ) {
        ...largeImage
      }
      eleusis3: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/01/eleusis_manuskript_03.png"
        }
      ) {
        ...largeImage
      }
      eleusis4: file(
        relativePath: {
          eq: "aus-dem-archiv-geholt/01/eleusis_manuskript_04.png"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Grid columns={2}>
        <Image
          image={data.eleusis1.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus dem Manuskript zu ›Eleusis‹"
          caption="Eleusis Seite 1 - Vorderseite"
        />
        <Image
          image={data.eleusis2.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus dem Manuskript zu ›Eleusis‹"
          caption="Eleusis Seite 1 - Rückseite"
        />
        <Image
          image={data.eleusis3.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus dem Manuskript zu ›Eleusis‹"
          caption="Eleusis Seite 2 - Vorderseite"
        />
        <Image
          image={data.eleusis4.childImageSharp.gatsbyImageData}
          alt="Eine Seite aus dem Manuskript zu ›Eleusis‹"
          caption="Eleusis Seite 2 - Rückseite"
        />
      </Grid>

      <Small>
        Manuskript aus der Bibliothek der Eberhard-Karls-Universität Tübingen.
      </Small>
    </Stack>
  )
}

export default EleusisManuskript
