import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Stack from "../../components/stack"
import Image from "../../components/image"
import Grid from "../../components/grid"

const Knubben = () => {
  const data = useStaticQuery(graphql`
    query Knubben {
      knubben: file(
        relativePath: { eq: "veranstaltungen/knubben.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, height: 500, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Paragraph>
        Hölderlins Reise nach Bordeaux im Winter 1801/02 stellt bis heute ein großes Rätsel dar. 
        Sicher ist nur: Sie wurde zum entscheidenden Wendepunkt im Leben und Schreiben des Dichters. 
        Thomas Knubben ist der Route Hölderlins zu Fuß gefolgt. Von Nürtingen wanderte er über die Alb, 
        über den Schwarzwald, über Straßburg, Lyon, die Auvergne nach Bordeaux. 
        Er will wissen, ob auf diese Weise Neues zu erfahren ist über Hölderlins »fatale Reise«. Und ob es gelingen kann, 
        den in den Dichterolymp Entschwundenen wieder ein Stück weit zurückzuholen in den Erfahrungshorizont der Gegenwart, 
        ihn begreifbar zu machen in seiner alltäglichen poetischen Kraft.
      </Paragraph>
      <Grid columns={2} space={"px"}>
        <Image
          image={data.knubben.childImageSharp.gatsbyImageData}
          alt="Cover des Buches von Thomas Knubben - Hölderlin: Eine Winterreise" 
        />
      </Grid>
    </Stack>
  )
}

export default Knubben