import Autorinnen from "../../../../src/content/aus-dem-archiv-geholt/band-06-theure-schwester/autorinnen";
import Brief from "../../../../src/content/aus-dem-archiv-geholt/band-06-theure-schwester/brief";
import Constrain from "../../../../src/components/constrain";
import Faltung from "../../../../src/content/aus-dem-archiv-geholt/band-06-theure-schwester/faltung";
import NurUnsereBesten from "../../../../src/content/aus-dem-archiv-geholt/band-06-theure-schwester/nur-unsere-besten";
import Landschaft from "../../../../src/content/aus-dem-archiv-geholt/band-06-theure-schwester/landschaft";
import QuoteBox from "../../../../src/components/quote-box";
import Small from "../../../../src/components/small";
import * as React from 'react';
export default {
  Autorinnen,
  Brief,
  Constrain,
  Faltung,
  NurUnsereBesten,
  Landschaft,
  QuoteBox,
  Small,
  React
};