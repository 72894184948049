import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/image"
import Paragraph from "../../../components/paragraph"
import Constrain from "../../../components/constrain"
import Box from "../../../components/box"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Blockquote from "../../../components/blockquote"
import QuotePrimary from "../../../components/quote-primary"
import QuoteBox from "../../../components/quote-box"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspot from "../../../components/image-hotspot"

const JoachimLehrer = (props) => {
  const data = useStaticQuery(graphql`
    query WirkungLehrerQuery {
      lehrerZeitalter: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-zeitalter.jpg"
        }
      ) {
        ...largeImage
      }
      lehrerFelsen: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-01-felsen.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerTurm: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-02-turm.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerLicht: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-03-licht.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerApollo: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-04-apollo.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerSturm: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-05-sturm.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerMeer: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-06-meer.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerKahn: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-07-kahn.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerSchuppen: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-08-schuppen.jpg"
        }
      ) {
        ...mediumImage
      }
      lehrerMarode: file(
        relativePath: {
          eq: "dauerausstellung/02-hoelderlins-wirkung/lehrer-09-marode.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack as="section">
      <Constrain align="left">
        <Stack space={6}>
          <Heading as="h2" level={2}>
            Joachim Lehrers ›Hölderlin oder Das goldene Zeitalter‹
          </Heading>
          <Paragraph>
            Fotorealistisch und doch in verfremdeter, stürmischer Umgebung
            zeichnete der Künstler Joachim Lehrer den Hölderlinturm – und
            versteckte darin jede Menge Details aus Hölderlins Leben und Werk.
          </Paragraph>
        </Stack>
      </Constrain>
      <Constrain align="right">
        <QuoteBox author="Joachim Lehrer">
          In dem Werk ›Hölderlin – oder: Das Goldene Zeitalter habe ich – mehr
          oder weniger verschlüsselt – die Lebensstationen Hölderlins
          dargestellt: eine bildgewordene Biografie.
        </QuoteBox>
      </Constrain>

      <ImageHotspots
        caption="Joachim Lehrer: ›Hölderlin oder Das goldene Zeitalter‹"
        image={
          <Image
            image={data.lehrerZeitalter.childImageSharp.gatsbyImageData}
            alt="Fantastisches Gemälde von Joachim Lehrer: ›Hölderlin oder Das goldene Zeitalter‹"
          />
        }
      >
        <ImageHotspot x={50} y={70}>
          <ModalTemplate
            title="Die Felsen im Wasser"
            image={
              <Image
                image={data.lehrerFelsen.childImageSharp.gatsbyImageData}
                alt="Ausschnitt mit Felsen im Wasser"
              />
            }
          >
            <Paragraph>
              Eine merkwürdige Idee, den Hölderlinturm auf eine Insel zu setzen.
              Aber natürlich hatte Joachim Lehrer gute Gründe dafür. In der
              Vorrede zu Hölderlins ›Hyperion‹ heißt es:
            </Paragraph>
            <Paragraph>
              <Blockquote>
                »Von früher Jugend an lebt ich lieber, als sonstwo, auf den
                Küsten von Jonien und Attika und den schönen Inseln des
                Archipelagus, und es gehörte unter meine liebsten Träume, einmal
                wirklich dahin zu wandern, zum heiligen Grabe der jugendlichen
                Menschheit. Griechenland war meine erste Liebe und ich weiß
                nicht, ob ich sagen soll, es werde meine letzte sein.«
              </Blockquote>
            </Paragraph>
            <Paragraph>
              Entsprechend häufig taucht das Motiv der Insel auch in Hölderlins
              Werken auf. Welchen Bezug hatte er, der wahrscheinlich niemals
              eine Insel betreten hat, zu diesem Topos? Sicher kannte sie aus
              Büchern. Johann Gotfried Schnabels mehrbändiger Roman ›Die Insel
              Felsenburg‹ gehörte zu den meistverbreitetsten Büchern des 18.
              Jahrhundert und der befreundete Schriftsteller Wilhelm Heinse, der
              auch Einfluss auf Hölderlins Roman ›Hyperion‹ hatte, wurde durch
              seinen Roman ›Ardinghello oder die glückseligen Inseln‹ (1786)
              berühmt, der Hölderlin schon in jungen Jahren begeisterte.
            </Paragraph>
            <Paragraph>
              Auch Joachim Lehrer selbst zieht es auf seinen Reisen immer wieder
              auf nahe und ferne Inseln.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={48} y={50}>
          <ModalTemplate
            title="Der Turm"
            image={
              <Image
                image={data.lehrerTurm.childImageSharp.gatsbyImageData}
                alt="Vergrößerter Ausschnitt des Turms. Im Detail sind marode Fensterläden und das Graffiti an der Hauswand zu erkennen"
              />
            }
          >
            <Paragraph>
              Fotorealistisch bis ins Detail hat Joachim Lehrer den
              Hölderlinturm abgebildet. So, wie er in den frühen 1990er Jahren
              allseits bekannt war: frisch renoviert und mit Graffiti an der
              Hauswand. Doch das beschauliche Postkartenmotiv entfaltet durch
              seine ungewohnt stürmische Umgebung eine ganz neue Wirkung. Voll
              Kraft und Farbe steht der Turm fest in der Brandung, den Elementen
              trotzend. Für Joachim Lehrer steht er einerseits für den jungen,
              Hölderlin, voller Tatendrang und Idealen, andererseits aber auch
              für die Distanz und Zurückgezogenheit, das Solitäre, das er in
              Hölderlins Biografie ebenfalls zu erkennen meint.
            </Paragraph>
            <Paragraph>
              Für das modern erscheinende Bild griff Joachim Lehrer auf eine
              Maltechnik zurück, die in der Renaissance entwickelt wurde und im
              Barock zur höchsten Blüte gelangte: die Harzöl-Lasurmalerei.
              Untrennbar mit dieser Lasurtechnik sind Namen wie van Eyck,
              Rembrandt und Vermeer verbunden. Zu Hölderlins Zeiten kam diese
              Technik bereits wieder in Vergessenheit, der aufkeimende Geniekult
              brauchte Maltechniken, die schnell und scheinbar erfolgreich von
              der Idee zum Bild führten. Sich von technischen Umständlichkeiten
              im Schöpfungsakt bremsen zu lassen, war für das Künstler-Genie
              unzumutbar. Der schlechte Erhaltungszustand der überkommenen
              Bilder aus dem 19. Jahrhundert legt davon Zeugnis ab.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={10} y={40}>
          <ModalTemplate
            title="Das Licht"
            image={
              <Image
                image={data.lehrerLicht.childImageSharp.gatsbyImageData}
                alt="Vergrößerung des Horizonts mit Farbverlauf"
              />
            }
          >
            <Paragraph>
              Das Licht taucht das Bild in warme Farben, besonders den Turm.
              Fast golden leuchtet er auf. Bei dieser Farbgestaltung hat sich
              Lehrer an Hölderlins Gedichten orientiert: trunken, wie im hellen
              Morgenstrahle. Oft scheint in ihnen das Licht aus jenem
              versunkenen goldenen Zeitalter der Antike auf, das Lehrers Bild
              seinen Titel gibt.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={67} y={64}>
          <ModalTemplate
            title="Apollo von Belvedere"
            image={
              <Image
                image={data.lehrerApollo.childImageSharp.gatsbyImageData}
                alt="Apollo von Belvedere im Hüttchen vor dem Haus"
              />
            }
          >
            <Paragraph>
              Im Hüttchen vor dem Haus hat Joachim Lehrer eine Statue
              untergebracht: Den Apollo von Belvedere. Er nimmt einerseits Bezug
              auf Hölderlins Ideale, die sich an der Kultur der Griechen
              orientieren, andererseits aber auch auf Hölderlin selbst, von dem
              die anderen Studierenden am Evangelischen Stift berichteten, wenn
              er durch den Speisesaal schritt erschien es, als schreite Apollo
              durch den Saal.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={35} y={20}>
          <ModalTemplate
            title="Die Wolken – der Sturm"
            image={
              <Image
                image={data.lehrerSturm.childImageSharp.gatsbyImageData}
                alt="Vergrößerung des Himmels mit aufziehendem Unwetter"
              />
            }
          >
            <Paragraph>
              Dunkel und drohend zieht am Himmel ein Unwetter auf. Darin
              spiegelt sich für Joachim Lehrer Hölderlins Gesamtkonstitution:
              Die Lebensbahn, die schon früh von klösterlicher, bedrückender
              Enge – und einer von Anfang an aussichtslosen großen Liebe
              überschattet wird.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={10} y={80}>
          <ModalTemplate
            title="Das Meer"
            image={
              <Image
                image={data.lehrerMeer.childImageSharp.gatsbyImageData}
                alt="Gischt auf stürmisch bewegten Wellen"
              />
            }
          >
            <Paragraph>
              Hat Hölderlin jemals das Meer zu Gesicht bekommen? Wenn überhaupt,
              dann 1802 in Bordeaux. Ungeachtet dessen zieht sich das Meer als
              Metapher durch viele seiner Werke und Briefe: »Ich bin zum Stoiker
              ewig verdorben. Das seh ich wohl. Ewig Ebb und Flut«, schreibt er
              in einem Brief an den Freund Neuffer.
            </Paragraph>
            <Paragraph>
              Im stürmisch bewegten, weiten Meer liegt für Joachim Lehrer aber
              auch die Freiheit für uferlose Pläne. Hierin erkennt er eine
              Parallele zu Hölderlins Lebensweg – einem Geworfensein näher als
              einer Lebensbahn: Aus der für ihn bestimmten Laufbahn
              ausgebrochen, sind seine Hofmeister- und Erzieherstellen
              Verlegenheitslösungen – weit entfernt von dem, was er sich
              gewünscht haben mochte.
            </Paragraph>
            <QuotePrimary author="Friedrich Hölderlin: Hyperions Schicksalslied">
              <span>Ihr wandelt droben im Licht</span>
              <span>Auf weichem Boden, selige Genien!</span>
              <span>Glänzende Götterlüfte</span>
              <span>Rühren euch leicht,</span>
              <span>Wie die Finger der Künstlerin</span>
              <span>Heilige Saiten.</span>
              <span>
                <br />
              </span>
              <span>Schicksallos, wie der schlafende</span>
              <span>Säugling, atmen die Himmlischen;</span>
              <span>Keusch bewahrt</span>
              <span>In bescheidener Knospe,</span>
              <span>Blühet ewig</span>
              <span>Ihnen der Geist,</span>
              <span>Und die seligen Augen</span>
              <span>Blicken in stiller</span>
              <span>Ewiger Klarheit.</span>
              <span>
                <br />
              </span>
              <span>Doch uns ist gegeben,</span>
              <span>Auf keiner Stätte zu ruhn,</span>
              <span>Es schwinden, es fallen</span>
              <span>Die leidenden Menschen</span>
              <span>Blindlings von einer</span>
              <span>Stunde zur andern,</span>
              <span>Wie Wasser von Klippe</span>
              <span>zu Klippe geworfen,</span>
              <span>Jahr lang ins Ungewisse hinab.</span>
            </QuotePrimary>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={85} y={70}>
          <ModalTemplate
            title="Der Kahn"
            image={
              <Image
                image={data.lehrerKahn.childImageSharp.gatsbyImageData}
                alt="Ein gestrandeter Stocherkahn im Wasser vor dem Turm"
              />
            }
          >
            <Paragraph>
              Der Antrieb, die Stange außer Reichweite. Ein Hinweis darauf, dass
              Hölderlin hier bis zu seinem Lebensende verweilen wird. Er kann
              gar nicht anders. Aber: Er ist nicht irgendwo gestrandet. Nach
              vergeblicher Behandlung im Autenriethschen Clinicum wird er hier
              vom Schreinermeister Zimmer in sein Haus aufgenommen und wird dort
              die nächsten Jahrzehnte ein ruhiges Dasein fristen. Womöglich war
              der Ort für den Dichter tatsächlich jene Heimat, nach der er sich
              in seinen Gedichten stets gesehnt hat.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={72} y={53}>
          <ModalTemplate
            title="Der Schuppen"
            image={
              <Image
                image={data.lehrerSchuppen.childImageSharp.gatsbyImageData}
                alt="Kleiner Holzschuppen mit Säge"
              />
            }
          >
            <Paragraph>
              Als Quartiermeister und Pfleger des Dichters musste auch der
              Schreiner Ernst Zimmer einen Platz in Lehrers Bild erhalten. Der
              Schuppen, den es so nie gegeben hat, ist eine Andeutung auf seinen
              Brotberuf: Vielleicht sein Holzlager oder seine Werkstatt. Sogar
              eine Säge hat Lehrer darin untergebracht.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={60} y={45}>
          <ModalTemplate
            title="Die marode Seite des Hauses"
            image={
              <Image
                image={data.lehrerMarode.childImageSharp.gatsbyImageData}
                alt="Bröckelnde Hausfassade und heruntergekommene Fensterläden"
              />
            }
          >
            <Paragraph>
              Vergleichsweise wenig ist über Hölderlins letzte drei Jahrzehnte
              im Turm am Neckar überliefert. Gleichklang im Alltag,
              Krankheitschübe, kleine Spaziergänge, keine neuen Horizonte mehr.
              Gustav Schwab berichtet: »in einem von Wellen bespühlten Thurme,
              träumt seit 33 Jahren Friedrich Hölderlin und brütet über seinem
              verstummten Saitenspiel.« Wenngleich oftmals dramatisiert, gab es
              in Hölderlin wirklichem Leben keinen Platz für dramatische
              Überhöhung. Er stirbt ohne weiteres Aufsehen im Hochsommer des
              Jahres 1843.
            </Paragraph>
            <QuotePrimary author="Friedrich Hölderlin: An">
              <span>Elysium</span>
              <span>Dort find ich ja</span>
              <span>Zu euch ihr Todesgötter</span>
              <span>
                Dort Diotima <Box as="span" display="inline-block" width={6} />
                Heroen.
              </span>
              <span>Singen möcht ich von dir</span>
              <span>Aber nur Tränen.</span>
              <span>Und in der Nacht in der ich wandle erlöscht mir dein</span>
              <span>Klares Auge!</span>
              <span>
                <Box as="span" display="inline-block" width={12} />
                himmlischer Geist.
              </span>
            </QuotePrimary>
          </ModalTemplate>
        </ImageHotspot>
      </ImageHotspots>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Hyperions Schicksalslied">
          <span>Doch uns ist gegeben,</span>
          <span>Auf keiner Stätte zu ruhn,</span>
          <span>Es schwinden, es fallen</span>
          <span>Die leidenden Menschen</span>
          <span>Blindlings von einer</span>
          <span>Stunde zur andern,</span>
          <span>Wie Wasser von Klippe</span>
          <span>zu Klippe geworfen,</span>
          <span>Jahr lang ins Ungewisse hinab.</span>
        </QuotePrimary>
      </Constrain>
    </Stack>
  )
}

export default JoachimLehrer
