import IlmaRakusa from "../../../../src/content/aus-dem-archiv-geholt/band-04-liebesbrief/ilma-rakusa";
import Bueste from "../../../../src/content/aus-dem-archiv-geholt/band-04-liebesbrief/bueste";
import Letter from "../../../../src/content/aus-dem-archiv-geholt/band-04-liebesbrief/letter";
import DateHeading from "../../../../src/content/aus-dem-archiv-geholt/band-04-liebesbrief/date-heading";
import * as React from 'react';
export default {
  IlmaRakusa,
  Bueste,
  Letter,
  DateHeading,
  React
};