import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AudioPlayer from "../../../components/audio-player"
import Constrain from "../../../components/constrain"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import Poem from "../../../components/poem"
import Paragraph from "../../../components/paragraph"
import Stack from "../../../components/stack"
import Text from "../../../components/text"

const Fruehling = (props) => {
  const data = useStaticQuery(graphql`
    query JahreszeitenFruehlingQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-fruehling.png"
        }
      ) {
        ...largeImage
      }
      fruehling01: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-01.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling02: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-02.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling03: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-03.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling04: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-04.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling05: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-05.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling06: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-06.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading as="h1" level={3}>
              {title}
            </Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack>
      <Grid columns={[1, 2]} alignY="center">
        <Stack space={3}>
          <ImageHotspots
            caption="Hölderlins Gedichtmanuskript ›Der Frühling.‹ aus dem Deutschen Literaturarchiv Marbach"
            image={
              <Image
                image={data.gedicht.childImageSharp.gatsbyImageData}
                alt="Handschriftliches Manuskript von Hölderlins ›Der Frühling.‹"
              />
            }
          >
            <ImageHotspot bg="spring" x={37} y={10} name="fruehling-01">
              <ModalTemplate
                title="Der Frühling. kommt"
                image={
                  <Image
                    image={data.fruehling01.childImageSharp.gatsbyImageData}
                    alt="vergrößerter Ausschnitt des Gedichttitels und der ersten Zeile mit der Ergänzung »kommt«"
                  />
                }
              >
                <Paragraph>
                  Zwischen dem Titel und der ersten Zeile des Gedichts fügt
                  Hölderlin das Wort »kommt« ein, das den Vers wie folgt
                  ergänzt: »Wenn aus der Tiefe kommt der Frühling in das Leben«
                  – eine von Hölderlins typischen Satzverstellungen, in denen
                  sich das Prädikat vor das Subjekt schiebt.
                </Paragraph>
                <Paragraph>
                  Durch den nachträglichen Einschub entsteht aber auch eine
                  schöne Rückkopplung auf den Titel: »Der Frühling. kommt«
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
            <ImageHotspot bg="spring" x={7} y={70} name="klecks">
              <ModalTemplate
                title="o – so"
                image={
                  <Image
                    image={data.fruehling02.childImageSharp.gatsbyImageData}
                    alt="Tintenkleckse auf dem Manuskript"
                  />
                }
              >
                <Paragraph>
                  Nach erneutem Eintauchen in das Tintenfass hat die Feder wohl
                  gekleckst. Von dem Wort »So« bleibt damit nur das »o« zurück.
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
            <ImageHotspot bg="spring" x={53} y={80} name="scardanelli">
              <ModalTemplate
                title="Scardanelli"
                image={
                  <Image
                    image={data.fruehling03.childImageSharp.gatsbyImageData}
                    alt="Hölderlins Signatur mit dem Pseudonym »Scardanelli«"
                  />
                }
              >
                <Paragraph>
                  Wie die meisten seiner Jahreszeitengedichte unterschreibt
                  Hölderlin auch dieses mit dem Pseudonym »Scardanelli«. Der
                  spätere Besitzer des Manuskripts ergänzte darunter: »J.(ohann)
                  C.(hristian) F.(riedrich) Hölderlin«
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
            <ImageHotspot bg="spring" x={73} y={93} name="geburtstag">
              <ModalTemplate
                title="Ein Geburtstagsgedicht?"
                image={
                  <Image
                    image={data.fruehling04.childImageSharp.gatsbyImageData}
                    alt="Datumsvermerk des späteren Besitzers"
                  />
                }
              >
                <Paragraph>
                  Auf Hölderlins »74ten Geburtstag« wurde das Manuskript von
                  seinem späteren Besitzer datiert. Das dies nicht recht stimmen
                  kann, ist offensichtlich, denn Hölderlin hat sein 74.
                  Lebensjahr nicht mehr erreicht. Eine mündliche Überlieferung
                  legt jedoch nahe, dass das Gedicht tatsächlich relativ spät,
                  womöglich zu Hölderlins 73. Geburtstag am 20. März 1843,
                  entstanden ist.
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
            <ImageHotspot bg="spring" x={17} y={82} name="datum">
              <ModalTemplate
                title="Eine fragwürdige Datierung"
                image={
                  <Image
                    image={data.fruehling05.childImageSharp.gatsbyImageData}
                  />
                }
              >
                <Paragraph>
                  Hölderlin datiert das Manuskript auf den 24. Mai 1758 – ein
                  Datum, das 12 Jahre vor seiner Geburt liegt und damit, wie
                  viele der Datierungen seiner Turmgedichte, fingiert ist. Der
                  Monat Mai kommt dabei besonders häufig vor.
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
            <ImageHotspot bg="spring" x={20} y={90} name="provenienznachweis">
              <ModalTemplate
                title="Der Provenienznachweis"
                image={
                  <Image
                    image={data.fruehling06.childImageSharp.gatsbyImageData}
                    alt="Handschriftliche Anmerkung des späteren Besitzers"
                  />
                }
              >
                <Paragraph>
                  Links unten notiert Baumeister, der spätere Besitzer des
                  Manuskripts, die Provenienzgeschichte des Blattes: »Dß.
                  Gedicht wurde mir besorgt von Paul Mutschler, gestorb.(en) im
                  Mai 1844. Aus dem Nachlaß des HE. Oberamtmann Camerer († 1893,
                  Tüb.(ingen)) durch die Witwe mir übergeben, 1894. –
                  Baumeister.«
                </Paragraph>
                <Paragraph>
                  Als Beamter der Stadt Nürtingen war Camerer für die Betreuung
                  von Hölderlins Pflegschaft, den Briefverkehr mit der Familie
                  Zimmer und die Auszahlung der vierteljährlichen Rechnungen
                  zuständig.
                </Paragraph>
              </ModalTemplate>
            </ImageHotspot>
          </ImageHotspots>
        </Stack>
        <Stack>
          <Stack space={6}>
            <Heading as="h3" level={3} color="spring">
              Der Frühling.
            </Heading>
            <Poem size="3">
              <span>Wenn aus der Tiefe kommt der Frühling in das Leben,</span>
              <span>Es wundert sich der Mensch, und neue Worte streben</span>
              <span>Aus Geistigkeit, die Freude kehret wieder</span>
              <span>Und festlich machen sich Gesang und Lieder.</span>
              <span>
                <br />
              </span>
              <span>Das Leben findet sich aus Harmonie der Zeiten,</span>
              <span>Daß immerdar den Sinn Natur und Geist geleiten,</span>
              <span>Und die Vollkommenheit ist Eines in dem Geiste,</span>
              <span>So findet vieles sich, und aus Natur das Meiste.</span>
              <span>
                <br />
              </span>
              <Columns>
                <Column textAlign="center">Mit Unterthänigkeit</Column>
              </Columns>
              <Columns>
                <Column>d. 24 Mai 1758.</Column>
                <Column></Column>
                <Column>Scardanelli.</Column>
              </Columns>
            </Poem>
          </Stack>
          <Stack space={3}>
            <Text sans>
              Christian Reiner liest{" "}
              <Text bold as="span" color="spring">
                ›Der Frühling.‹
              </Text>
            </Text>
            <AudioPlayer
              src={`dauerausstellung/05-haelfte-des-lebens/fruehling`}
            />
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  )
}

export default Fruehling
